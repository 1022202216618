<template>
  <div class="kpi-change-row">
    <div class="form-label">{{ label }}</div>

    <div v-if="before !== null" class="measure-energy-label">
      <span>{{ formatNumber(before, 1) }} {{ units }}</span>
      <EnergyLabel v-if="energyLabelBeforeRatio !== undefined" :value="energyLabelBeforeRatio" />
    </div>
    <div v-else>-</div>

    <div v-if="after !== null" class="measure-energy-label">
      <span>{{ formatNumber(after, 1) }} {{ units }}</span>
      <EnergyLabel v-if="energyLabelAfterRatio !== undefined" :value="energyLabelAfterRatio" />
    </div>
    <div v-else>-</div>

    <div v-if="before === 0">-</div>
    <div v-else-if="after === before">-</div>
    <div v-else-if="after > before">↑ {{ formatNumber(((after - before) / before) * 100, 0) }} %</div>
    <div v-else>↓ {{ formatNumber(((after - before) / before) * 100 * -1, 0) }} %</div>
  </div>
</template>

<script>
import EnergyLabel from '@/components/shared/EnergyLabel.vue'

export default {
  components: {
    EnergyLabel,
  },

  props: {
    label: {
      type: String,
    },
    before: {
      type: Number,
      required: true,
    },
    after: {
      type: Number,
      required: true,
    },
    units: {
      type: String,
      required: true,
    },
    energyLabelBeforeRatio: {
      type: Number,
    },
    energyLabelAfterRatio: {
      type: Number,
    },
  },
}
</script>

<style lang="scss">
.kpi-change-row {
  display: contents;

  & .measure-energy-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-s);
  }
}
</style>
