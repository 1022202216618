<i18n>
{
  "de": {
    "utilityHeader": "Elektrizitätsversorgung und Preise",
    "noGisData": "Keine Daten verfügbar"
  }
}
</i18n>

<template>
  <DetailListItem class="electricity-utility-row" :title="$t('utilityHeader')" expandable @expand="onModalOpen">
    <div v-if="building.el_utility">
      {{ building.el_utility.name }}
    </div>
    <div v-else>
      {{ $t('noGisData') }}
    </div>
    <template #modal>
      <ElectricityUtilityModal v-if="modalOpen" @close="onModalClose" :building="building" />
    </template>
  </DetailListItem>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import ElectricityUtilityModal from '@/components/building/feasibility/ElectricityUtilityModal.vue'

export default {
  components: {
    DetailListItem,
    ElectricityUtilityModal,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  computed: {
    tarifMedium() {
      return this.building.el_utility.grid_tarifs.find((v) => v.category === 'H4').total
    },
  },

  methods: {
    onModalOpen() {
      this.modalOpen = true
    },

    onModalClose() {
      this.modalOpen = false
    },
  },
}
</script>
