<i18n>
{
  "de": {
    "yAxisTitle": {
      "ee": "Endenergieverbrauch in",
      "s12e": "Scope 1-2 Emissionen in"
    },
    "tooltipTitle": {
      "ee": "Endenergie",
      "s12e": "Emissionen"
    },
    "valueTypeUnit": {
      "ee": {
        "absolute": "MWh",
        "specific": "kWh/m²"
      },
      "s12e": {
        "absolute": "t CO₂e",
        "specific": "kg CO₂e/m²"
      }
    }
  }
}
</i18n>

<template>
  <BarChart :chart-data="processedChartData" :options="options" exportable class="c-meters-kpi-chart" />
</template>

<script>
import _ from 'lodash'

import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import BarChart from '@/components/shared/charts/BarChart.vue'

import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [
    //
    ChartOptionsMixin,
  ],

  components: {
    BarChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    kpiType: {
      type: String, // 'ee' or 's12e'
      required: true,
    },
    valuesType: {
      type: String, // 'absolute' or 'specific'
      required: true,
    },
  },

  data() {
    return {
      legendItemsHidden: [],
    }
  },

  computed: {
    yAxisTitle() {
      return `${this.$t(`yAxisTitle.${this.kpiType}`)} ${this.$t(`valueTypeUnit.${this.kpiType}.${this.valuesType}`)}`
    },

    units() {
      return this.$t(`valueTypeUnit.${this.kpiType}.${this.valuesType}`)
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxis: {
            barPercentage: 0.6,
            grid: {
              drawOnChartArea: false,
            },
          },
          yAxis: {
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value),
            },
            title: {
              display: true,
              text: this.yAxisTitle,
            },
          },
        },
        interaction: {
          mode: 'x',
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (items) =>
                this.getTooltipTitleWithTotal(items, {
                  precision: 1,
                  title: this.getTooltipTitle(this.kpiType),
                  unit: this.units,
                }),
              label: (item) =>
                this.getTooltipLabelWithPercentage(item, { unit: this.units, getTotalFn: this.getTotal }),
            },
            // Reverse order of items
            itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
          },
          legend: {
            position: 'bottom',
            align: 'start',
            reverse: true,
            labels: {
              padding: 15,
              generateLabels: this.generateLegendLabels,
            },
            onClick: (event, legendItem) => {
              this.onLegendItemClick(event.chart, event, legendItem)
            },
          },
        },
      }
    },

    // Filter out ELECTRICITY_PV, sort by meter_type in the following order: END_ENERGY_HEATER, ELECTRICITY_TENANTS, ELECTRICITY_GENERAL
    meters() {
      return this.building.meters
        .filter((meter) => meter.meter_type !== 'ELECTRICITY_PV')
        .sort((a, b) => {
          if (a.meter_type === 'END_ENERGY_HEATER') return -1
          if (b.meter_type === 'END_ENERGY_HEATER') return 1
          if (a.meter_type === 'ELECTRICITY_TENANTS') return -1
          if (b.meter_type === 'ELECTRICITY_TENANTS') return 1
          if (a.meter_type === 'ELECTRICITY_GENERAL') return -1
          if (b.meter_type === 'ELECTRICITY_GENERAL') return 1
          return 0
        })
    },

    meterTypeColorSteps() {
      // Create an array of objects with the count of meters of each type
      // The first instance of each meter type has a color_step of 0, the next 1, etc.
      // Example:
      // { meter_type: 'END_ENERGY_HEATER', color_step: 0 }
      // { meter_type: 'ELECTRICITY_GENERAL', color_step: 0 }
      // { meter_type: 'END_ENERGY_HEATER', color_step: 1 }
      // { meter_type: 'ELECTRICITY_GENERAL', color_step: 1 }

      // Helper with unique meter types
      const uniqueMeterTypes = [...new Set(this.meters.map((meter) => meter.meter_type))]
      // Initialize counts for each unique meter type to 0
      const counts = {}
      uniqueMeterTypes.map((meterType) => {
        counts[meterType] = 0
      })
      // Populate color steps
      return this.meters.map((meter) => {
        const colorStep = {
          meter_type: meter.meter_type,
          color_step: counts[meter.meter_type],
        }
        counts[meter.meter_type] += 1
        return colorStep
      })
    },

    // Process data
    processedChartData() {
      const years = []
      this.meters.map((meter) => meter.values.map((d) => years.push(d.year)))
      const uniqueYears = [...new Set(years)].sort((a, b) => a - b)
      const yearsCount = uniqueYears.length

      let datasets = []

      // Populate datasets
      this.meters.forEach((meter, index) => {
        // Get values for each year
        const meterValues = uniqueYears.map((year) => {
          const yearValues = meter.values.filter((v) => v.year === year)
          return yearValues.reduce((pv, cv) => {
            if (this.kpiType === 'ee') {
              return pv + cv.amount_normalized
            } else {
              return pv + cv.s12e
            }
          }, 0)
        })

        // Push datasets
        datasets.push({
          index: index,
          meterType: meter.meter_type,
          label:
            meter.meter_type === 'END_ENERGY_HEATER'
              ? this.getLabelForEndEnergyHeaterTruncated(meter)
              : this.$t(`_meterTypes.${meter.meter_type}`),
          backgroundColor: this.generateMeterTypeColors(meter.meter_type, index),
          borderColor: '#ffffff',
          borderWidth: 1,
          data: meterValues.map((v) => (this.valuesType === 'absolute' ? v / 1000 : v / this.building.kpi.energy_area)),
          stack: 'measured',
          barThickness: 13,
        })
      })

      return {
        labels:
          yearsCount < 10
            ? _.concat(
                uniqueYears,
                _.map(_.range(11 - yearsCount), (i) => _.parseInt(_.last(uniqueYears)) + i + 1)
              )
            : uniqueYears,
        datasets: datasets,
      }
    },
  },

  methods: {
    //
    getTotal(yearIdx) {
      return this.processedChartData.datasets
        .filter((d) => d.stack === 'measured')
        .reduce((pv, cv) => pv + Math.max(0, cv.data[yearIdx] ? cv.data[yearIdx] : 0), 0)
    },

    //
    getTooltipTitle(kpiType) {
      return kpiType === 'ee' ? this.$t('tooltipTitle.ee') : this.$t('tooltipTitle.s12e')
    },

    // Generate legend labels
    generateLegendLabels() {
      return this.meters.map((meter, index) => {
        return {
          index: index,
          meterType: meter.meter_type,
          text:
            meter.meter_type === 'END_ENERGY_HEATER'
              ? this.getLabelForEndEnergyHeater(meter)
              : this.$t(`_meterTypes.${meter.meter_type}`),
          fillStyle: this.generateMeterTypeColors(meter.meter_type, index),
          strokeStyle: '#ffffff',
          hidden: this.legendItemsHidden.includes(index),
        }
      })
    },

    // Generate colors for meter types taking into account multiple meters of the same type
    // Example:
    // END_ENERGY_HEATER, 0: #xxxxxxff
    // ELECTRICITY_PV, 1: #xxxxxxff
    // END_ENERGY_HEATER, 2: #xxxxxxcc
    // ELECTRICITY_GENERAL, 3: #xxxxxxff
    generateMeterTypeColors(meterType, index) {
      // Opacity in hex, 5 steps, in case of more than 1 of the same meter type
      const colorSteps = ['ff', 'cc', '99', '66', '33']
      return colorPalettes.meterTypeColors[meterType] + colorSteps[this.meterTypeColorSteps[index].color_step % 5]
    },

    //
    onLegendItemClick(chart, e, legendItem) {
      if (this.legendItemsHidden.includes(legendItem.index)) {
        this.legendItemsHidden = this.legendItemsHidden.filter((l) => l !== legendItem.index)
      } else {
        this.legendItemsHidden.push(legendItem.index)
      }
      for (let dataIdx = 0; dataIdx < chart.data.datasets.length; dataIdx++) {
        if (chart.data.datasets[dataIdx].index === legendItem.index) {
          let meta = chart.getDatasetMeta(dataIdx)
          meta.hidden = this.legendItemsHidden.includes(legendItem.index)
        }
      }
      chart.update()
    },

    //
    getLabelForEndEnergyHeater(meter) {
      const energyCarrier = this.portfolio.energy_carriers.find((ec) => ec.id === meter.energy_carrier_id)
      let energyCarrierName = ''

      if (energyCarrier && energyCarrier.default) {
        energyCarrierName = this.$t(`_energyCarriers.${energyCarrier.name}`)
      } else {
        energyCarrierName = energyCarrier.name
      }

      return energyCarrierName
        ? `${this.$t(`_meterTypes.END_ENERGY_HEATER`)}: ${energyCarrierName}`
        : this.$t(`_meterTypes.END_ENERGY_HEATER`)
    },

    //
    getLabelForEndEnergyHeaterTruncated(meter) {
      const energyCarrier = this.portfolio.energy_carriers.find((ec) => ec.id === meter.energy_carrier_id)
      let energyCarrierName = ''

      if (energyCarrier && energyCarrier.default) {
        energyCarrierName = this.$t(`_energyCarriers.${energyCarrier.name}`)
      } else {
        energyCarrierName = energyCarrier.name
      }

      const label = energyCarrierName
        ? `${this.$t(`_meterTypes.END_ENERGY_HEATER`)}: ${energyCarrierName}`
        : this.$t(`_meterTypes.END_ENERGY_HEATER`)
      return label.length > 30 ? `${label.slice(0, 30).trim()}...` : label
    },
  },
}
</script>
