<i18n>
  {
    "de": {
      "heatingHeader": "Heizung",
      "heatingAge": "Alter Heizung",
      "lifecyclePopupTitle": "Zustand Heizung",
      "noHeating": "Keine Heizung",
      "years": "Jahre",
      "yearUnknownWarning": "Alter der Wärmeerzeugung unsicher",
      "missingHeatingType": "Heizungstyp unbekannt",
      "heatingPower": "Heizleistung"
    }
  }
  </i18n>

<template>
  <DetailListItem
    class="c-current-state-heating-summary-row"
    :title="$t('heatingHeader')"
    :expandable="hasHeating"
    @expand="onModalOpen"
  >
    <Box class-name="heating-summary-contents">
      <Box v-if="hasMissingHeating">
        <MissingDataLabel>{{ $t('missingHeatingType') }}</MissingDataLabel>
      </Box>
      <Box v-else class-name="heaters-list">
        <DataWarning v-if="hasHeating && building.current_state.heating.year_estimated">{{
          $t('yearUnknownWarning')
        }}</DataWarning>
        <Box v-for="(name, index) in heatersList" :key="index" class-name="pill">
          <span>
            {{ name }}
          </span>
        </Box>
        <Box v-if="heatersList.length > 0 && hasHeating" class-name="pill">
          <span>
            {{ building.current_state.heating.year || building?.current_state?.result?.envelope?.building_year?.value }}
          </span>
        </Box>
        <Box v-if="building.current_state.result.heating_power" class-name="pill">
          <span>
            {{ $t('heatingPower') }}:
            {{ formatNumberWithThreshold(building.current_state.result.heating_power, 1, 1, 0) }} kW
          </span>
        </Box>
        <Box v-if="heatersList.length === 0" class-name="no-heating">{{ $t('noHeating') }}</Box>
      </Box>
    </Box>

    <template v-if="hasHeating" #rightContent>
      <VPopover trigger="hover" placement="right" class="lifecycle-popover">
        <RingIndicator
          :year="building.heating_year"
          :minLifetime="building.heating_replacement_year"
          :maxLifetime="building.heating_replacement_year + 5"
          class="ring-indicator"
        />
        <template slot="popover">
          <header>
            {{ $t('lifecyclePopupTitle') }}
          </header>
          <main class="heating-summary-row-lifecycle">
            <RingIndicator
              :year="building.heating_year"
              :minLifetime="building.heating_replacement_year"
              :maxLifetime="building.heating_replacement_year + 5"
            />
            {{ $t('heatingAge') }}: {{ heatingAge }} {{ $t('years') }}
          </main>
        </template>
      </VPopover>
    </template>

    <template #modal>
      <Modal v-if="isModalOpen" wide @close="onModalClose">
        <template #header>
          <h2>{{ $t('heatingHeader') }}</h2>
        </template>
        <ViewHeating :building="building" :portfolio="portfolio" />
      </Modal>
    </template>
  </DetailListItem>
</template>

<script>
import { VPopover } from 'v-tooltip'
import Box from '@/components/cui/layout/Box.vue'

import ModalMixin from '@/components/shared/ModalMixin.vue'

import Modal from '@/components/shared/Modal.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import MissingDataLabel from '@/components/shared/MissingDataLabel.vue'
import DataWarning from '@/components/shared/DataWarning.vue'
import ViewHeating from '@/components/building/view/ViewHeating.vue'
import RingIndicator from '@/components/shared/RingIndicator.vue'

export default {
  mixins: [
    //
    ModalMixin,
  ],

  components: {
    Box,
    VPopover,
    ViewHeating,
    DetailListItem,
    MissingDataLabel,
    RingIndicator,
    DataWarning,
    Modal,
  },

  props: {
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasHeating() {
      return !this.building.current_state.envelope.without_heating
    },

    heatersList() {
      const heaters = this.building.current_state.heating.heaters
      const result = []

      heaters.forEach((heater) => {
        if (this.getRawNameForId(heater.heating_type_id) === 'LOCAL') {
          if (heater.heat_grid_identifier) {
            result.push(
              `${this.getNameForId(heater.heating_type_id)}: ${this.getGridLabel(heater.heat_grid_identifier)}`
            )
          }
        } else {
          result.push(this.getNameForId(heater.heating_type_id))
        }
      })
      return result
    },

    hasMissingHeating() {
      if (
        this.building.current_state.envelope.without_heating &&
        this.building.current_state.zones.zones.find((z) => z.dhw_demand && z.dhw_demand > 0) === undefined
      ) {
        // Don't require heating if envelope is not heated and no dhw demand
        return false
      }
      return (
        this.building.current_state.heating.heaters.find((z) => z.type === 'UNKNOWN') ||
        this.building.current_state.heating.heaters.length === 0
      )
    },

    heatingAge() {
      return new Date().getFullYear() - this.building.heating_year
    },
  },

  methods: {
    getRawNameForId(heatingTypeId) {
      const heatingType = this.portfolio.heating_types.find((ht) => ht.id === heatingTypeId)
      if (heatingType) {
        return heatingType.name
      } else {
        return 'UNKNOWN'
      }
    },

    // Must output final string: a translated name if it has a default translation, or the raw name otherwise
    getNameForId(heatingTypeId) {
      const heatingType = this.portfolio.heating_types.find((ht) => ht.id === heatingTypeId)
      if (heatingType) {
        if (heatingType.default) {
          return this.$t(`_heatingTypes.${heatingType.name}`)
        } else {
          return heatingType.name
        }
      } else {
        return this.$t('_heatingTypes.UNKNOWN')
      }
    },

    getGridLabel(gridIdentifier) {
      const grid = this.portfolio.heat_grids.find((g) => g.identifier === gridIdentifier)
      return grid && grid.name
    },
  },
}
</script>

<style lang="scss">
.c-current-state-heating-summary-row {
  & .lifecycle-popover {
    display: inline;
  }

  & .heating-summary-contents {
    width: 100%;

    & .heaters-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: var(--spacing-xxs);
      width: 100%;

      & .pill {
        display: flex;
        align-items: center;
        gap: var(--spacing-xs);
        padding: 0 var(--spacing-s);
        border: var(--box-border);
        border-radius: 13px;
        height: 24px;
        font-size: 14px;

        & > span {
          display: inline-block;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      & .no-heating {
        opacity: 0.5;
      }
    }
  }

  & .ring-indicator {
    height: 28px;
    margin-right: 7px;
    vertical-align: middle;
  }
}

.heating-summary-row-lifecycle {
  display: grid;
  grid-template-columns: 32px 1fr;

  & .ring-indicator {
    height: 24px;
  }
}
</style>
