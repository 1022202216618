<i18n>
{
  "de": {
    "locationHeader": "Standort",
    "noLocation": "Standortdaten fehlen",
    "noAddressDefined": "Standort nicht gefunden",
    "egid": "EGID"
  }
}
</i18n>

<template>
  <DetailListItem :title="$t('locationHeader')" expandable @expand="onModalOpen">
    <div class="value">
      <MissingDataLabel v-if="building.latitude === null || building.longitude === null">
        {{ $t('noLocation') }}
      </MissingDataLabel>
      <span v-for="(location, index) in locationData" :key="index">
        {{ location }}
      </span>
    </div>

    <template #modal>
      <LocationModal v-if="isModalOpen" :building="building" @close="onModalClose" />
    </template>
  </DetailListItem>
</template>

<script>
import ModalMixin from '@/components/shared/ModalMixin.vue'

import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import MissingDataLabel from '@/components/shared/MissingDataLabel.vue'
import LocationModal from '@/components/building/overview/LocationModal.vue'

export default {
  mixins: [
    //
    ModalMixin,
  ],

  components: {
    DetailListItem,
    MissingDataLabel,
    LocationModal,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    locationData() {
      const result = []
      const building = this.building

      if (building.addresses?.length) {
        building.addresses.forEach((item) => {
          let address = item

          if (building.zip_code) address += ` ${building.zip_code}`
          if (this.building.location_info.state === 'OK') {
            const locationInfo = this.building.location_info.data

            address += ` ${locationInfo.municipality} ${locationInfo.canton}`
          }
          result.push(address)
        })
      } else if (building.egids?.length) {
        building.egids.forEach((item) => {
          result.push(`${this.$t('egid')} ${item}`)
        })
      } else {
        result.push(`${building.latitude} ${building.longitude}`)
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.value {
  display: flex;
  flex-direction: column;
}
</style>
