<i18n>
{
  "de": {
    "energy": "Energie",
    "emissions": "Emissionen",
    "absoluteValues": "Absolut",
    "specificValues": "Spezifisch"
  }
}
</i18n>

<template>
  <div class="c-meters-charts-and-values">
    <div class="header-actions">
      <nav class="toggle-buttons data-type-toggle">
        <button type="button" :class="{ selected: dataType === 'energy' }" @click="setDataType('energy')">
          <span>{{ $t('energy') }}</span>
        </button>
        <button type="button" :class="{ selected: dataType === 'emissions' }" @click="setDataType('emissions')">
          <span>{{ $t('emissions') }}</span>
        </button>
      </nav>
      <nav class="toggle-buttons">
        <button type="button" :class="{ selected: valuesType === 'absolute' }" @click="setValuesType('absolute')">
          <span>{{ $t('absoluteValues') }}</span>
        </button>
        <button type="button" :class="{ selected: valuesType === 'specific' }" @click="setValuesType('specific')">
          <span>{{ $t('specificValues') }}</span>
        </button>
      </nav>
    </div>

    <div class="charts-box">
      <div class="chart" :class="{ hidden: dataType !== 'energy' }">
        <h3>{{ $t('energy') }}</h3>
        <MetersKpiChart kpi-type="ee" :portfolio="portfolio" :building="building" :values-type="valuesType" />
      </div>
      <div class="chart" :class="{ hidden: dataType !== 'emissions' }">
        <h3>{{ $t('emissions') }}</h3>
        <MetersKpiChart kpi-type="s12e" :portfolio="portfolio" :building="building" :values-type="valuesType" />
      </div>
    </div>

    <MetersRecentValues :building="building" :portfolio="portfolio" :data-type="dataType" />
  </div>
</template>

<script>
import MetersKpiChart from '@/components/building/meters/MetersKpiChart.vue'
import MetersRecentValues from '@/components/building/meters/MetersRecentValues.vue'

export default {
  components: {
    MetersKpiChart,
    MetersRecentValues,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dataType: 'energy', // 'energy' or 'emissions'
      valuesType: 'absolute', // 'absolute' or 'specific'
    }
  },

  methods: {
    setDataType(type) {
      this.dataType = type
    },

    setValuesType(type) {
      this.valuesType = type
    },
  },
}
</script>

<style lang="scss" scoped>
.c-meters-charts-and-values {
  background-color: #fff;

  & .header-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: var(--spacing-s);
    border-bottom: 1px solid #eeeeee;

    @media (max-width: 900px) {
      justify-content: space-between;
    }
  }

  & .data-type-toggle {
    display: none;

    @media (max-width: 900px) {
      display: flex;
    }
  }

  & .charts-box {
    display: flex;
    flex-direction: row;

    & .chart {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: var(--spacing-l);
      flex: 1;
      padding: var(--spacing-l) var(--spacing-xl) var(--spacing-m);
      border-bottom: 1px solid #eeeeee;

      &:first-child {
        border-right: 1px solid #eeeeee;

        @media (max-width: 900px) {
          border-right: none;
        }
      }

      & h3 {
        margin: 0;
        text-align: center;
      }

      & > * {
        flex: 1;
        width: 100%;
      }

      &.hidden {
        @media (max-width: 900px) {
          display: none;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.c-meters-charts-and-values {
  & .chart-container {
    height: 340px;
  }
}
</style>
