<i18n>
{
  "de": {
    "roofTileTitle": "Dachfläche",
    "roofTileArea": "Fläche",
    "roofTileIrradiation": "Einstrahlung",
    "roofTileSlope": "Neigung",
    "roofTileOrientation": "Orientierung",
    "roofTileClass": "Eignung für PV",
    "roofTileClass_5": "Top",
    "roofTileClass_4": "Sehr gut",
    "roofTileClass_3": "Gut",
    "roofTileClass_2": "Mittel",
    "roofTileClass_1": "Gering",
    "roofTileClass_small": "Fläche zu klein",
    "circFactor": "Formfaktor",
    "pvModules": "Max. Anzahl PV-Module",
    "pvCapacity": "Max. PV-Leistung",
    "pvProduction": "Stromertrag",
    "nModulesLabel": "Module",
    "pendingMessage": "Wird gespeichert, bitte warten...",
    "active": "ja"
  }
}
</i18n>

<template>
  <MapboxPopup :coordinates="coordinates" :showed="showed" anchor="top" @close="onPopupClose">
    <div class="roof-tile-popup">
      <template v-if="feature !== undefined">
        <div class="title">{{ $t('roofTileTitle') }} {{ feature.properties.id }}</div>

        <div class="two-cols">
          <span>{{ $t('roofTileArea') }} ({{ $t('circFactor') }})</span>
          <span>
            <span>
              {{ formatNumber(feature.properties.area, 0) }} m² ({{
                formatNumber(feature.properties.circ_area_factor, 1)
              }})
            </span>
          </span>
          <span>{{ $t('roofTileClass') }}</span>
          <span>
            <span :class="[`ranking-${feature.properties.solar_class}`]">
              {{ $t(`roofTileClass_${feature.properties.solar_class}`) }} ({{
                formatNumber(feature.properties.irradiation, 0)
              }}
              kWh<sub>sol</sub>/m²)
            </span>
          </span>
          <span>{{ $t('roofTileSlope') }} / {{ $t('roofTileOrientation') }}</span>
          <span
            >{{ formatNumber(feature.properties.slope, 0) }}° /
            {{ formatNumber(feature.properties.orientation, 0) }}°</span
          >
        </div>

        <div v-if="!feature.properties.pv_n_modules" class="two-cols">
          <span>{{ $t('pvCapacity') }}</span>
          <span>{{ $t('roofTileClass_small') }}</span>
        </div>
        <div v-else-if="feature.properties.pv_n_modules > 0" class="two-cols">
          <span>{{ $t('pvCapacity') }}</span>
          <span>
            {{ feature.properties.pv_capacity }} kWp ({{ feature.properties.pv_n_modules }} {{ $t('nModulesLabel') }})
          </span>
          <span>{{ $t('pvProduction') }}</span
          ><span>{{ formatNumber(feature.properties.pv_specific_production, 0) }} kWh/kWp</span>
        </div>

        <EditTile :building="building" :feature="feature" :is-roof="true" />
      </template>
    </div>
  </MapboxPopup>
</template>

<script>
import EditTile from '@/components/building/edit/EditTile.vue'
import MapboxPopup from '@/components/shared/MapboxPopup.vue'

export default {
  components: {
    MapboxPopup,
    EditTile,
  },

  props: {
    feature: {
      type: Object,
    },
    building: {
      type: Object,
    },
    coordinates: {
      type: Array,
      required: true,
    },
    showed: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    onPopupClose(event) {
      this.$emit('close', event)
    },
  },
}
</script>

<style lang="scss">
.roof-tile-popup {
  min-width: 400px;
  width: 100%;

  sub {
    font-size: 11px;
  }

  & .two-cols {
    display: grid;
    grid-template-columns: 150px 1fr;
  }

  & .title {
    font-weight: 600;
    margin-bottom: var(--spacing-m);
  }

  & .ranking-1::before,
  & .ranking-2::before,
  & .ranking-3::before,
  & .ranking-4::before,
  & .ranking-5::before,
  & .ranking-small::before {
    content: '';
    display: inline-block;
    margin-right: 0.3rem;
    place-self: center;
    width: 1.3rem;
    height: 0.8rem;
    border: var(--box-border);
    vertical-align: text-top;
  }

  & .ranking-1::before {
    background-color: var(--ranking-5-color);
  }

  & .ranking-2::before {
    background-color: var(--ranking-4-color);
  }

  & .ranking-3::before {
    background-color: var(--ranking-3-color);
  }

  & .ranking-4::before {
    background-color: var(--ranking-2-color);
  }

  & .ranking-5::before {
    background-color: var(--ranking-1-color);
  }
}
</style>
