<i18n>
{
  "de": {
    "show": "Anzeigen",
    "edit": "Bearbeiten"
  }
}
</i18n>

<template>
  <VPopover trigger="hover" placement="bottom">
    <ListButton :img-src="allowEdit ? '/icons/edit.svg' : '/icons/chevron-right.svg'"
                :disabled="disabled" @click="onClick()" />
    <template slot="popover">
      <main>{{ displayedTooltip }}</main>
    </template>
  </VPopover>
</template>

<script>
import ListButton from '@/components/shared/lists/ListButton.vue'
import { VPopover } from 'v-tooltip'

export default {
  components: {
    VPopover,
    ListButton,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    allowEdit: {
      type: Boolean,
      required: true,
    },
    editTooltip: {
      type: String,
    },
    showTooltip: {
      type: String,
    }
  },

  computed: {
    displayedTooltip() {
      if (this.allowEdit) {
        return this.editTooltip ? this.editTooltip : this.$t('edit')
      } else {
        return this.showTooltip ? this.showTooltip : this.$t('show')
      }
    }
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>
