<template>
  <div class="pv-minimap">
    <MglMap
      :accessToken="accessToken"
      :mapStyle.sync="mapStyle"
      class="map-container"
      :scrollZoom="false"
      :center="coordinates"
      :zoom="initialZoom"
    >
      <!-- Adding navigation control -->
      <MglNavigationControl position="top-right" />
      <!-- Adding GeoJSON layer -->
      <PvMeasureLayer
        :building="building"
        :measure="measure"
        sourceId="measureTilesSource"
        layerId="measureTilesLayer"
        @selectfeature="measureTileSelect"
      />
      <PvMeasurePopup
        :feature="activeFeature"
        :coordinates="popupCoordinates"
        :showed="activeLayer === 'measureTiles'"
        @close="onPopupClose"
      />
    </MglMap>
  </div>
</template>

<script>
import { MglMap, MglNavigationControl } from 'vue-mapbox'

import PvMeasureLayer from '@/components/building/map/PvMeasureLayer.vue'
import PvMeasurePopup from '@/components/building/map/PvMeasurePopup.vue'

export default {
  name: 'pv-minimap',

  components: {
    MglMap,
    MglNavigationControl,
    PvMeasureLayer,
    PvMeasurePopup,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    measure: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      accessToken: 'pk.eyJ1IjoibWl2dW5lIiwiYSI6ImNsNWNreTd0cTBpZHkza28xeWo1ODJzamEifQ.Srz2_XsFlucH_7qZzPqkLQ',
      mapStyle: 'mapbox://styles/mivune/cl5clbnp5001a14pvz0v0wugw',
      activeLayer: undefined,
      activeFeature: undefined,
      popupCoordinates: [8, 48], // this can't be blank!  it won't be shown but pick something
    }
  },

  computed: {
    coordinates() {
      return [(this.building.bbox[0] + this.building.bbox[2]) / 2, (this.building.bbox[1] + this.building.bbox[3]) / 2]
    },

    initialZoom() {
      // https://wiki.openstreetmap.org/wiki/Zoom_levels
      return Math.min(18, Math.floor(Math.log2(360 / (this.building.bbox[2] - this.building.bbox[0]))))
    },
  },

  methods: {
    onPopupClose(event) {
      this.activeLayer = undefined
      this.activeFeature = undefined
    },

    measureTileSelect(data) {
      this.activeLayer = 'measureTiles'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },
  },
}
</script>

<style>
.building-map {
  position: relative;
}

.building-map .info-panel {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 300px;
}

.building-map .map-container {
  height: calc(8rem + 18vw);
}
</style>
