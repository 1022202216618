<i18n>
{
  "de": {
    "yLabel": "Scope 1-2 Emissionen* (kg CO₂e/m²)",
    "xLabel": "Endenergie* (kWh/m²)",
    "emissionsTooltip": "Scope 1-2 Emissionen*",
    "emissionsUnitsTooltip": "kg CO₂e/m²",
    "energyTooltip": "Endenergie*",
    "energyUnitsTooltip": "kWh/m²",
    "withoutTenantSideNote": "*Mieterseitige Energie und mieterseitige Emissionen werden hier nicht miteingerechnet."
  }
}
</i18n>

<template>
  <div class="c-bubble-chart-wrapper">
    <BubbleChart :chart-data="processedChartData" :options="options" class="bubble-chart" />
    <span class="footnote">{{ $t('withoutTenantSideNote') }}</span>
  </div>
</template>

<script>
import _ from 'lodash'

import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import BubbleChart from '@/components/shared/charts/BubbleChart.vue'

export default {
  mixins: [
    //
    ChartOptionsMixin,
  ],

  components: {
    BubbleChart,
  },

  props: {
    highlightBuildingId: {
      type: Number,
      required: false,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.onChartClick,
        scales: {
          xAxis: {
            title: {
              display: true,
              text: this.$t('xLabel'),
            },
            beginAtZero: true,
          },
          yAxis: {
            title: {
              display: true,
              text: this.$t('yLabel'),
            },
            beginAtZero: true,
          },
        },
        plugins: {
          tooltip: {
            displayColors: false,
            callbacks: {
              title: this.getTooltipTitleWithIdentifier,
              beforeBody: this.beforeBody,
              beforeLabel: (item) =>
                this.getTooltipLabel(item, {
                  unit: this.$t('energyUnitsTooltip'),
                  label: this.$t('energyTooltip'),
                  axis: 'x',
                }),
              label: (item) =>
                this.getTooltipLabel(item, {
                  unit: this.$t('emissionsUnitsTooltip'),
                  label: this.$t('emissionsTooltip'),
                }),
            },
          },
          legend: {
            display: false,
          },
        },
      }
    },

    sortedChartData() {
      const data = []
      const highlightBuilding = this.chartData.find((b) => b.id === this.highlightBuildingId)

      this.chartData.forEach((building) => {
        if (building.id !== this.highlightBuildingId) {
          data.push(building)
        }
      })
      if (highlightBuilding) {
        data.push(highlightBuilding)
      }
      return data
    },

    processedChartData() {
      const datasets = []
      const data = []
      // const maxEnergyArea = Math.max(...[...this.sortedChartData.map((b) => b.energy_area), 10000])
      // const minEnergyArea = Math.min(...this.sortedChartData.map((b) => b.energy_area))

      this.sortedChartData.forEach((building) => {
        // Method 1: Scale radius by sqrt of energy area, min 6, max 16
        const radius = Math.max(6, Math.min(16, Math.sqrt(building.energy_area) / 9))
        // Method 2: Scale radius by taking min and max values and remapping to 6-16
        // const radius =
        //   6 +
        //   (16 - 6) * ((Math.min(building.energy_area, maxEnergyArea) - minEnergyArea) / (maxEnergyArea - minEnergyArea))
        data.push({
          r: radius,
          x: this.getKpiWithoutTenantSide(building.kpis.ee_by_usage) / building.energy_area,
          y: this.getKpiWithoutTenantSide(building.kpis.s12e_by_usage) / building.energy_area,
        })
      })

      datasets.push({
        backgroundColor: this.highlightBuildingId
          ? this.sortedChartData.map((b) => (b.id === this.highlightBuildingId ? '#188A02' : '#88888844'))
          : '#188A0255',
        backgroundOpacity: 0.4,
        borderColor: '#ffffff',
        borderWidth: 1.5,
        hoverBackgroundColor: '#188A02',
        hoverBorderColor: '#ffffff',
        hoverBorderWidth: 2,
        data: data,
      })

      return {
        datasets: datasets,
      }
    },
  },

  methods: {
    //
    beforeBody(items) {
      const building = this.sortedChartData[items[0].dataIndex]
      return `${building.description.trim()}${building.municipality ? `, ${building.municipality}` : ''}`
    },

    //
    getTooltipTitleWithIdentifier(items) {
      return this.sortedChartData[items[0].dataIndex].identifier || ''
    },

    //
    getKpiWithoutTenantSide(byUsage) {
      return _.entries(byUsage)
        .filter((e) => e[0] !== 'TENANTS')
        .reduce((sum, e) => sum + e[1], 0)
    },

    //
    onChartClick(event, items) {
      if (items.length !== 0) {
        this.$emit('click', this.sortedChartData[items[0].index])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-bubble-chart-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > * {
    width: 100%;
  }

  & .footnote {
    padding-top: 10px;
    font-size: var(--font-xs);
    color: #888;
    text-align: center;
  }
}
</style>
