<template>
  <div class="c-timeline-element">
    <div class="line-top" :class="{ transparent: type === 'INITIAL' }" />
    <div class="dot" :class="type" />
    <div class="line-bottom" :class="{ transparent: isLastMeasure }" />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String, // 'INITIAL', 'PLANNED', 'SCENARIO', 'SCENARIO_EDITED', 'COMPLETED', 'CURRENT' // TODO: 'FINAL'
      default: 'INITIAL',
    },
    isLastMeasure: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.c-timeline-element {
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xs);
  width: 64px;

  & .line-top,
  & .line-bottom {
    width: 2px;
    background-color: #eee;
  }

  & .dot {
    flex: none;
    width: 16px;
    height: 16px;
    background-color: #666;
    border: 4px solid #eee;
    border-radius: 50%;

    &.INITIAL {
      background-color: #111;
      border-color: #eee;
    }

    &.CURRENT {
      background-color: var(--color-primary);
      border-color: #ebf8e6;
    }

    &.PLANNED {
      background-color: #f29a4a;
      border-color: #ffecdb;
    }

    &.SCENARIO {
      background-color: #f29a4a;
      border-color: #ffecdb;
    }

    &.SCENARIO_EDITED {
      background-color: #f29a4a;
      border-color: #ffecdb;
    }

    &.COMPLETED {
      background-color: #007bff;
      border-color: #e5f2ff;
    }
  }

  & .line-top {
    flex: none;
    height: 40px;

    &.transparent {
      background-color: transparent;
    }
  }

  & .line-bottom {
    flex: 1;

    &.transparent {
      background-color: transparent;
    }
  }
}
</style>
