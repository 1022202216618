<i18n>
{
  "de": {
    "geothermalTitle": "Zulässigkeit Wärmenutzung",
    "reloadLabel": "Zulässigkeiten neu abfragen",
    "pendingMessage": "Daten werden abgefragt...",
    "lastUpdateLabel": "Daten aktualisiert am",
    "ewsAdmissibility": "Erdwärmesonden",
    "gwAdmissibility": "Wärmenutzung Grundwasser",
    "thermoElementsAdmissibility": "Thermoaktive Elemente",
    "liquidRegistersAdmissibility": "Erdregister/Energiekörbe mit flüssigen Wärmeträgern",
    "airRegistersAdmissibility": "Erdregister/Energiekörbe mit Luft betrieben",
    "sourceLabel": "Quelle",
    "sourceCantonLabel": "GIS Kanton",
    "openGisBrowser": "GIS-Browser öffnen",
    "maxEwsDepthLabel": "Tiefenbeschränkung",
    "ambiguousMessage": "(Nicht eindeutig, zur Sicherheit manuell überprüfen)"
  }
}
</i18n>

<template>
  <Modal class="c-geothermal-modal" @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('geothermalTitle') }}</h2>
    </template>
    <div v-if="error">
      <p>{{ error }}</p>
    </div>
    <div v-if="pending" class="loading">
      <ClipLoader size="16px" color="#000" />
      <div>{{ $t('pendingMessage') }}</div>
    </div>
    <div v-else-if="building.geothermal.state !== 'OK'" class="missing-data">
      {{ $t(`_geothermalState.${building.geothermal.state}`) }}
    </div>
    <div v-else class="admissibilities">
      <DetailList>
        <DetailListItem :title="$t('ewsAdmissibility')">
          <TextWithIcon
            :icon="ewsAdmissibilityIcon"
            :text="$t(`_admissibility.${building.geothermal.admissibilities.ews}`)"
          >
            <div v-if="building.geothermal.admissibilities.ews_ambiguous">{{ $t('ambiguousMessage') }}</div>
            <span v-if="building.geothermal.admissibilities.ews_max_depth">
              ({{ $t('maxEwsDepthLabel') }}: {{ building.geothermal.admissibilities.ews_max_depth }} m)</span
            >
          </TextWithIcon>
          <template v-if="building.geothermal.admissibilities.ews_remarks" #rightContent>
            {{ building.geothermal.admissibilities.ews_remarks }}
          </template>
        </DetailListItem>
        <DetailListItem :title="$t('gwAdmissibility')">
          <TextWithIcon
            :icon="gwAdmissibilityIcon"
            :text="$t(`_admissibility.${building.geothermal.admissibilities.gw}`)"
          >
            <div v-if="building.geothermal.admissibilities.gw_ambiguous">{{ $t('ambiguousMessage') }}</div>
          </TextWithIcon>
          <template v-if="building.geothermal.admissibilities.gw_remarks" #rightContent>
            {{ building.geothermal.admissibilities.gw_remarks }}
          </template>
        </DetailListItem>
        <DetailListItem
          v-if="building.geothermal.admissibilities.thermo_elements !== 'UNKNOWN'"
          :title="$t('thermoElementsAdmissibility')"
        >
          <TextWithIcon
            :icon="thermoElementsAdmissibilityIcon"
            :text="$t(`_admissibility.${building.geothermal.admissibilities.thermo_elements}`)"
          />
          <template v-if="building.geothermal.admissibilities.thermo_elements_remarks" #rightContent>
            {{ building.geothermal.admissibilities.thermo_elements_remarks }}
          </template>
        </DetailListItem>
        <DetailListItem
          v-if="building.geothermal.admissibilities.liquid_registers !== 'UNKNOWN'"
          :title="$t('liquidRegistersAdmissibility')"
        >
          <TextWithIcon
            :icon="liquidRegistersAdmissibilityIcon"
            :text="$t(`_admissibility.${building.geothermal.admissibilities.liquid_registers}`)"
          />
          <template v-if="building.geothermal.admissibilities.liquid_registers_remarks" #rightContent>
            {{ building.geothermal.admissibilities.liquid_registers_remarks }}
          </template>
        </DetailListItem>
        <DetailListItem
          v-if="building.geothermal.admissibilities.air_registers !== 'UNKNOWN'"
          :title="$t('airRegistersAdmissibility')"
        >
          <TextWithIcon
            :icon="airRegistersAdmissibilityIcon"
            :text="$t(`_admissibility.${building.geothermal.admissibilities.air_registers}`)"
          />
          <template v-if="building.geothermal.admissibilities.air_registers_remarks" #rightContent>
            {{ building.geothermal.admissibilities.air_registers_remarks }}
          </template>
        </DetailListItem>
      </DetailList>
    </div>

    <template v-if="!pending">
      <div class="text">
        <p v-if="building.geothermal.state === 'OK'">{{ $t('lastUpdateLabel') }}: {{ lastUpdateDate }}</p>
        <p v-if="building.geothermal.state === 'OK'">
          {{ $t('sourceLabel') }}: {{ $t('sourceCantonLabel') }} {{ building.location_info.data.canton }}
          <template v-if="building.geothermal.gis_url">
            (<a :href="building.geothermal.gis_url" target="_blank">{{ $t('openGisBrowser') }}</a
            >)
          </template>
        </p>
      </div>

      <ButtonWrapper>
        <button v-if="getBuildingPermission('REFRESH_GEOTHERMAL')" class="button" @click="onReload">
          {{ $t('reloadLabel') }}
        </button>
      </ButtonWrapper>
    </template>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import Modal from '@/components/shared/Modal.vue'
import TextWithIcon from '@/components/shared/TextWithIcon.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'

export default {
  components: {
    ButtonWrapper,
    TextWithIcon,
    DetailList,
    DetailListItem,
    Modal,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pending: null,
      error: null,
    }
  },

  computed: {
    ...mapGetters({
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),
    lastUpdateDate() {
      return new Date(this.building.geothermal.last_update).toLocaleDateString('de-DE')
    },

    ewsAdmissibilityIcon() {
      let colors = {
        ADMISSIBLE: 'check-circle',
        LIMITATIONS: 'alert-triangle',
        NON_ADMISSIBLE: 'x-circle',
        BAD_GEOLOGY: 'x-circle',
        ERROR: 'info-circle',
        UNKNOWN: 'help-circle',
      }
      if (this.building.geothermal.admissibilities.ews_ambiguous) {
        colors['ADMISSIBLE'] = 'alert-triangle'
      }
      return colors[this.building.geothermal.admissibilities.ews]
    },

    gwAdmissibilityIcon() {
      let colors = {
        ADMISSIBLE: 'check-circle',
        LIMITATIONS: 'alert-triangle',
        NON_ADMISSIBLE: 'x-circle',
        BAD_GEOLOGY: 'x-circle',
        ERROR: 'info-circle',
        UNKNOWN: 'help-circle',
      }
      if (this.building.geothermal.admissibilities.gw_ambiguous) {
        colors['ADMISSIBLE'] = 'alert-triangle'
      }
      return colors[this.building.geothermal.admissibilities.gw]
    },

    thermoElementsAdmissibilityIcon() {
      let colors = {
        ADMISSIBLE: 'check-circle',
        LIMITATIONS: 'alert-triangle',
        NON_ADMISSIBLE: 'x-circle',
        ERROR: 'info-circle',
        UNKNOWN: 'help-circle',
      }
      return colors[this.building.geothermal.admissibilities.thermo_elements]
    },

    liquidRegistersAdmissibilityIcon() {
      let colors = {
        ADMISSIBLE: 'check-circle',
        LIMITATIONS: 'alert-triangle',
        NON_ADMISSIBLE: 'x-circle',
        ERROR: 'info-circle',
        UNKNOWN: 'help-circle',
      }
      return colors[this.building.geothermal.admissibilities.liquid_registers]
    },

    airRegistersAdmissibilityIcon() {
      let colors = {
        ADMISSIBLE: 'check-circle',
        LIMITATIONS: 'alert-triangle',
        NON_ADMISSIBLE: 'x-circle',
        ERROR: 'info-circle',
        UNKNOWN: 'help-circle',
      }
      return colors[this.building.geothermal.admissibilities.air_registers]
    },
  },

  methods: {
    ...mapActions({
      queryGeothermal: 'building/queryGeothermal',
    }),

    async onReload() {
      this.pending = true
      try {
        this.error = null
        await this.queryGeothermal({
          id: this.building.id,
        })
      } catch (error) {
        this.error = error
      }
      this.pending = false
    },
  },
}
</script>

<style lang="scss" scoped>
.missing-data {
  color: var(--warning-color);
}

.admissibilities div ~ div {
  margin-top: var(--spacing-s);
}

.text {
  margin-top: var(--spacing-m);

  & a {
    text-decoration: underline;
  }
}

.loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-s);
}
</style>

<style lang="scss">
.c-geothermal-modal {
  & .item-right {
    flex: 1 40px !important;
  }
}
</style>
