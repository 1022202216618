<template>
  <div class="c-no-data-message">
    <div>
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.c-no-data-message {
  padding: var(--spacing-m);
  text-align: center;
  background-color: #fcfcfc;

  & div {
    opacity: 0.5;
  }
}
</style>
