<i18n>
{
  "de": {
    "elHeader": "Stromkonfiguration",
    "containerNote": "Das gewählte Stromprodukt wird für den Stromverbrauch (Allgemeinstrom und Mieterstrom) und für alle Heizungstypen mit dem Energieträger 'In der Liegenschaft definiertes Stromprodukt (Netz)' oder 'In der Liegenschaft definiertes Stromprodukt (Netz+PV)' verwendet.",
    "gridTitle": "Netzbezug",
    "pvTitle": "PV-Anlage"
  }
}
</i18n>

<template>
  <DetailListItem
    class="c-current-state-electricity-summary-row"
    :title="$t('elHeader')"
    expandable
    @expand="onModalOpen"
  >
    <div class="details">
      <span v-if="electricityGrid" class="pill">
        <span> {{ $t('gridTitle') }}: {{ electricityGrid }}</span>
      </span>
      <span v-if="electricityPv" class="pill">
        <span> {{ $t('pvTitle') }}: {{ electricityPv }}</span>
      </span>
      <span v-if="!electricityGrid && !electricityPv" class="default"
        >{{ $t('gridTitle') }}: {{ $t('_energyCarriers.ELECTRICITY_GRID') }}</span
      >
    </div>

    <template #modal>
      <Modal v-if="isModalOpen" wide @close="onModalClose">
        <template #header>
          <h2>{{ $t('elHeader') }}</h2>
        </template>
        <p>{{ $t('containerNote') }}</p>
        <br />
        <ViewElectricity :portfolio="portfolio" :building="building" />
      </Modal>
    </template>
  </DetailListItem>
</template>

<script>
import ModalMixin from '@/components/shared/ModalMixin.vue'

import Modal from '@/components/shared/Modal.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import ViewElectricity from '@/components/building/view/ViewElectricity.vue'

export default {
  mixins: [
    //
    ModalMixin,
  ],

  components: {
    Modal,
    DetailListItem,
    ViewElectricity,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    electricityGrid() {
      if (this.building.current_state.electricity_grid_id) {
        return `${this.getCarrierName(this.building.current_state.electricity_grid_id)}`
      } else {
        return null
      }
    },

    electricityPv() {
      if (this.building.current_state.electricity_pv_id) {
        return `${this.getCarrierName(this.building.current_state.electricity_pv_id)}`
      } else {
        return null
      }
    },
  },

  methods: {
    getCarrierName(id) {
      const carrier = this.portfolio.energy_carriers.find((carrier) => carrier.id === id)
      return carrier && carrier.default ? this.$t(`_energyCarriers.${carrier.name}`) : carrier.name
    },
  },
}
</script>

<style lang="scss" scoped>
.c-current-state-electricity-summary-row {
  & .details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--spacing-xxs);
    width: 100%;
  }

  & .pill {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    padding: 0 var(--spacing-s);
    border: var(--box-border);
    border-radius: 13px;
    height: 24px;
    font-size: 14px;

    & > span {
      display: inline-block;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  & .default {
    opacity: 0.5;
  }
}
</style>
