<i18n>
{
  "de": {
    "statusLabel": "Rolle",
    "statusInfoBoxTitle": "Die Rolle bestimmt den Funktionsumfang und Preisplan.",
    "statusInfoBoxMessage": "<ul><li>Planer-Liegenschaften können vollständig bearbeitet werden.</li><li>Reporter-Liegenschaften können teilweise bearbeitet werden, beschränkt auf Verbrauchsdaten und Massnahmen.</li><li>Archivierte Liegenschaften können nicht bearbeitet werden.</li></ul><p>Hinweis: Manche Änderungen der Rolle werden erst mit Beginn der nächsten Lizenzperiode wirksam.<p/>",
    "status": {
      "PLANNER": "Planer",
      "REPORTER": "Reporter",
      "ARCHIVED": "Archiviert"
    },
    "statusChangeText": {
      "PENDING_ARCHIVED": "Nächste Lizenzperiode: Archiviert",
      "PENDING_REPORTER": "Nächste Lizenzperiode: Reporter"
    },
    "confirmStatusChange": "Diese Rollenänderung kann Auswirkungen auf den Preis haben."
  }
}
</i18n>

<template>
  <EditContainer
    :bind-form-state="formState"
    :allow-edit="getBuildingPermission('EDIT_STATUS')"
    class="c-edit-status"
    @container="onFormEvent"
  >
    <FormRow :label="$t('statusLabel')" :info-title="$t('statusInfoBoxTitle')" :info-text="$t('statusInfoBoxMessage')">
      <Select
        v-if="isEditing"
        id="status-options"
        v-model="model.status"
        class="limit-width"
        :options="statusOptions"
        :reduce="(status) => status.id"
      />
      <div v-else-if="model.status">
        {{
          `${$t(`status.${model.status}`)} ${
            isPending ? ` (${$t(`statusChangeText.${building.status_change_pending}`)})` : ''
          }`
        }}
      </div>
      <div v-else>-</div>
    </FormRow>
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'
import EditContainer from '@/components/shared/forms/EditContainer.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import Select from '@/components/shared/forms/Select.vue'

import { BUILDING_STATUS } from '@/models/Building'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    EditContainer,
    FormRow,
    Select,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    statusOptions() {
      return _.keys(BUILDING_STATUS).map((status) => ({
        id: status,
        label: this.$t(`status.${status}`),
      }))
    },

    isPending() {
      return Boolean(this.building.status_change_pending)
    },
  },

  watch: {
    building() {
      this.resetModel()
    },

    isEditing() {
      this.resetModel()
    },
  },

  mounted() {
    this.resetModel()
  },

  methods: {
    ...mapActions({
      updateBuildingStatus: 'building/updateBuildingStatus',
    }),

    resetModel() {
      this.model = {
        status: this.building.status,
      }
    },

    async saveHandler(model) {
      if ((model.status === 'PLANNER' || model.status === 'REPORTER') && this.building.status !== 'PLANNER') {
        if (!confirm(this.$t('confirmStatusChange'))) {
          return
        }
      }

      await this.updateBuildingStatus({
        id: this.building.id,
        status: model.status,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-edit-status {
  & .limit-width {
    max-width: 160px;
    width: 100%;
  }
}
</style>
