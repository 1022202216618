<i18n>
{
  "de": {
    "measureModalTitle": "Massnahmendetails",
    "kpiHeading": "Kennzahlen"
  }
}
</i18n>

<template>
  <Modal :width="760" @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('measureModalTitle') }}</h2>
    </template>
    <KpiChangeSummary :kpi-after="measure.kpi" :kpi-before="lastKpi" />
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import KpiChangeSummary from '@/components/building/measures/KpiChangeSummary.vue'

export default {
  components: {
    Modal,
    KpiChangeSummary,
  },

  props: {
    measure: {
      type: Object,
    },
    lastKpi: {
      type: Object,
    },
  },
}
</script>
