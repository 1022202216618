<i18n>
{
  "de": {
    "pvYear": "Baujahr",
    "remarksTitle": "Bemerkungen"
  }
}
</i18n>

<template>
  <Box class="c-view-pv">
    <FormRow :label="$t('pvYear')">
      <NumericInput
        v-model="model.year"
        class="limit-width"
        noFormatting
        :optional="isPvSectionsEmpty"
        int
        :min="1900"
        :max="2100"
        :edit="false"
      />
      <EstimateInput v-model="model.year_estimated" :edit="false" />
    </FormRow>

    <FormRow :label="$t('remarksTitle')">
      <TextInput v-model="model.remarks" :edit="false" :allow-empty="true" />
    </FormRow>

    <EditPvSections v-model="model.sections" :pv-results="pvResults" :edit="false" />
  </Box>
</template>

<script>
import Box from '@/components/cui/layout/Box.vue'
import EditPvSections from '@/components/building/edit/EditPvSections.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import EstimateInput from '@/components/shared/forms/EstimateInput.vue'

export default {
  components: {
    Box,
    NumericInput,
    FormRow,
    TextInput,
    EstimateInput,
    EditPvSections,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    pvResults() {
      return this.building.current_state.result && this.building.current_state.result.pv
    },

    isPvSectionsEmpty() {
      return this.model.sections.length === 0
    },
  },

  watch: {
    building: function () {
      this.resetModel()
    },
  },

  created() {
    this.resetModel()
  },

  methods: {
    validateSections(validation, field) {
      this.onValidation(validation, field)
    },

    resetModel() {
      this.model = {
        sections: [],
        year: this.building.current_state.pv.year,
        year_estimated: this.building.current_state.pv.year_estimated,
        remarks: this.building.current_state.pv.remarks,
      }
      for (let i = 0; i < this.building.current_state.pv.sections.length; i++) {
        const section = this.building.current_state.pv.sections[i]
        this.model.sections.push({
          capacity: section.capacity,
          angle: section.angle,
          orientation: section.orientation,
          loss: section.loss,
          production_manual: section.production_manual,
          ownuse_ratio_manual: section.ownuse_ratio_manual,
        })
      }
    },
  },
}
</script>

<style lang="scss">
.c-view-pv {
  & .limit-width {
    max-width: 150px;
    display: inline-block;
  }
}
</style>
