<template>
  <div class="c-meters-recent-values">
    <!-- Energy -->
    <div class="values-box" :class="{ hidden: dataType !== 'energy' }">
      <KpiBox
        :label="recentYears[0]?.toString()"
        :value="formatNumberWithThreshold(eeValues.totalValues[0] / 1000, 2, 1, 1)"
        valueUnit="MWh"
        :relativeValue="formatNumberWithThreshold(eeValues.relativeValues[0], 2, 1, 1)"
        relativeValueUnit="kWh/m²"
        className="energy-kpi"
      />
      <KpiBox
        v-if="!isSingleYear"
        :label="recentYears[1]?.toString()"
        :value="formatNumberWithThreshold(eeValues.totalValues[1] / 1000, 2, 1, 1)"
        valueUnit="MWh"
        :relativeValue="formatNumberWithThreshold(eeValues.relativeValues[1], 2, 1, 1)"
        relativeValueUnit="kWh/m²"
        className="energy-kpi"
      />
      <div v-if="!isSingleYear" class="difference-box">
        <div :class="eeValues.percentageChange >= 0 ? 'positive' : 'negative'">
          {{ eeValues.percentageChange >= 0 ? '↑' : '↓' }} {{ Math.abs(eeValues.percentageChange) }}%
        </div>
      </div>
    </div>

    <!-- Emissions -->
    <div class="values-box" :class="{ hidden: dataType !== 'emissions' }">
      <KpiBox
        :label="recentYears[0]?.toString()"
        :value="formatNumberWithThreshold(s12eValues.totalValues[0] / 1000, 2, 1, 1)"
        valueUnit="t CO₂e"
        :relativeValue="formatNumberWithThreshold(s12eValues.relativeValues[0], 2, 1, 1)"
        relativeValueUnit="kg CO₂e/m²"
        className="emissions-kpi"
      />
      <KpiBox
        v-if="!isSingleYear"
        :label="recentYears[1]?.toString()"
        :value="formatNumberWithThreshold(s12eValues.totalValues[1] / 1000, 2, 1, 1)"
        valueUnit="t CO₂e"
        :relativeValue="formatNumberWithThreshold(s12eValues.relativeValues[1], 2, 1, 1)"
        relativeValueUnit="kg CO₂e/m²"
        className="emissions-kpi"
      />
      <div v-if="!isSingleYear" class="difference-box">
        <div :class="s12eValues.percentageChange >= 0 ? 'positive' : 'negative'">
          {{ s12eValues.percentageChange >= 0 ? '↑' : '↓' }} {{ Math.abs(s12eValues.percentageChange) }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import KpiBox from '@/components/shared/kpi/KpiBox.vue'

export default {
  components: {
    KpiBox,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
    dataType: {
      type: String, // 'energy' or 'emissions'
      required: true,
    },
  },

  computed: {
    meters() {
      // Return all meters except ELECTRICITY_PV
      return this.building.meters
        .filter((meter) => meter.meter_type !== 'ELECTRICITY_PV')
        .map((meter) => {
          return {
            type: meter.meter_type,
            data: meter.values,
          }
        })
    },

    recentYears() {
      // Iterate over all meters and get the unique years ordered chronologically
      const years = []
      this.meters.map((data) => data.data.map((d) => years.push(d.year)))
      const uniqueYears = [...new Set(years)].sort((a, b) => a - b)
      return uniqueYears.length > 1 ? uniqueYears.slice(-2) : uniqueYears
    },

    isSingleYear() {
      return this.recentYears.length === 1
    },

    eeValues() {
      return this.getValues('amount_normalized')
    },

    s12eValues() {
      return this.getValues('s12e')
    },

    kpiTypes() {
      return ['amount', 'amount_normalized', 's12e', 's1e', 'pe']
    },

    totalValues() {
      // Return array of objects with totals for each kpi type
      // Example:
      // {
      //   amount: 1000,
      //   amount_normalized: 1000,
      //   s12e: 1000,
      //   s1e: 1000,
      //   pe: 1000,
      // }
      return this.recentYears.map((year) => {
        return this.kpiTypes.reduce((acc, kpiType) => {
          acc[kpiType] = this.meters.reduce(
            (pv, meter) => pv + meter.data.filter((d) => d.year === year).reduce((pv, cv) => pv + cv[kpiType], 0),
            0
          )
          return acc
        }, {})
      })
    },

    relativeValues() {
      return this.totalValues.map((totalValues) => {
        return this.kpiTypes.reduce((acc, kpiType) => {
          acc[kpiType] = totalValues[kpiType] / this.building.kpi.energy_area
          return acc
        }, {})
      })
    },
  },

  methods: {
    getValues(kpiType) {
      return {
        years: this.recentYears,
        totalValues: this.getTotalValues(kpiType),
        relativeValues: this.getRelativeValues(kpiType),
        percentageChange: !this.isSingleYear ? this.getPercentageChange(kpiType) : null,
      }
    },

    getTotalValues(kpiType) {
      return this.totalValues.map((totalValues) => totalValues[kpiType])
    },

    getRelativeValues(kpiType) {
      return this.relativeValues.map((relativeValues) => relativeValues[kpiType])
    },

    getPercentageChange(kpiType) {
      const [previousYear, currentYear] = this.totalValues

      return _.round(((currentYear[kpiType] - previousYear[kpiType]) / previousYear[kpiType]) * 100, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-meters-recent-values {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eeeeee;
  background-color: #fcfcfc;

  & .values-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
    padding: var(--spacing-xxl) 0;

    &.hidden {
      @media (max-width: 900px) {
        display: none;
      }
    }

    &:first-child {
      border-right: 1px solid #eeeeee;

      @media (max-width: 900px) {
        border-right: none;
      }
    }

    & .difference-box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 var(--spacing-s);

      & div {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        padding: var(--spacing-xxs) var(--spacing-s);
        border-radius: 16px;

        &.negative {
          color: #1a9900;
          background-color: #1a99001a;
        }

        &.positive {
          color: #991a00;
          background-color: #991a001a;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.c-meters-recent-values {
  & .values-box {
    & .c-kpi-box {
      & .value {
        font-size: 24px;
      }
    }
  }
}
</style>
