<i18n>
{
  "de": {
    "mapButton": "Karte",
    "satelliteButton": "Satellit",
    "noLocation": "Standortdaten fehlen"
  }
}
</i18n>

<template>
  <div class="c-building-map-container">
    <!-- Map / Sattelite toggle -->
    <div class="buttons-container">
      <nav class="round-buttons">
        <button type="button" :class="{ selected: mapMode === 'satellite' }" @click="setMapMode('satellite')">
          <span>{{ $t('satelliteButton') }}</span>
        </button>
        <button type="button" :class="{ selected: mapMode === 'map' }" @click="setMapMode('map')">
          <span>{{ $t('mapButton') }}</span>
        </button>
      </nav>
    </div>

    <!-- Map -->
    <template v-if="building && building.latitude !== null && building.longitude !== null">
      <keep-alive>
        <BuildingMap v-if="mapMode === 'satellite'" :building="building" map-mode="satellite" />
      </keep-alive>
      <keep-alive>
        <BuildingMap v-if="mapMode === 'map'" :building="building" map-mode="map" />
      </keep-alive>
    </template>
    <!-- No location data -->
    <template v-else>
      <div class="no-location">{{ $t('noLocation') }}</div>
    </template>
  </div>
</template>

<script>
import BuildingMap from '@/components/building/map/BuildingMap.vue'

export default {
  components: {
    BuildingMap,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    mapMode() {
      return this.$store.state.ui.buildingMapMode
    },
  },

  created() {
    // Initialize map vs satellite
    const mapMode = localStorage.getItem('buildingMapMode')
    if (mapMode) {
      this.$store.dispatch('ui/setBuildingMapMode', mapMode)
    }
  },

  methods: {
    //
    setMapMode(mode) {
      this.$store.dispatch('ui/setBuildingMapMode', mode)
      localStorage.setItem('buildingMapMode', mode)
    },
  },
}
</script>

<style lang="scss">
.c-building-map-container {
  position: relative;
  max-height: 540px;

  & .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 12px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
  }

  & .no-location {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 65px);
    width: 100%;
    background-color: #eee;
    color: #888;
  }
}
</style>
