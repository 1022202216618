<i18n>
{
  "de": {
    "message": "Die folgenden Liegenschaften wurden ausgeschlossen, da sie bei der Berechnung Fehler aufwiesen:"
  }
}
</i18n>

<template>
  <div v-if="faults.length != 0" class="faults-container">
    <p>
      {{ $t('message') }}
      <span v-for="f in faults" :key="f.building.id" class="building-id">
        <router-link
          :to="{ name: 'buildingDetails', params: { portfolio_id: portfolio.id, building_id: f.building.id } }"
        >
          {{ f.building.id }}
        </router-link>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    faults: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style>
.faults-container {
  text-align: center;
  color: red;
}

.faults-container .building-id a {
  color: red;
  border-bottom: 1px solid red;
  margin-right: 4px;
}
</style>
