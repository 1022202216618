<i18n>
{
  "de": {
    "loadingMessage": "Daten werden geladen. Dies kann lange dauern, wenn viele Liegenschaften und Szenarien geladen werden müssen.",
    "errorMessage": "Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
  }
}
</i18n>

<template>
  <div :class="classes">
    <div v-if="contentState.error">
      {{ $t('errorMessage') }}
    </div>
    <div v-if="contentState.pending" class="pending">
      <ClipLoader size="16px" color="#000" />
      <div>{{ $t('loadingMessage') }}</div>
    </div>
    <template v-if="contentState.ready && !contentState.pending && !contentState.error">
      <slot></slot>
    </template>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    ClipLoader,
  },

  props: {
    contentState: {
      type: Object,
      required: true,
    },
  },

  computed: {
    classes() {
      return {
        'async-content-container-message': this.contentState.pending || this.contentState.error,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.async-content-container-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pending {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);
  line-height: 16px;
}
</style>
