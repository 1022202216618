<script>
import BaseLayer from '@/components/building/map/BaseLayer.vue'

export default {
  components: {
    BaseLayer,
  },

  extends: BaseLayer,

  computed: {
    sourceConfig() {
      let features = []
      if (this.building.energy_grids.state === 'OK') {
        for (let i = 0; i < this.building.energy_grids.energy_grids.length; i++) {
          let grid = this.building.energy_grids.energy_grids[i]
          if (grid.geometry.type === 'Point') {
            features.push({
              id: i,
              type: 'Feature',
              geometry: grid['geometry'],
              properties: grid,
            })
          }
        }
      }
      return {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: features,
        },
      }
    },

    layerConfig() {
      return {
        type: 'symbol',
        source: this.sourceId,
        paint: {
          'text-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#000000', '#222222'],
        },
        layout: {
          'text-field': ['get', 'description'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-size': 14,
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'icon-image': 'dot_blue',
          'icon-size': 2,
        },
      }
    },
  },
}
</script>
