<template>
  <svg class="ring-indicator" :viewBox="`0 0 ${size} ${size}`">
    <circle class="ring-track" :cx="center" :cy="center" :r="radius" />
    <circle
      v-if="year"
      class="ring-progress"
      :class="progressClasses"
      :cx="center"
      :cy="center"
      :r="radius"
      :style="progressStyles"
    />
  </svg>
</template>

<script>
export default {
  props: {
    year: {
      type: Number,
    },
    minLifetime: {
      type: Number,
    },
    maxLifetime: {
      type: Number,
    },
  },

  data() {
    return {
      size: 400,
      radius: 170,
      circle: { color: 'xd', opacity: 0.5, angle: 360, offset: 0 },
    }
  },

  computed: {
    center() {
      return this.size / 2
    },

    currentYear() {
      return new Date().getFullYear()
    },

    // New
    progressClasses() {
      return {
        // If the age of the building component is greater or equal to the max lifetime + 5 years, the ring is red.
        'ring-progress--very-high': this.currentYear >= this.maxLifetime,
        // If the age of the building component is between the min lifetime and max lifetime + 5 years, the ring is orange.
        'ring-progress--high': this.currentYear >= this.minLifetime && this.currentYear < this.maxLifetime,
        // If the age of building component is between 90% of its min lifetime, the ring is yellow.
        'ring-progress--mid':
          this.currentYear >= this.year + (this.minLifetime - this.year) * 0.9 && this.currentYear < this.minLifetime, // Example: 2021 + (2025 - 2021) * 0.90 = 2023
        // If the age of the building component is less than 90% of its min lifetime, the ring is green.
        'ring-progress--normal': this.currentYear < this.year + (this.minLifetime - this.year) * 0.9,
        // If the age of the building component is the same as the current year, the ring is green.
        'ring-progress--none': this.currentYear === this.year,
      }
    },

    progressStyles() {
      const circumference = 2 * Math.PI * this.radius
      // Explanation: strokeLength is the length of the stroke that is drawn.
      // It is the circumference of the circle multiplied by the ratio of the current year to the max lifetime.
      // The ratio is capped at 1.0.

      // Brand new building component
      if (this.currentYear === this.year) {
        return {
          'stroke-dasharray': `${circumference} ${circumference}`,
          'stroke-dashoffset': 0,
        }
      }

      // Normal case
      const strokeLength =
        circumference * Math.min(1.0, (this.currentYear - this.year) / (this.maxLifetime - this.year))
      return {
        'stroke-dasharray': `${strokeLength} ${circumference - strokeLength}`,
        'stroke-dashoffset': circumference / 4,
      }
    },
  },
}
</script>

<style lang="scss">
.ring-indicator {
  fill-rule: evenodd;
  clip-rule: evenodd;
  max-width: 100%;

  --circle-stroke-width: 60;

  & circle {
    fill: none;
    stroke: currentColor;
    stroke-width: var(--circle-stroke-width);
  }

  & .ring-track {
    color: var(--track-color);
  }

  & .ring-progress {
    color: transparent;

    &.ring-progress--very-high {
      color: #f63c14;
    }

    &.ring-progress--high {
      color: #f69b12;
    }

    &.ring-progress--mid {
      color: #ffcc19;
    }

    &.ring-progress--normal {
      color: #36b908;
    }

    &.ring-progress--none {
      color: #36b908;
    }
  }
}
</style>
