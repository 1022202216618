<i18n>
{
  "de": {
    "title": "Liegenschaft",
    "scrollToMeasures": "Zum Lebenszyklus & Massnahmen",
    "tooltip": "Hinweis: Dieses Anzeigefenster dient als Überblick über die wichtigsten Gebäudestammdaten des aktuellen Zustandes (und nicht des initialen Zustandes).<br><br>Abgesehen von den Kategorien «Bezeichnung & Status» und «Standort» können die Werte hier nicht bearbeitet werden. Die Bearbeitung der Gebäudestammdaten erfolgt im Anzeigefenster «Initialer Zustand» des Bereiches «Lebenszyklus & Massnahmen».<br><br>Wichtig: Die Gebäudestammdaten werden grundsätzlich nur für den initialen Zustand, also den ersten bekannten Zustand des Gebäudes, festgelegt. Eine Änderung des Zustandes im Lebenszyklus, z.B. Heizungsersatz, wird durch die Erstellung von Massnahmen implementiert. Aktualisierungen der Stammdaten aufgrund präziserer Informationslage erfolgt durch die Änderung des initialen Zustandes im Bereich «Lebenszyklus & Massnahmen»."
  }
}
</i18n>

<template>
  <Card collapsible section="building" class="c-status-and-location-overview">
    <CardHeader slot="header" icon="cards/location">
      <template #title>
        <div class="c-status-and-location-overview__title">
          <span>{{ $t('title') }}</span>
          <InfoBox>
            <div v-html="$t('tooltip')" />
          </InfoBox>
        </div>
      </template>
    </CardHeader>
    <CardContent slot="content">
      <List divider>
        <DescriptionAndStatusSummaryRow :building="building" />
        <LocationSummaryRow :building="building" />
      </List>

      <!-- Current state summary -->
      <BuildingSummaryRows :building="building" :portfolio="portfolio" />

      <!-- Link to measures -->
      <div class="link-to-measures-container">
        <div>
          <Button
            className="settings-tag-groups-button"
            :text="$t('scrollToMeasures')"
            @click="onScrollToMeasuresSection"
          />
        </div>
      </div>
    </CardContent>
  </Card>
</template>

<script>
import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import List from '@/components/cui/data-display/List.vue'
import Button from '@/components/cui/inputs/Button.vue'
import DescriptionAndStatusSummaryRow from '@/components/building/overview/DescriptionAndStatusSummaryRow.vue'
import LocationSummaryRow from '@/components/building/overview/LocationSummaryRow.vue'
import BuildingSummaryRows from '@/components/building/overview/BuildingSummaryRows.vue'
import InfoBox from '@/components/shared/InfoBox.vue'

export default {
  components: {
    Card,
    CardHeader,
    CardContent,
    List,
    Button,
    DescriptionAndStatusSummaryRow,
    LocationSummaryRow,
    BuildingSummaryRows,
    InfoBox,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
  },

  methods: {
    //
    onScrollToMeasuresSection() {
      this.$nextTick(() => {
        const measuresSection = document.getElementById('measures')
        if (measuresSection) {
          measuresSection.scrollIntoView({ behavior: 'smooth' })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-status-and-location-overview {
  .c-status-and-location-overview__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-xxs);
  }

  .link-to-measures-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 64px;
    border-top: var(--box-border);
  }
}
</style>
