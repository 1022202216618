<i18n>
{
  "de": {
    "statusPillText": {
      "PLANNER": "PLANER",
      "REPORTER": "REPORTER",
      "ARCHIVED": "ARCHIVIERT"
    }
  }
}
</i18n>

<template>
  <div :class="`c-status-pill ${building.status}`">
    {{ $t(`statusPillText.${building.status}`) }}
  </div>
</template>

<script>
export default {
  props: {
    building: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.c-status-pill {
  font-weight: 700;
  font-size: var(--font-xs);
  line-height: var(--font-xs);
  letter-spacing: 2px;
  padding: 6px 12px;
  border-radius: 13px;

  &.PLANNER {
    color: #1a9900;
    background-color: #1a99001a;
  }

  &.REPORTER {
    color: #008099;
    background-color: #0080991a;
  }

  &.ARCHIVED {
    color: #777777;
    background-color: #7777771a;
  }
}
</style>
