<i18n>
{
  "de": {
    "metersTitle": "Energie-Monitoring",
    "normalizationStation": "Klimastation",
    "normalizationTemperature": "Normtemperatur",
    "normalizationAltitude": "Höhe"
  }
}
</i18n>

<template>
  <Card collapsible section="meters" class="c-meters-section">
    <CardHeader slot="header" :title="$t('metersTitle')" :icon="'cards/meters'" />
    <CardContent slot="content">
      <div v-if="hasData" class="meters-charts-and-values-container">
        <MetersChartsAndValues :building="building" :portfolio="portfolio" />
      </div>
      <MetersSectionTable :building="building" :portfolio="portfolio" />
    </CardContent>
    <CardFooter slot="footer" class="climate-station-info">
      {{ climateStationInfo }}
    </CardFooter>
  </Card>
</template>

<script>
import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import CardFooter from '@/components/cui/surfaces/CardFooter.vue'
import MetersSectionTable from '@/components/building/meters/MetersSectionTable.vue'
import MetersChartsAndValues from '@/components/building/meters/MetersChartsAndValues.vue'

export default {
  components: {
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    MetersSectionTable,
    MetersChartsAndValues,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    climateStationInfo() {
      const name = this.building?.location_info?.data?.climate_station?.name
      const altitude = this.building?.location_info?.data?.climate_station?.altitude
        ? `${this.building.location_info.data.climate_station.altitude} m`
        : null
      const normTemp = this.building?.location_info?.data?.climate_station?.norm_temperature
        ? `${this.building.location_info.data.climate_station.norm_temperature} °C`
        : null
      let result = ''

      if (name) result += `${this.$t('normalizationStation')}: ${name}`
      if (name && altitude && normTemp)
        result += ` (${this.$t('normalizationAltitude')}: ${altitude}, ${this.$t(
          'normalizationTemperature'
        )}: ${normTemp})`

      return result || `${this.$t('normalizationStation')}: -`
    },

    hasData() {
      return this.building.meters.some((meter) => meter.values.length > 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-meters-section {
  min-width: 500px;
  overflow: hidden;
  margin-bottom: var(--spacing-l);

  & .meters-charts-and-values-container {
    min-height: 668px;
    background-color: #fcfcfc;
  }

  & .climate-station-info {
    background-color: #fff;
    color: #888;
    border-top: var(--box-border);
    padding: var(--spacing-s);
  }
}
</style>
