<i18n>
{
  "de": {
    "zonesSummaryHeader": "Fläche & Nutzung",
    "envelopeSummaryHeader": "Gebäudehülle",
    "heatingSummaryHeader": "Heizung",
    "electricitySummaryHeader": "Stromkonfiguration",
    "photovoltaicSummaryHeader": "Photovoltaik",
    "yearBuilt": "Baujahr",
    "areaEbfLabel": "m² EBF",
    "gridTitle": "Netzbezug",
    "pvTitle": "PV-Anlage",
    "missingBuildingYear": "Baujahr unbekannt",
    "yearUnknownWarning": "Alter der Wärmeerzeugung unsicher",
    "componentYearsUnknown": "Der Zustand der Gebäudehülle ist teilweise unbekannt",
    "missingZones": "Keine Zonen definiert",
    "missingEnergyArea": "Fläche fehlt",
    "areaGisLabel": "Flächen aus GIS-Daten abgeleitet",
    "heatingPower": "Heizleistung",
    "buildingComponents": {
      "walls": "Fassade",
      "windows": "Fenster",
      "basement": "Kellerdecke",
      "roof": "Dach"
    }
  }
}
</i18n>

<template>
  <!-- Current state summary -->
  <Box className="c-building-summary-rows">
    <!-- Zones summary row -->
    <DetailListItem :title="$t('zonesSummaryHeader')">
      <Box class="details">
        <Box v-if="totalEnergyArea" className="pill">
          <span>{{ formatNumber(totalEnergyArea, 0) }} {{ $t('areaEbfLabel') }}</span>
        </Box>
        <Box v-for="(name, index) in zonesList" :key="index" className="pill">
          <span>
            {{ name }}
          </span>
        </Box>
        <MissingDataLabel v-if="areaFromGis">
          {{ $t('areaGisLabel') }}
        </MissingDataLabel>
        <MissingDataLabel v-if="totalEnergyArea === undefined">
          {{ $t('missingEnergyArea') }}
        </MissingDataLabel>
        <MissingDataLabel v-if="building.current_state.zones.zones.length === 0">
          {{ $t('missingZones') }}
        </MissingDataLabel>
      </Box>
    </DetailListItem>

    <!-- Envelope summary row -->
    <DetailListItem :title="$t('envelopeSummaryHeader')">
      <Box class="details">
        <!-- Missing building year -->
        <div
          v-if="
            building.current_state.result &&
            (!building.current_state.result.envelope || !building.current_state.result.envelope.building_year.value)
          "
        >
          <MissingDataLabel>{{ $t('missingBuildingYear') }}</MissingDataLabel>
        </div>

        <!-- Building component years unknown -->
        <DataWarning v-if="componentYearsUnknown">{{ $t('componentYearsUnknown') }}</DataWarning>

        <!-- Building year -->
        <Box v-if="building.current_state.result" class="pill">
          <span>{{ $t('yearBuilt') }}: {{ building.current_state.result.envelope.building_year.value }}</span>
        </Box>

        <!-- Building components -->
        <template v-if="buildingComponents">
          <!-- TODO: TBD if needed -->
          <!-- <span v-if="fullRenovation" class="pill">
            {{ $t('renovationLabel') }}
          </span>
          <span v-else-if="partialRenovation" class="pill">
            {{ $t('partialRenovationLabel') }}
          </span>
          <span v-else class="pill">
            {{ $t('originalStateLabel') }}
          </span> -->
          <template v-for="buildingComponent in ['walls', 'windows', 'roof', 'basement']">
            <span :key="buildingComponent" class="pill">
              {{ `${$t(`buildingComponents.${buildingComponent}`)}:&nbsp;${buildingComponents[buildingComponent]}` }}
            </span>
          </template>
        </template>
      </Box>
    </DetailListItem>

    <!-- Heating summary row -->
    <DetailListItem :title="$t('heatingSummaryHeader')">
      <Box class="details">
        <template v-if="hasMissingHeating">
          <MissingDataLabel>{{ $t('missingHeatingType') }}</MissingDataLabel>
        </template>
        <template v-else>
          <DataWarning v-if="hasHeating && building.current_state.heating.year_estimated">{{
            $t('yearUnknownWarning')
          }}</DataWarning>
          <Box v-for="(name, index) in heatersList" :key="index" class-name="pill">
            <span>
              {{ name }}
            </span>
          </Box>
          <Box v-if="heatersList.length > 0 && hasHeating" class-name="pill">
            <span>
              {{
                building.current_state.heating.year || building?.current_state?.result?.envelope?.building_year?.value
              }}
            </span>
          </Box>
          <Box v-if="building.current_state.result.heating_power" class-name="pill">
            <span>
              {{ $t('heatingPower') }}:
              {{ formatNumberWithThreshold(building.current_state.result.heating_power, 1, 1, 0) }} kW
            </span>
          </Box>
          <Box v-if="heatersList.length === 0" class-name="no-heating">{{ $t('noHeating') }}</Box>
        </template>
      </Box>
    </DetailListItem>

    <!-- Electricity summary row -->
    <DetailListItem :title="$t('electricitySummaryHeader')">
      <Box class="details">
        <Box v-if="electricityGrid" class="pill">
          <span> {{ $t('gridTitle') }}: {{ electricityGrid }}</span>
        </Box>
        <Box v-if="electricityPv" class="pill">
          <span> {{ $t('pvTitle') }}: {{ electricityPv }}</span>
        </Box>
        <span v-if="!electricityGrid && !electricityPv" class="default"
          >{{ $t('gridTitle') }}: {{ $t('_energyCarriers.ELECTRICITY_GRID') }}</span
        >
      </Box>
    </DetailListItem>

    <!-- Photovoltaic summary row -->
    <DetailListItem v-if="photovoltaicSummary" :title="$t('photovoltaicSummaryHeader')">
      <Box class="details">
        <span class="pill">{{ photovoltaicSummary }}</span>
      </Box>
    </DetailListItem>
  </Box>
</template>

<script>
import Box from '@/components/cui/layout/Box.vue'

import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import MissingDataLabel from '@/components/shared/MissingDataLabel.vue'
import DataWarning from '@/components/shared/DataWarning.vue'

export default {
  components: {
    Box,
    DetailListItem,
    MissingDataLabel,
    DataWarning,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
  },

  computed: {
    componentYearsUnknown() {
      for (const c of ['walls', 'roof', 'basement', 'windows']) {
        if (this.building.current_state.envelope[c].year_estimated) {
          return true
        }
      }
      return false
    },

    buildingComponents() {
      let componentYears = {}
      for (const c of ['walls', 'roof', 'basement', 'windows']) {
        const buildingYear = this.building.current_state.result.envelope.building_year.value
        const componentYear = this.building.current_state.envelope[c].year || buildingYear
        componentYears[c] = componentYear
      }
      return componentYears
    },

    hasHeating() {
      return !this.building.current_state.envelope.without_heating
    },

    heatersList() {
      const heaters = this.building.current_state.heating.heaters
      const result = []

      heaters.forEach((heater) => {
        if (this.getRawNameForId(heater.heating_type_id) === 'LOCAL') {
          if (heater.heat_grid_identifier) {
            result.push(
              `${this.getNameForId(heater.heating_type_id)}: ${this.getGridLabel(heater.heat_grid_identifier)}`
            )
          }
        } else {
          result.push(this.getNameForId(heater.heating_type_id))
        }
      })
      return result
    },

    hasMissingHeating() {
      if (
        this.building.current_state.envelope.without_heating &&
        this.building.current_state.zones.zones.find((z) => z.dhw_demand && z.dhw_demand > 0) === undefined
      ) {
        // Don't require heating if envelope is not heated and no dhw demand
        return false
      }
      return (
        this.building.current_state.heating.heaters.find((z) => z.type === 'UNKNOWN') ||
        this.building.current_state.heating.heaters.length === 0
      )
    },

    totalEnergyArea() {
      const sum = this.building.current_state.zones.zones.reduce((pv, cv) => {
        if (cv.energy_area.deduced) {
          return pv + parseFloat(cv.energy_area.deduced)
        } else if (cv.energy_area) {
          return pv + parseFloat(cv.energy_area)
        } else {
          return pv
        }
      }, 0)
      if (Number.isNaN(sum)) {
        return undefined
      }
      return sum
    },

    areaFromGis() {
      if (this.building.current_state.result !== null) {
        return this.building.current_state.zones.zones.reduce(
          (pv, cv) =>
            pv + (cv.energy_area.source === 'GWR_EXTRAPOLATION' || cv.energy_area.source === 'SONNENDACH_EXTRAPOLATION')
              ? 1
              : 0,
          0
        )
      }
      return undefined
    },

    hasUnknownZoneUsages() {
      return this.building.current_state.zones.zones.find((z) => z.usage === 'UNKNOWN')
    },

    zonesList() {
      let result = []
      for (let zoneIdx = 0; zoneIdx < this.building.current_state.zones.zones.length; zoneIdx++) {
        const zone = this.building.current_state.zones.zones[zoneIdx]
        const zoneName = this.$t(`_usageTypes.${zone.usage}`)
        const zoneShare =
          zone.energy_area.deduced && this.formatNumber((zone.energy_area.deduced / this.totalEnergyArea) * 100, 0)
        if (Number.isNaN(zoneShare) || zoneShare === false || this.building.current_state.zones.zones.length === 1) {
          result.push(zoneName)
        } else {
          result.push(`${zoneName}: ${zoneShare}%`)
        }
      }
      return result
    },

    electricityGrid() {
      if (this.building.current_state.electricity_grid_id) {
        return `${this.getCarrierName(this.building.current_state.electricity_grid_id)}`
      } else {
        return null
      }
    },

    electricityPv() {
      if (this.building.current_state.electricity_pv_id) {
        return `${this.getCarrierName(this.building.current_state.electricity_pv_id)}`
      } else {
        return null
      }
    },

    photovoltaicSummary() {
      const photovoltaicSummaryElement = this.building.initial_state.pv.sections.reduce((c, pv) => c + pv.capacity, 0)

      return photovoltaicSummaryElement ? `${photovoltaicSummaryElement} kWp` : null
    },
  },

  methods: {
    getCarrierName(id) {
      const carrier = this.portfolio.energy_carriers.find((carrier) => carrier.id === id)
      return carrier && carrier.default ? this.$t(`_energyCarriers.${carrier.name}`) : carrier.name
    },

    getRawNameForId(heatingTypeId) {
      const heatingType = this.portfolio.heating_types.find((ht) => ht.id === heatingTypeId)
      if (heatingType) {
        return heatingType.name
      } else {
        return 'UNKNOWN'
      }
    },

    // Must output final string: a translated name if it has a default translation, or the raw name otherwise
    getNameForId(heatingTypeId) {
      const heatingType = this.portfolio.heating_types.find((ht) => ht.id === heatingTypeId)
      if (heatingType) {
        if (heatingType.default) {
          return this.$t(`_heatingTypes.${heatingType.name}`)
        } else {
          return heatingType.name
        }
      } else {
        return this.$t('_heatingTypes.UNKNOWN')
      }
    },

    getGridLabel(gridIdentifier) {
      const grid = this.portfolio.heat_grids.find((g) => g.identifier === gridIdentifier)
      return grid && grid.name
    },
  },
}
</script>

<style lang="scss" scoped>
.c-building-summary-rows {
  & > * {
    border-top: var(--box-border);
  }

  & .details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--spacing-xxs);
    width: 100%;
  }

  & .pill {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    padding: 0 var(--spacing-s);
    border: var(--box-border);
    border-radius: 13px;
    height: 24px;
    font-size: 14px;

    & > span {
      display: inline-block;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  & .default {
    opacity: 0.5;
  }
}
</style>
