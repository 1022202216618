<i18n>
{
  "de": {
    "activeText": "Aktiv",
    "inactiveText": "Inaktiv",
    "from": "seit"
  }
}
</i18n>

<template>
  <div class="c-active-status">
    {{ `${$t(isActive ? 'activeText' : 'inactiveText')} ${yearLabel}` }}
  </div>
</template>

<script>
export default {
  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    buildingYear() {
      return this.building.initial_state?.result?.envelope?.building_year?.value
    },

    isActive() {
      const activeFrom = this.building.active_from || this.buildingYear || 0
      const activeTo = this.building.active_to || 9999
      const currentYear = new Date().getFullYear()

      return activeFrom && activeFrom <= currentYear && activeTo >= currentYear
    },

    yearLabel() {
      const activeFrom = this.building.active_from
      const activeTo = this.building.active_to
      const buildingYear = this.buildingYear
      const isActive = this.isActive

      if (activeFrom && activeTo) {
        // If both are set, just show both no matter if active or not
        return `(${activeFrom} - ${activeTo})`
      } else if (!activeFrom && activeTo) {
        // If activeTo is set, don't show 'from', just show building year and activeTo no matter if active or not
        return `(${buildingYear} - ${activeTo})`
      } else if (isActive && activeFrom && !activeTo) {
        // If active and activeFrom is set, show 'from' and activeFrom
        return `(${this.$t('from')} ${activeFrom})`
      } else if (isActive && !activeFrom && buildingYear) {
        // If active and no activeFrom is set, show 'from' and use building year
        return `(${this.$t('from')} ${buildingYear})`
      }

      return ''
    },
  },
}
</script>
