<!--
this component was modified by ruggle@bs2.ch. the original component doesn't work correctly and the package seems to be unmaintained
-->

<template>
  <div style="display: none">
    <!-- @slot Slot for popup content -->
    <slot />
  </div>
</template>

<script>
const popupEvents = {
  open: 'open',
  close: 'close',
}

/**
 * Popup component.
 * @see See [Mapbox Gl JS Popup](https://www.mapbox.com/mapbox-gl-js/api/#popup)
 */
export default {
  name: 'Popup',

  inject: {
    mapbox: {
      default: null,
    },
    map: {
      default: null,
    },
  },

  props: {
    /**
     * If `true`, a close button will appear in the top right corner of the popup.
     * Mapbox GL popup option.
     */
    closeButton: {
      type: Boolean,
      default: true,
    },
    /**
     * Mapbox GL popup option.
     * If `true`, the popup will closed when the map is clicked. .
     */
    closeOnClick: {
      type: Boolean,
      default: true,
    },
    /**
     * Mapbox GL popup option.
     * A string indicating the popup's location relative to the coordinate set.
     * If unset the anchor will be dynamically set to ensure the popup falls within the map container with a preference for 'bottom' .
     *  'top', 'bottom', 'left', 'right', 'top-left', 'top-right', 'bottom-left', 'bottom-right'
     */
    anchor: {
      validator(value) {
        let allowedValues = ['top', 'bottom', 'left', 'right', 'top-left', 'top-right', 'bottom-left', 'bottom-right']
        return typeof value === 'string' && allowedValues.includes(value)
      },
      default: undefined,
    },
    /**
     * Mapbox GL popup option.
     * A pixel offset applied to the popup's location
     * a single number specifying a distance from the popup's location
     * a PointLike specifying a constant offset
     * an object of Points specifing an offset for each anchor position Negative offsets indicate left and up.
     */
    offset: {
      type: [Number, Object, Array],
      default: () => [0, 0],
    },
    coordinates: {
      type: Array,
    },

    /**
     * Component option.
     * If `true`, popup treats data in deafult slot as plain text
     */
    onlyText: {
      type: Boolean,
      default: false,
    },

    showed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      initial: true,
      popup: undefined,
    }
  },

  computed: {
    open: {
      get() {
        if (this.popup !== undefined) {
          return this.popup.isOpen()
        }
        return false
      },
      set(value) {
        if (this.map && this.popup) {
          if (!value) {
            this.popup.remove()
          } else {
            this.popup.addTo(this.map)
          }
        }
      },
    },
  },

  watch: {
    coordinates(lngLat) {
      if (this.initial) return
      this.popup.setLngLat(lngLat)
    },

    showed(next, prev) {
      if (next !== prev) {
        this.open = next
      }
    },
  },

  created() {
    this.popup = new this.mapbox.Popup(this.$props)
  },

  mounted() {
    this.$_addPopup()
    this.initial = false
  },

  beforeDestroy() {
    if (this.map) {
      this.popup.remove()
      this.$_emitEvent('removed')
    }
  },

  methods: {
    /**
     * Emit Vue event with additional data
     *
     * @param {string} name EventName
     * @param {Object} [data={}] Additional data
     */
    $_emitEvent(name, data = {}) {
      this.$emit(name, {
        map: this.map,
        component: this,
        ...data,
      })
    },

    /**
     * Emit Vue event with Mapbox event as additional data
     *
     * @param {Object} event
     */
    $_emitMapEvent(event, data = {}) {
      this.$_emitEvent(event.type, { mapboxEvent: event, ...data })
    },
    /*
    $_emitSelfEvent (event, data = {}) {
      this.$_emitMapEvent(event, { control: this.control, ...data })
    }, */
    /** Bind events for markers, popups and controls.
     * MapboxGL JS emits this events on popup or marker object,
     * so we treat them as 'self' events of these objects
     */
    $_bindSelfEvents(events, emitter) {
      Object.keys(this.$listeners).forEach((eventName) => {
        if (events.includes(eventName)) {
          emitter.on(eventName, this.$_emitSelfEvent)
        }
      })
    },

    $_unbindSelfEvents(events, emitter) {
      if (events.length === 0) return
      if (!emitter) return
      events.forEach((eventName) => {
        emitter.off(eventName, this.$_emitSelfEvent)
      })
    },

    $_addPopup() {
      this.popup = new this.mapbox.Popup(this.$props)
      this.popup.setMaxWidth('400px')
      if (this.coordinates !== undefined) {
        this.popup.setLngLat(this.coordinates)
      }
      if (this.$slots.default !== undefined) {
        if (this.onlyText) {
          if (this.$slots.default[0].elm.nodeType === 3) {
            let tmpEl = document.createElement('span')
            tmpEl.appendChild(this.$slots.default[0].elm)
            this.popup.setText(tmpEl.innerText)
          } else {
            this.popup.setText(this.$slots.default[0].elm.innerText)
          }
        } else {
          this.popup.setDOMContent(this.$slots.default[0].elm)
        }
      }

      this.$_bindSelfEvents(Object.keys(popupEvents), this.popup)

      this.$_emitEvent('added', { popup: this.popup })

      if (this.showed) {
        this.open = true
      }
    },

    $_emitSelfEvent(event) {
      this.$_emitMapEvent(event, { popup: this.popup })
    },

    remove() {
      this.popup.remove()
      this.$_emitEvent('remove', { popup: this.popup })
    },
  },
}
</script>
