<template>
  <span class="check-input">
    <span v-if="edit">
      <input
        :id="inputId"
        :checked="value"
        :disabled="disabled"
        type="checkbox"
        @change="$emit('input', $event.target.checked)"
      />
      <label :for="inputId">{{ label }}</label>
    </span>
    <span v-else-if="activeLabel !== undefined && value" class="read-only-value">{{ activeLabel }}</span>
    <span v-else-if="inactiveLabel !== undefined && !value" class="read-only-value">{{ inactiveLabel }}</span>
    <span v-else-if="value" class="read-only-value">{{ label }}</span>
  </span>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  props: {
    value: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    activeLabel: {
      type: String,
    },
    inactiveLabel: {
      type: String,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inputId: uuidv4(),
    }
  },
}
</script>

<style>
.check-input {
  display: inline-block;
  white-space: nowrap;
}
</style>
