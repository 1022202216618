<i18n>
  {
    "de": {
      "editMeterTitle": "Datenpunkt bearbeiten",
      "addMeterTitle": "Datenpunkt hinzufügen"
    }
  }
  </i18n>

<template>
  <Modal :width="960" class="c-edit-meter-modal" @close="$emit('close', $event)">
    <div v-if="error">
      <p>{{ error }}</p>
    </div>

    <template #header>
      <h2 v-if="!meter">{{ $t('addMeterTitle') }}</h2>
      <h2 v-else>{{ $t('editMeterTitle') }}</h2>
    </template>

    <EditMeter
      :portfolio="portfolio"
      :building="building"
      :meter="meter"
      @add="onAdd"
      @cancel="onCancel"
      @error="onError"
    />

    <div v-if="meter">
      <div class="separator" />
      <MeterValuesList :building="building" :meter="meter" />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import EditMeter from '@/components/building/meters/EditMeter.vue'
import MeterValuesList from '@/components/building/meters/MeterValuesList.vue'

export default {
  components: {
    Modal,
    EditMeter,
    MeterValuesList,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    meterId: {
      type: Number,
    },
  },

  data() {
    return {
      error: null,
    }
  },

  computed: {
    meter() {
      return this.building.meters.find((m) => m.id === this.meterId) || null
    },
  },

  methods: {
    //
    onCancel() {
      if (this.meter === null) {
        this.$emit('close')
      }
    },

    //
    onAdd(result) {
      this.$emit('add', result)
    },

    //
    onError(error) {
      this.error = error
    },
  },
}
</script>

<style lang="scss" scoped>
.c-edit-meter-modal {
  & .delete-button {
    margin-left: 0;
  }

  & .separator {
    height: 1px;
    margin: var(--spacing-l) 0;
    padding: 0;
    background-color: var(--separator-color);
  }
}
</style>
