<template>
  <li :class="classes">
    <div class="item-main" @click="onClick">
      <h4 v-if="title">
        <span>{{ title }}</span>
        <InfoBox v-if="infoText" :title="infoTitle ? infoTitle : label">{{ infoText }}</InfoBox>
      </h4>
      <div><slot /></div>
    </div>
    <div v-if="hasRightContent || expandable" class="item-right" @click="onClick">
      <slot name="rightContent" />
      <span class="expand-icon"><Icon icon="chevron-right" /></span>
    </div>
    <div class="modal"><slot name="modal" /></div>
  </li>
</template>

<script>
import Icon from '@/components/cui/data-display/Icon.vue'
import InfoBox from '@/components/shared/InfoBox.vue'

export default {
  components: {
    Icon,
    InfoBox,
  },

  props: {
    title: {
      type: String,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    infoTitle: {
      type: String,
    },
    infoText: {
      type: String,
    },
  },

  computed: {
    classes() {
      return {
        'detail-list-item': true,
        expandable: this.expandable,
        'non-expandable': !this.expandable,
      }
    },

    hasRightContent() {
      return !!this.$slots.rightContent
    },
  },

  methods: {
    onClick() {
      if (this.expandable) {
        this.$emit('expand')
      }
    },
  },
}
</script>

<style lang="scss">
.detail-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & .two-columns {
    display: grid;
    grid-template-columns: 140px 160px;
  }

  & .three-columns {
    display: grid;
    grid-template-columns: 140px 160px 1fr;
  }

  &.expandable {
    cursor: pointer;

    &:hover {
      background-color: #f8f8f8;
    }
  }

  &:hover {
    background-color: #f8f8f8;
  }

  &.bubble-summary-row:hover {
    background: none;
  }

  & .modal {
    cursor: auto;
  }

  & > .item-main {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-xs) var(--spacing-s);
    width: 100%;
    line-height: 24px;
  }

  & > .item-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: var(--box-padding);
    flex: none;
  }

  &.expandable .expand-icon {
    opacity: 0.6;
  }

  &.expandable:hover .expand-icon {
    opacity: 1;
  }

  & .expand-icon > img {
    vertical-align: top;
  }

  & > .item-main > h4 {
    font-weight: 600;
    color: var(--secondary-highlight-color);
    grid-column: 1 / -1;
    margin: 0;
  }
}
</style>

<style scoped lang="scss">
.detail-list-item {
  &.non-expandable .expand-icon {
    opacity: 0;
  }
}
</style>
