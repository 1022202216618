<!--
BuildingMixin:
Loads a specific building for pages with router argument "building_id"

Provides: building, buildingReady, buildingLoading
-->
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    // Properties set by router:
    building_id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      __buildingById: 'building/getById',
    }),

    buildingReady() {
      return this.building !== undefined && this.building.__loaded
    },

    buildingLoading() {
      return this.building !== undefined && this.building.__loading
    },

    building() {
      this.__setActiveBuildingId(this.building_id)
      return this.__buildingById(this.building_id)
    },
  },

  watch: {
    building_id() {
      if (this.building_id) {
        this.__setActiveBuildingId(this.building_id)
        this.__loadBuildingDetailsById(this.building_id)
      }
    },
  },

  created() {
    if (this.building_id) {
      this.__setActiveBuildingId(this.building_id)
      this.__loadBuildingDetailsById(this.building_id)
    }
  },

  methods: {
    ...mapActions({
      __setActiveBuildingId: 'building/setActiveBuildingId',
      __loadBuildingDetailsById: 'building/loadDetailsById',
    }),
  },
}
</script>
