<script>
import BaseLayer from '@/components/building/map/BaseLayer.vue'

export default {
  components: {
    BaseLayer,
  },

  extends: BaseLayer,

  computed: {
    sourceConfig() {
      let features = []
      if (this.building.gwr.state === 'OK') {
        for (let i = 0; i < this.building.gwr.entries.length; i++) {
          let entry = this.building.gwr.entries[i]
          features.push({
            id: i,
            type: 'Feature',
            geometry: entry['geometry'],
            properties: {
              ...entry['properties'],
              description: entry['properties']['label'],
            },
          })
        }
      }
      return {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: features,
        },
      }
    },

    layerConfig() {
      return {
        type: 'circle',
        source: this.sourceId,
        paint: {
          'circle-color': '#ffffff',
          'circle-radius': ['case', ['boolean', ['feature-state', 'hover'], false], 3, 2],
          'circle-stroke-color': '#000000',
          'circle-stroke-width': 2,
        },
        minzoom: 14,
      }
    },
  },
}
</script>
