<template>
  <Box class="c-view-zones">
    <EditZonesTable v-model="model.zones" :zones="building.current_state.zones.zones" :edit="false" />
  </Box>
</template>

<script>
import Box from '@/components/cui/layout/Box.vue'
import EditZonesTable from '@/components/building/edit/EditZonesTable.vue'

export default {
  components: {
    EditZonesTable,
    Box,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  created() {
    this.resetModel()
  },

  methods: {
    resetModel() {
      this.model = {
        zones: [],
      }
      for (let i = 0; i < this.building.current_state.zones.zones.length; i++) {
        const zone = this.building.current_state.zones.zones[i]
        this.model.zones.push({ ...zone })
      }
    },
  },
}
</script>
