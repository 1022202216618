<i18n>
{
  "de": {
    "buttonLabel": "Speichern"
  }
}
</i18n>

<template>
  <ListButton :img-src="'/icons/save.svg'" :label="$t('buttonLabel')" :disabled="disabled" @click="onClick()" />
</template>

<script>
import ListButton from '@/components/shared/lists/ListButton.vue'

export default {
  components: {
    ListButton,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>
