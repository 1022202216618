<template>
  <v-select
    v-if="edit"
    v-model="model"
    clearable
    :options="options"
    :get-option-label="(option) => $t(`_pvClusteringList.${option}`)"
    @input="onInput"
  />
  <span v-else
    ><span v-if="selectedLabel">{{ selectedLabel }}</span></span
  >
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    options: {
      type: Array,
    },
    edit: {
      type: Boolean,
    },
  },

  data() {
    return {
      model: null,
    }
  },

  computed: {
    selectedLabel() {
      const selectedPvClusteringList = this.options.find((o) => o === this.model)
      return selectedPvClusteringList
        ? this.$t(`_pvClusteringList.${selectedPvClusteringList}`)
        : this.$t('_pvClusteringList.NONE')
    },
  },

  watch: {
    value() {
      this.model = this.value
    },
  },

  created() {
    this.model = this.value
  },

  methods: {
    onInput() {
      this.$emit('input', this.model)
    },
  },
}
</script>
