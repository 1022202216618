<i18n>
{
  "de": {
    "noMeasure": "Keine Renovation",
    "componentNameTitle": "Bauteil",
    "insulationStandardTitle": "Wärmedämmung",
    "basementNotExists": "Gebäude ist nicht unterkellert",
    "costsTitle": "Kosten *",
    "autoCostsHint": "Kosten leer lassen für automatische Kostenschätzung",
    "missingRenovationStandardTooltip": "Der Renovationsstandard wurde zwischenzeitlich entfernt."
  }
}
</i18n>

<template>
  <div class="c-edit-envelope-measure">
    <DetailList has-header class="components-table">
      <template #header>
        <span>{{ $t('componentNameTitle') }}</span>
        <span>{{ $t('insulationStandardTitle') }}</span>
        <span>{{ $t('costsTitle') }}</span>
      </template>
      <!--
        Windows
      !-->
      <li>
        <div>{{ $t('_envelope.windows') }}</div>
        <div v-if="edit">
          <v-select
            id="windows-options"
            v-model="selectedStandards.windows"
            :options="renovationStandardOptions"
            :clearable="false"
            :reduce="(t) => t.id"
            @input="onStandardChange"
          />
        </div>
        <div v-else-if="model.windows">
          {{ model.windows.standard }}
          <EstimateInput
            v-if="renovationStandardOptions.filter((o) => o.id === model.windows.standard).length === 0"
            v-model="trueModel"
            :tooltip="$t('missingRenovationStandardTooltip')"
          />
        </div>
        <div v-else>-</div>
        <div v-if="model.windows">
          <NumericInput
            v-model="model.windows.costs"
            :edit="isEditing"
            :min="0"
            optional
            :units="'CHF'"
            @input="onInput"
            @validation="onValidation($event, 'window-costs')"
          />
        </div>
        <div v-else>-</div>
      </li>

      <!--
        Walls
      -->
      <li>
        <div>{{ $t('_envelope.walls') }}</div>
        <div v-if="edit">
          <v-select
            id="walls-options"
            v-model="selectedStandards.walls"
            :options="renovationStandardOptions"
            :clearable="false"
            :reduce="(t) => t.id"
            @input="onStandardChange"
          />
        </div>
        <div v-else-if="model.walls">
          {{ model.walls.standard }}
          <EstimateInput
            v-if="renovationStandardOptions.filter((o) => o.id === model.walls.standard).length === 0"
            v-model="trueModel"
            :tooltip="$t('missingRenovationStandardTooltip')"
          />
        </div>
        <div v-else>-</div>
        <div v-if="model.walls">
          <NumericInput
            v-model="model.walls.costs"
            :edit="isEditing"
            :min="0"
            optional
            :units="'CHF'"
            @input="onInput"
            @validation="onValidation($event, 'walls-costs')"
          />
        </div>
        <div v-else>-</div>
      </li>

      <!--
        Roof
      -->
      <li>
        <div>{{ $t('_envelope.roof') }}</div>
        <div v-if="edit">
          <v-select
            id="roof-options"
            v-model="selectedStandards.roof"
            :options="renovationStandardOptions"
            :clearable="false"
            :reduce="(t) => t.id"
            @input="onStandardChange"
          />
        </div>
        <div v-else-if="model.roof">
          {{ model.roof.standard }}
          <EstimateInput
            v-if="renovationStandardOptions.filter((o) => o.id === model.roof.standard).length === 0"
            v-model="trueModel"
            :tooltip="$t('missingRenovationStandardTooltip')"
          />
        </div>
        <div v-else>-</div>
        <div v-if="model.roof">
          <NumericInput
            v-model="model.roof.costs"
            :edit="isEditing"
            :min="0"
            optional
            :units="'CHF'"
            @input="onInput"
            @validation="onValidation($event, 'roof-costs')"
          />
        </div>
        <div v-else>-</div>
      </li>

      <!--
        Basement
      -->
      <li v-if="model.basement != building.initial_state.envelope.basement_exists">
        <div>{{ $t('_envelope.basement') }}</div>
        <div v-if="edit">
          <v-select
            id="basement-options"
            v-model="selectedStandards.basement"
            :options="renovationStandardOptions"
            :clearable="false"
            :reduce="(t) => t.id"
            @input="onStandardChange"
          />
        </div>
        <div v-else-if="model.basement">
          {{ model.basement.standard }}
          <EstimateInput
            v-if="renovationStandardOptions.filter((o) => o.id === model.basement.standard).length === 0"
            v-model="trueModel"
            :tooltip="$t('missingRenovationStandardTooltip')"
          />
        </div>
        <div v-else>-</div>
        <div v-if="model.basement">
          <NumericInput
            v-model="model.basement.costs"
            :edit="isEditing"
            :min="0"
            optional
            :units="'CHF'"
            @input="onInput"
            @validation="onValidation($event, 'basement-costs')"
          />
        </div>
        <div v-else>-</div>
      </li>
      <li v-else class="no-basement">
        <div>{{ $t('_envelope.basement') }}</div>
        <div>{{ $t('basementNotExists') }}</div>
        <div></div>
      </li>
    </DetailList>
    <div class="hint">* {{ $t('autoCostsHint') }}</div>
  </div>
</template>
<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin.vue'

import DetailList from '@/components/shared/lists/DetailList.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import EstimateInput from '@/components/shared/forms/EstimateInput.vue'

export default {
  mixins: [
    //
    ComplexFormMixin,
  ],

  components: {
    DetailList,
    NumericInput,
    EstimateInput,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedStandards: {
        windows: null,
        walls: null,
        basement: null,
        roof: null,
      },
      trueModel: true,
    }
  },

  computed: {
    renovationStandardOptions() {
      const options = [{ id: 'NONE', label: this.$t('noMeasure') }]
      this.building.renovation_standards.forEach((rs) =>
        options.push({
          id: rs.name,
          label: rs.name, // TOOD: rs.id for id
        })
      )
      this.portfolio.renovation_standards
        .filter((rs) => !rs.building_id && options.filter((o) => o.id === rs.name).length === 0)
        .forEach((rs) =>
          options.push({
            id: rs.name,
            label: rs.name, // TOOD: rs.id for id
          })
        )
      return options
    },
  },

  created() {
    this.resetStandards()
  },

  methods: {
    onStandardChange() {
      for (const c of ['windows', 'walls', 'basement', 'roof']) {
        if (this.selectedStandards[c] === 'NONE') {
          this.model[c] = null
        } else if (this.model[c] === null) {
          this.model[c] = {
            standard: this.selectedStandards[c],
            costs: null,
          }
        } else {
          this.model[c].standard = this.selectedStandards[c]
        }
      }
      this.onInput()
    },
    resetStandards() {
      for (const c of ['windows', 'walls', 'basement', 'roof']) {
        if (this.model[c] === null) {
          this.selectedStandards[c] = 'NONE'
        } else {
          this.selectedStandards[c] = this.model[c].standard
        }
      }
      this.onInput()
    },
  },
}
</script>

<style lang="scss">
.c-edit-envelope-measure {
  & .detail-list > ul > li {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>

<style lang="scss" scoped>
.no-basement {
  height: 50px;
  color: #888;
}
</style>
