<i18n>
{
  "de": {
    "energyGridsTitle": "Wärmenetze und Wärmequellen",
    "reloadLabel": "Daten aktualisieren",
    "pendingMessage": "Daten werden abgefragt...",
    "lastUpdateLabel": "Aktualisiert am",
    "ownResearchTitle": "Eigene Recherche",
    "bfeApiTitle": "BFE API",
    "nameTitle": "Name",
    "detailsUrlLink": "Details",
    "heatSourceTitle": "Wärmequelle",
    "operatorTitle": "Betreiber",
    "ghgFactorTitle": "Scope 1-2 EF",
    "peFactorTitle": "Primärenergie (PEF)",
    "statusTitle": "Status",
    "distanceTitle": "Distanz",
    "sourcesTitle": "Quellen",
    "noDataAvailable": "An diesem Standort sind keine Daten zu Wärmenetzen oder Wärmequellen verfügbar",
    "noElements": "Kein Eintrag"
  }
}
</i18n>

<template>
  <Modal wide class="c-energy-grids-modal" @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('energyGridsTitle') }}</h2>
    </template>
    <div v-if="error">
      <p>{{ error }}</p>
    </div>
    <div v-if="pending" class="loading">
      <ClipLoader size="16px" color="#000" />
      <div>{{ $t('pendingMessage') }}</div>
    </div>
    <div v-else-if="building.energy_grids.state !== 'OK'" class="missing-data">
      {{ $t(`_energyGridsState.${building.energy_grids.state}`) }}
    </div>
    <div
      v-else-if="
        building.energy_grids.energy_grids.length === 0 && building.energy_grids.energy_grids_simplified === 'UNKNOWN'
      "
    >
      {{ $t('noDataAvailable') }}
    </div>
    <div v-else>
      <h3>{{ $t('ownResearchTitle') }}:</h3>
      <DetailList has-header class="energy-grids-list">
        <template #header>
          <span>{{ $t('nameTitle') }}</span>
          <span>{{ $t('operatorTitle') }}</span>
          <span>{{ $t('heatSourceTitle') }}</span>
          <span>{{ $t('ghgFactorTitle') }}</span>
          <span>{{ $t('peFactorTitle') }}</span>
          <span>{{ $t('statusTitle') }}</span>
          <span>{{ $t('distanceTitle') }}</span>
          <span></span>
        </template>
        <template v-if="ownResearchList.length > 0">
          <li v-for="(grid, index) in ownResearchList" :key="index">
            <span>{{ grid.name }}</span>
            <span>{{ grid.operator }}</span>
            <span>{{ grid.heatsource }}</span>
            <span v-if="grid.ghg_factor !== null">{{ formatNumber(grid.ghg_factor, 1) }} g/kWh</span>
            <span v-else>-</span>
            <span v-if="grid.pe_factor !== null">{{ formatNumber(grid.pe_factor, 2) }}</span>
            <span v-else>-</span>
            <TextWithIcon :icon="getIcon(grid.status, grid.distance)">
              <span v-if="grid.distance > 0">{{ $t(`_gridAvailability.${grid.status}_NEAR`) }}</span>
              <span v-else>{{ $t(`_gridAvailability.${grid.status}`) }}</span>
            </TextWithIcon>
            <span>{{ Math.ceil(grid.distance / 10) * 10 }} m</span>
            <a v-if="grid.detailsurl" target="_blank" :href="grid.detailsurl">{{ $t('detailsUrlLink') }}</a>
          </li>
        </template>
        <template v-else>
          <li>
            <span>{{ $t('noElements') }}</span>
          </li>
        </template>
      </DetailList>
      <h3>{{ $t('bfeApiTitle') }}:</h3>
      <DetailList has-header class="energy-grids-list">
        <template #header>
          <span>{{ $t('nameTitle') }}</span>
          <span>{{ $t('operatorTitle') }}</span>
          <span>{{ $t('heatSourceTitle') }}</span>
          <span>{{ $t('ghgFactorTitle') }}</span>
          <span>{{ $t('peFactorTitle') }}</span>
          <span>{{ $t('statusTitle') }}</span>
          <span>{{ $t('distanceTitle') }}</span>
          <span></span>
        </template>
        <template v-if="bfeApiList.length > 0">
          <li v-for="(grid, index) in bfeApiList" :key="index">
            <span>{{ grid.name }}</span>
            <span>{{ grid.operator }}</span>
            <span>{{ grid.heatsource }}</span>
            <span v-if="grid.ghg_factor !== null">{{ formatNumber(grid.ghg_factor, 1) }} g/kWh</span>
            <span v-else>-</span>
            <span v-if="grid.pe_factor !== null">{{ formatNumber(grid.pe_factor, 2) }}</span>
            <span v-else>-</span>
            <TextWithIcon :icon="getIcon(grid.status, grid.distance)">
              <span v-if="grid.distance > 0">{{ $t(`_gridAvailability.${grid.status}_NEAR`) }}</span>
              <span v-else>{{ $t(`_gridAvailability.${grid.status}`) }}</span>
            </TextWithIcon>
            <span>{{ Math.ceil(grid.distance / 10) * 10 }} m</span>
            <a v-if="grid.detailsurl" target="_blank" :href="grid.detailsurl">{{ $t('detailsUrlLink') }}</a>
          </li>
        </template>
        <template v-else>
          <li>
            <span>{{ $t('noElements') }}</span>
          </li>
        </template>
      </DetailList>
    </div>
    <div v-if="dataSources.length > 0" class="sources-list">
      {{ $t('sourcesTitle') }}:
      <div v-for="(source, index) in dataSources" :key="index">
        {{ source }}
      </div>
    </div>

    <ButtonWrapper v-if="!pending">
      <p v-if="building.energy_grids.state === 'OK'">{{ $t('lastUpdateLabel') }}: {{ lastUpdateDate }}</p>
      <button v-if="getBuildingPermission('REFRESH_ENERGY_GRIDS')" class="button" @click="onReload">
        {{ $t('reloadLabel') }}
      </button>
    </ButtonWrapper>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Modal from '@/components/shared/Modal.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import TextWithIcon from '@/components/shared/TextWithIcon.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    ButtonWrapper,
    DetailList,
    TextWithIcon,
    Modal,
    ClipLoader,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pending: null,
      error: null,
    }
  },

  computed: {
    ...mapGetters({
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),

    lastUpdateDate() {
      return new Date(this.building.energy_grids.last_update).toLocaleDateString('de-DE')
    },

    ownResearchList() {
      return this.building.energy_grids.energy_grids.filter((eg) => eg.raw_source === 'shp_file')
    },

    bfeApiList() {
      return this.building.energy_grids.energy_grids.filter((eg) => eg.raw_source === 'bfe_api')
    },

    dataSources() {
      if (this.building.energy_grids.energy_grids !== null) {
        return new Set(this.building.energy_grids.energy_grids.map((g) => g.data_source))
      }
      return new Set()
    },
  },

  methods: {
    ...mapActions({
      queryEnergyGrids: 'building/queryEnergyGrids',
    }),

    getIcon(status, distance) {
      let icons = {
        HEAT_SOURCE_NEAR: 'info-circle',
        HEAT_POTENTIAL_NEAR: 'info-circle',
        GAS_RETREAT: 'x-circle',
        EXISTING: 'check-circle',
        EXISTING_NEAR: 'info-circle',
        PLANNED: 'alert-triangle',
        PLANNED_NEAR: 'alert-triangle',
        EVALUATION: 'alert-triangle',
        EVALUATION_NEAR: 'alert-triangle',
        NONE: 'x-circle',
        ERROR: 'info-circle',
        UNKNOWN: 'help-circle',
      }
      return icons[status + (distance > 0 ? '_NEAR' : '')]
    },

    async onReload() {
      this.pending = true
      try {
        this.error = null
        await this.queryEnergyGrids({
          id: this.building.id,
        })
      } catch (error) {
        this.error = error
      }
      this.pending = false
    },
  },
}
</script>

<style lang="scss" scoped>
.c-energy-grids-modal {
  & .missing-data {
    color: var(--warning-color);
  }

  & .sources-list {
    margin-top: var(--spacing-m);
    & > div {
      margin-left: var(--spacing-s);
    }
  }

  & .loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-s);
  }
}
</style>

<style lang="scss">
.c-energy-grids-modal {
  & .detail-list > ul > li {
    grid-template-columns: 2fr 2fr 3fr 1fr 1fr 2fr 1fr 1fr;
  }
}
</style>
