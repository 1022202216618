<i18n>
{
  "de": {
    "descriptionLabel": "Bezeichnung",
    "identifierLabel": "Eindeutige Liegenschaftsnr.",
    "remarksLabel": "Bemerkungen"
  }
}
</i18n>

<template>
  <EditContainer
    :bind-form-state="formState"
    :allow-edit="getBuildingPermission('EDIT_DESCRIPTION')"
    class="c-edit-base-data"
    @container="onFormEvent"
  >
    <FormRow :label="$t('identifierLabel')">
      <TextInput v-model="model.identifier" :edit="isEditing" @validation="onValidation($event, 'identifier')" />
    </FormRow>
    <FormRow :label="$t('descriptionLabel')">
      <TextInput
        v-model="model.description"
        :edit="isEditing"
        :allow-empty="true"
        @validation="onValidation($event, 'description')"
      />
    </FormRow>
    <FormRow :label="$t('remarksLabel')">
      <TextArea v-if="isEditing" v-model="model.remarks" />
      <div v-else class="remarks-text">{{ model.remarks }}</div>
    </FormRow>
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import EditContainer from '@/components/shared/forms/EditContainer.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import TextArea from '@/components/shared/forms/TextArea.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    EditContainer,
    TextInput,
    FormRow,
    TextArea,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  watch: {
    building() {
      this.resetModel()
    },
  },

  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding',
    }),

    resetModel() {
      this.model = {
        description: this.building.description || '',
        identifier: this.building.identifier || '',
        remarks: this.building.remarks || '',
      }
    },

    async saveHandler(model) {
      await this.updateBuilding({
        id: this.building.id,
        description: model.description.trim(),
        identifier: model.identifier,
        remarks: model.remarks,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-edit-base-data {
  .remarks-text {
    white-space: pre-wrap;
  }
}
</style>
