<template>
  <div class="energy-label">
    <div class="energy-label-arrow" :style="`border-right-color: ${color}`"></div>
    <span class="energy-label-block" :style="`background-color: ${color}`"
      ><span>{{ label }}</span></span
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    labels: {
      type: Array,
      default() {
        return ['+', 'A', 'B', 'C', 'D', 'E', 'F', 'G']
      },
    },
    limits: {
      type: Array,
      default() {
        return [0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0, Infinity]
      },
    },
    colors: {
      type: Array,
      default() {
        return ['#00ac60', '#00ac60', '#4fb859', '#c3de43', '#e8e800', '#f8ce20', '#f09d0f', '#e22e31']
      },
    },
  },

  computed: {
    classIndex() {
      return this.limits.findIndex((limit) => this.value < limit)
    },

    label() {
      return this.labels[this.classIndex]
    },

    color() {
      return this.colors[this.classIndex]
    },
  },
}
</script>

<style lang="scss">
.energy-label {
  display: flex;
  flex-direction: row;

  & .energy-label-arrow {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 12px solid white;
    border-bottom: 10px solid transparent;
  }

  & .energy-label-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1rem;
    height: 20px;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow: hidden;
    font-size: 13px;
    font-weight: 700;

    & > span {
      color: #fff;
    }
  }
}
</style>
