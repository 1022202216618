<i18n>
{
  "de": {
    "pendingMessage": "Daten werden gespeichert, bitte warten...",
    "editButtonLabel": "Bearbeiten",
    "cancelButtonLabel": "Abbrechen",
    "saveButtonLabel": "Speichern"
  }
}
</i18n>
<template>
  <div class="c-edit-container">
    <!-- Loading -->
    <div v-if="bindFormState.pending" class="loading">
      <ClipLoader size="16px" color="#000" />
      <div>{{ $t('pendingMessage') }}</div>
    </div>

    <!-- Content -->
    <div v-if="!bindFormState.pending">
      <slot></slot>
      <ButtonWrapper v-if="allowEdit">
        <button
          v-if="bindFormState.edit"
          class="button"
          :disabled="!bindFormState.isValid"
          @click="$emit('container', 'save')"
        >
          {{ $t('saveButtonLabel') }}
        </button>
        <button v-if="bindFormState.edit" class="button" @click="$emit('container', 'cancel')">
          {{ $t('cancelButtonLabel') }}
        </button>
        <button v-else class="button" @click="$emit('container', 'edit')">
          {{ $t('editButtonLabel') }}
        </button>
      </ButtonWrapper>
    </div>

    <!-- Error -->
    <div v-if="bindFormState.error" class="error-message">
      {{ bindFormState.error?.message || bindFormState.error }}
    </div>
  </div>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    ButtonWrapper,
    ClipLoader,
  },

  props: {
    bindFormState: {
      type: Object,
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.c-edit-container {
  & .loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-xs);
  }

  & .error-message {
    color: var(--error-color);
  }
}
</style>
