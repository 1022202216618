<i18n>
  {
    "de": {
      "enabled": "Ja",
      "disabled": "Nein",
      "zevLabel": "ZEV",
      "zevInfoBoxTitle": "Zusammenschluss zum Eigenverbrauch (ZEV)",
      "zevInfoBox": "Wenn der Zusammenschluss zum Eigenverbrauch ausgewählt ist, wird der PV-Strom auch für den Mieterstrom verwendet. Wenn nicht, wird PV-Strom lediglich für den Allgemeinstrom, Heizung und Warmwasser verwendet."
    }
  }
</i18n>

<template>
  <Box class="c-view-electricity">
    <ElectricityGridPicker
      class="energy-grid-picker"
      :edit="false"
      :clearable="true"
      :carrier-options="carrierOptions"
      :gridSelectionId="gridSelectionId"
      :pvSelectionId="pvSelectionId"
    />
    <FormRow class="zev-row" :label="$t('zevLabel')" :info-title="$t('zevInfoBoxTitle')" :info-text="$t('zevInfoBox')">
      <CheckInput
        v-model="model.zev"
        :edit="false"
        :label="$t('enabled')"
        :active-label="$t('enabled')"
        :inactive-label="$t('disabled')"
      />
    </FormRow>
  </Box>
</template>

<script>
import Box from '@/components/cui/layout/Box.vue'
import ElectricityGridPicker from '@/components/shared/ElectricityGridPicker.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import CheckInput from '@/components/shared/forms/CheckInput.vue'

export default {
  components: {
    Box,
    ElectricityGridPicker,
    FormRow,
    CheckInput,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    carrierOptions() {
      return this.portfolio.energy_carriers
        .filter((ec) => ec.electricity_grid)
        .map((ec) => {
          return {
            id: ec.id,
            label: ec.default ? this.$t(`_energyCarriers.${ec.name}`) : ec.name,
            carrier: ec,
          }
        })
    },

    gridSelectionId() {
      if (this.model) {
        return this.model['electricity_grid_id']
      } else {
        return this.gridOptions[0].id
      }
    },

    pvSelectionId() {
      if (this.model) {
        return this.model['electricity_pv_id']
      } else {
        return this.gridOptions[0].id
      }
    },
  },

  created() {
    this.resetModel()
  },

  methods: {
    resetModel() {
      this.model = {
        electricity_grid_id: this.building.current_state.electricity_grid_id,
        electricity_pv_id: this.building.current_state.electricity_pv_id,
        zev: this.building.current_state.zev,
      }
    },
  },
}
</script>

<style lang="scss">
.c-view-electricity {
  & .limit-width {
    max-width: 150px;
    display: inline-block;
  }

  & .zev-row {
    margin-top: var(--spacing-m);
  }
}
</style>
