<i18n>
  {
    "de": {
      "title": "Energie",
      "endEnergyDemand": "Endenergiebedarf",
      "total": "Absolut",
      "intensity": "Spezifisch",
      "indicator": {
        "h_ee": "Wärmebedarf",
        "rh_ee": "Raumwärme",
        "dhw_ee": "Warmwasser",
        "el_demand": "Strombedarf",
        "el_general": "Allgemeinstrom",
        "el_tenants": "Mieterstrom",
        "h_demand": "Nutzenergie Wärme",
        "rh_demand": "Raumwärme",
        "dhw_demand": "Warmwasser"
      },
      "rh_limit": "* Grenzwert SIA 380/1: {value} kWh/m²",
      "infoBoxText": "Die elektrische Betriebsenergie für Wärmepumpen wird im Wärmebedarf aufgeführt."
    }
  }
  </i18n>

<template>
  <KpiOverviewCard
    class="c-energy-kpi-overview"
    :title="$t('title')"
    titleIcon="cards/energy"
    section="energy"
    @details-click="onModalOpen()"
  >
    <template #values>
      <KpiBox
        :label="$t('endEnergyDemand')"
        :value="formatNumberWithThreshold(energyData[0]?.total, 2, 1, 1)"
        valueUnit="MWh"
        :relativeValue="formatNumberWithThreshold(energyData[0]?.intensity, 2, 1, 1)"
        relativeValueUnit="kWh/m²"
      />
    </template>

    <template #chart>
      <KpiDoughnutChart
        v-if="energyData[0].total > 0"
        kpi-type="ee"
        :group-by-type="'carrier'"
        :portfolio="portfolio"
        :chart-data="chartData"
        :chart-options="chartOptions"
      />
    </template>

    <template #table>
      <!-- End energy table -->
      <DetailList has-header>
        <!-- Header -->
        <template #header class="row">
          <div class="name" />
          <HeaderEntry :title="$t('total')" unit="MWh" align-right />
          <HeaderEntry :title="$t('intensity')" unit="kWh/m²" align-right />
        </template>

        <template>
          <li
            v-for="item in endEnergyDemandTableData"
            :key="item.indicator"
            class="row"
            :class="{ bold: item.indicator === 'h_ee' }"
          >
            <div class="name">
              <span v-if="['rh_ee', 'dhw_ee'].includes(item.indicator)" class="tab" />
              <span class="indicator">
                {{ $t(`indicator.${item.indicator}`) }}
              </span>
              <InfoBox v-if="item.indicator === 'h_ee'">{{ $t('infoBoxText') }}</InfoBox>
            </div>
            <div class="align-right">
              {{ formatNumberWithThreshold(item.total, 2, 1, 1) }}
            </div>
            <div class="align-right">
              {{ formatNumberWithThreshold(item.intensity, 2, 1, 1) }}
            </div>
          </li>
        </template>
      </DetailList>

      <!-- Electricity table -->
      <DetailList>
        <template>
          <li
            v-for="item in electricityDemandTableData"
            :key="item.indicator"
            class="row"
            :class="{ bold: ['el_demand'].includes(item.indicator) }"
          >
            <div class="name">
              <span v-if="['el_general', 'el_tenants'].includes(item.indicator)" class="tab" />
              <span class="indicator">
                {{ $t(`indicator.${item.indicator}`) }}
              </span>
            </div>
            <div class="align-right">
              {{ formatNumberWithThreshold(item.total, 2, 1, 1) }}
            </div>
            <div class="align-right">
              {{ formatNumberWithThreshold(item.intensity, 2, 1, 1) }}
            </div>
          </li>
        </template>
      </DetailList>

      <Divider class="divider" />

      <!-- Energy demand table -->
      <DetailList>
        <template>
          <li
            v-for="item in energyDemandTableData"
            :key="item.indicator"
            class="row"
            :class="{ bold: ['h_demand'].includes(item.indicator) }"
          >
            <div class="name">
              <span v-if="['rh_demand', 'dhw_demand'].includes(item.indicator)" class="tab" />
              <span class="indicator">
                {{ $t(`indicator.${item.indicator}`) }}
              </span>
              <span v-if="item.indicator === 'rh_demand' && building?.kpi?.rh_limit">*</span>
            </div>
            <div class="align-right">
              {{ formatNumberWithThreshold(item.total, 2, 1, 1) }}
            </div>
            <div class="align-right">
              {{ formatNumberWithThreshold(item.intensity, 2, 1, 1) }}
            </div>
          </li>
        </template>
      </DetailList>

      <div v-if="building?.kpi?.rh_limit" class="gray">{{ $t('rh_limit', { value: building?.kpi?.rh_limit }) }}</div>
    </template>

    <template #modal>
      <EnergyKpiModal
        v-if="modalOpen"
        :building="building"
        :portfolio="portfolio"
        :chart-data="chartData"
        :kpi-data="energyData"
        @close="onModalClose()"
      />
    </template>
  </KpiOverviewCard>
</template>

<script>
import _ from 'lodash'

import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import KpiOverviewCard from '@/components/shared/kpi/KpiOverviewCard.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'
import KpiBox from '@/components/shared/kpi/KpiBox.vue'
import KpiDoughnutChart from '@/components/portfolio/kpi/charts/KpiDoughnutChart.vue'
import EnergyKpiModal from '@/components/building/kpi/EnergyKpiModal.vue'
import Divider from '@/components/cui/data-display/Divider.vue'
import InfoBox from '@/components/shared/InfoBox.vue'

export default {
  mixins: [
    //
    ChartOptionsMixin,
  ],

  components: {
    InfoBox,
    KpiBox,
    KpiDoughnutChart,
    KpiOverviewCard,
    DetailList,
    HeaderEntry,
    EnergyKpiModal,
    Divider,
  },

  props: {
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        // cutout: '60%',
        layout: {
          padding: {
            left: 15,
            right: 15,
            top: 15,
            bottom: 15,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            display: false,
            labels: {
              font: {
                size: 12,
                weight: '500',
                lineHeight: 1,
              },
              boxWidth: 20,
              padding: 5,
            },
          },
          tooltip: {
            padding: 6,
            cornerRadius: 3,
            bodyFont: {
              size: 14,
            },
            callbacks: {
              label: (item) => this.getSlicedTooltipLabel(item, { precision: 1, unit: 'MWh', label: item.label }),
            },
          },
        },
      }
    },

    energyData() {
      return this.getValues(this.chartData[0], 'kpis', ['ee', 'pe', 'penr', 'per'])
    },

    electricityDemandTableData() {
      // Calculate el_demand
      const baseDataElectricity = this.getValues(this.chartData[0], null, ['el_general', 'el_tenants'])
      const elDemand = this.calculateCompositeValue('el_demand', baseDataElectricity, ['el_general', 'el_tenants'])

      return [elDemand, ...baseDataElectricity]
    },

    energyDemandTableData() {
      // Calculate h_demand
      const baseDataHeating = this.getValues(this.chartData[0], null, ['rh_demand', 'dhw_demand'])
      const hDemand = this.calculateCompositeValue('h_demand', baseDataHeating, ['rh_demand', 'dhw_demand'])

      return [hDemand, ...baseDataHeating]
    },

    endEnergyDemandTableData() {
      const eeByUsage = this.chartData[0]?.kpis?.ee_by_usage || {}
      const energyArea = this.building?.kpi?.energy_area || 1 // Prevent division by zero

      const rhEe = eeByUsage.ROOM_HEAT || 0
      const dhwEe = eeByUsage.HOT_WATER || 0
      const hEe = rhEe + dhwEe

      return [
        {
          indicator: 'h_ee',
          total: hEe / 1000, // Convert to MWh
          intensity: hEe / energyArea || 0,
        },
        {
          indicator: 'rh_ee',
          total: rhEe / 1000, // Convert to MWh
          intensity: rhEe / energyArea || 0,
        },
        {
          indicator: 'dhw_ee',
          total: dhwEe / 1000, // Convert to MWh
          intensity: dhwEe / energyArea || 0,
        },
      ]
    },
  },

  methods: {
    //
    getValues(sourceObject, path, indicators) {
      return _.map(indicators, (indicator) => {
        const value = path ? _.get(sourceObject, `${path}.${indicator}`) : sourceObject?.[indicator]
        const hasValue = value !== undefined && value !== null
        const totalValue = hasValue ? value / 1000 : null
        const intensityValue = totalValue !== null ? value / this.building.kpi.energy_area : null

        return {
          indicator,
          total: totalValue || 0,
          intensity: intensityValue || 0,
        }
      })
    },

    //
    onModalOpen() {
      this.modalOpen = true
    },

    //
    onModalClose() {
      this.modalOpen = false
    },

    // Have to calculate the composite total values because it seems
    // that the values stored in the database are the modelled values
    // and not the values that are adjusted/calibrated manually or with meters
    calculateCompositeValue(name, data, indicators) {
      const values = indicators.map(
        (indicator) => parseFloat(data.find((item) => item.indicator === indicator)?.total) || 0
      )
      const total = values.reduce((sum, value) => sum + value, 0)
      const intensity = values.reduce(
        (sum, value, index) =>
          sum + parseFloat(data.find((item) => item.indicator === indicators[index])?.intensity) || 0,
        0
      )

      return {
        indicator: name,
        total,
        intensity,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-energy-kpi-overview {
  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .name {
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: center;

    & .indicator {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & .tab {
      width: 8px;
    }
  }

  & .bold {
    font-weight: 600;
  }

  & .gray {
    font-size: var(--font-xs);
    color: #888;
  }

  & .divider {
    // Done to match the spacing of the other cards
    margin: 18px 0 17px;
  }
}
</style>

<style lang="scss">
.c-energy-kpi-overview {
  & .detail-list {
    & > ul {
      & > li {
        & > * {
          flex: 1 !important;
          width: 0 !important;
        }

        & > *.name {
          flex: 2 !important;
        }

        & .c-info-box {
          margin: 0 4px;
        }
      }
    }
  }
}
</style>
