<i18n>
  {
    "de": {
      "tariffTitle": "Tarife",
      "energyTitle": "Vergütung Energie",
      "ecoTitle": "Vergütung Herkunftsnachweise (HKN)",
      "totalCompensationTitle": "Vergütung total",
      "cancelEdit": "Abbrechen",
      "resetButton": "Rückliefertarife zurücksetzen",
      "tariffCategory": {
        "STANDARD": "Standard",
        "CUSTOM": "Benutzer definiert"
      }
    }
  }
  </i18n>

<template>
  <div class="c-edit-electricity-feedin-tariff">
    <DetailList has-header class="feedin-tarifs-table">
      <template #header>
        <span>{{ $t('tariffTitle') }}</span>
        <span class="align-right">{{ $t('energyTitle') }}</span>
        <span class="align-right">{{ $t('ecoTitle') }}</span>
        <span class="align-right">{{ $t('totalCompensationTitle') }}</span>
        <span
          v-if="getBuildingPermission('EDIT_TARIFFS')"
          :class="isEditing ? 'action-column-2' : 'action-column-1'"
        ></span>
      </template>
      <li v-if="!isEditing">
        <span>{{ $t(`tariffCategory.${defaultFeedinTariffData.category}`) }}</span>
        <span class="align-right">{{ formatNumber(defaultFeedinTariffData.energy, 1) }} Rp./kWh</span>
        <span class="align-right">{{ formatNumber(defaultFeedinTariffData.eco, 1) }} Rp./kWh</span>
        <span class="align-right">{{ totalValue }} Rp./kWh</span>
        <ListButtonWrapper v-if="getBuildingPermission('EDIT_TARIFFS')" class="action-column-1">
          <ListEditButton @click="onEdit()" />
        </ListButtonWrapper>
      </li>
      <li v-else>
        <span>{{ $t(`tariffCategory.${defaultFeedinTariffData.category}`) }}</span>
        <div class="align-right">
          <NumericInput
            v-model="model.energy"
            noFormatting
            :edit="isEditing"
            :units="'Rp./kWh'"
            @validation="onValidation($event, 'energy')"
          />
        </div>
        <div class="align-right">
          <NumericInput
            v-model="model.eco"
            noFormatting
            :edit="isEditing"
            :units="'Rp./kWh'"
            @validation="onValidation($event, 'eco')"
          />
        </div>
        <span class="align-right">{{ totalValue }} Rp./kWh</span>
        <ListButtonWrapper class="action-column-2">
          <ListSaveButton :disabled="!isValid || !isDirty" @click="onSave" />
          <ListCancelButton @click="onCloseEdit" />
        </ListButtonWrapper>
      </li>
    </DetailList>
    <ListActionFeedback :message="formState.error?.message || formState.error" @close="onFormEvent('clear-error')" />
    <ButtonWrapper v-if="isEditing && isCustomTariff">
      <button type="button" class="button" @click="onReset">
        {{ $t('resetButton') }}
      </button>
    </ButtonWrapper>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import DetailList from '@/components/shared/lists/DetailList'
import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import ListSaveButton from '@/components/shared/lists/ListSaveButton.vue'
import ListCancelButton from '@/components/shared/lists/ListCancelButton.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import ListActionFeedback from '@/components/shared/lists/ListActionFeedback.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    DetailList,
    ListButtonWrapper,
    ListEditButton,
    ListCancelButton,
    ListSaveButton,
    NumericInput,
    ButtonWrapper,
    ListActionFeedback,
  },

  props: {
    buildingId: {
      type: Number,
      required: true,
    },
    defaultFeedinTariffData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    totalValue() {
      const sum = this.model.energy + this.model.eco
      return isNaN(sum) ? '-' : this.formatNumber(sum, 1)
    },

    isCustomTariff() {
      return this.defaultFeedinTariffData.category === 'CUSTOM'
    },

    isDirty() {
      return (
        this.model.power !== this.defaultFeedinTariffData.power ||
        this.model.energy !== this.defaultFeedinTariffData.energy ||
        this.model.eco !== this.defaultFeedinTariffData.eco
      )
    },
  },

  watch: {
    defaultFeedinTariffData() {
      this.resetModel()
    },

    isEditing() {
      this.resetModel()
    },
  },

  methods: {
    ...mapActions({
      updateBuildingFeedinTariff: 'building/updateBuildingElectricityFeedinTariff',
    }),

    resetModel() {
      this.model = {
        power: this.defaultFeedinTariffData.power,
        energy: this.defaultFeedinTariffData.energy,
        eco: this.defaultFeedinTariffData.eco,
      }
    },

    onEdit() {
      this.model = { ...this.data }
      this.onFormEvent('edit')
    },

    onSave() {
      this.onFormEvent('save')
    },

    async saveHandler() {
      await this.updateBuildingFeedinTariff({
        id: this.buildingId,
        customValues: this.isDirty
          ? {
              category: 'CUSTOM',
              power: this.model.power,
              energy: this.model.energy,
              eco: this.model.eco,
            }
          : null,
      })
    },

    onReset() {
      this.updateBuildingFeedinTariff({
        id: this.buildingId,
        customValues: null,
      })
      this.onFormEvent('cancel')
    },

    onCloseEdit() {
      this.onFormEvent('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
.c-edit-electricity-feedin-tariff {
  & .select-width {
    min-width: 170px;
    width: 170px;
  }

  & .action-column-1 {
    max-width: 50px;
  }

  & .action-column-2 {
    max-width: 100px;
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }
}
</style>
