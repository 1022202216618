<i18n>
{
  "de": {
    "emissions": "Emissionen",
    "endEnergy": "Endenergie",
    "primaryEnergy": "Primärenergie",
    "scope": {
      "s12e": "Scope 1-2",
      "s123e": "Scope 1-3",
      "s1e": "Scope 1",
      "s2e": "Scope 2",
      "s3e": "Scope 3"
    },
    "energyDemand": {
      "ee": "Endenergiebedarf",
      "pe": "Primärenergiebedarf"
    },
    "energyUsage": "Verwendungszweck",
    "energyCarrier": "Energieträger",
    "measurementData": "Messdaten",
    "reductionPaths": "Absenkpfade"
  }
}
</i18n>

<template>
  <Modal class="c-emissions-kpi-modal" :width="800" @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('emissions') }}</h2>
    </template>

    <nav class="toggle-buttons kpi-type-switch">
      <button
        v-for="indicator in emissionsSustainabilityIndicatorIdentifiers"
        :key="indicator"
        type="button"
        :class="{ selected: kpiType === indicator }"
        @click="setKpiType(indicator)"
      >
        <span>{{ $t(`scope.${indicator}`) }}</span>
      </button>
    </nav>

    <div class="content">
      <div class="cards-row">
        <div class="kpi-values card">
          <h3>{{ `${$t(`scope.${kpiType}`)} ${$t('emissions')}` }}</h3>
          <KpiBox
            :value="formatNumberWithThreshold(kpiData.find((kpi) => kpi.indicator === kpiType).total, 2, 1, 1)"
            valueUnit="t CO₂e"
            :relativeValue="
              formatNumberWithThreshold(kpiData.find((kpi) => kpi.indicator === kpiType).intensity, 2, 1, 1)
            "
            relativeValueUnit="kg CO₂e/m²"
            centered
          />
        </div>
      </div>

      <div v-if="kpiData.find((kpi) => kpi.indicator === kpiType).total > 0" class="cards-row">
        <!-- Energy Carriers -->
        <div class="energy-carrier-chart card">
          <h3>{{ $t('energyCarrier') }}</h3>
          <KpiDoughnutChart
            :kpi-type="kpiType"
            :group-by-type="'carrier'"
            :portfolio="portfolio"
            :chart-data="chartData"
            :chart-options="chartOptions"
            exportable
          />
        </div>

        <!-- Usage -->
        <div class="energy-demand-chart card">
          <h3>{{ $t('energyUsage') }}</h3>
          <KpiDoughnutChart
            :kpi-type="kpiType"
            :group-by-type="'usage'"
            :portfolio="portfolio"
            :chart-data="chartData"
            :chart-options="chartOptions"
            exportable
          />
        </div>
      </div>

      <!-- Old: to be removed -->
      <!-- Meters -->
      <!-- <div class="measurement-data-chart card">
        <h3>{{ $t('measurementData') }}</h3>
        <MeasuredKpiChart :kpi-type="kpiType" :portfolio="portfolio" :query="{ building_id: this.building.id }" />
      </div> -->

      <Divider />

      <h3>{{ $t('reductionPaths') }}</h3>

      <!-- Projections 1 -->
      <div class="card">
        <ProjectionChart
          :building="building"
          :portfolio="portfolio"
          :projections="['carrier', 'usage']"
          default-projection="carrier"
          :kpi-type="kpiType"
        />
      </div>

      <!-- Projections 2 -->
      <div class="card">
        <ProjectionChart
          :building="building"
          :portfolio="portfolio"
          :projections="['carrier', 'usage']"
          default-projection="usage"
          :kpi-type="kpiType"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex'

import Modal from '@/components/shared/Modal.vue'
import KpiBox from '@/components/shared/kpi/KpiBox.vue'
import KpiDoughnutChart from '@/components/portfolio/kpi/charts/KpiDoughnutChart.vue'
import ProjectionChart from '@/components/building/kpi/ProjectionChart.vue'
import Divider from '@/components/cui/data-display/Divider.vue'
// Old: to be removed
// import MeasuredKpiChart from '@/components/report/charts/MeasuredKpiChart.vue'

export default {
  components: {
    Modal,
    KpiBox,
    KpiDoughnutChart,
    ProjectionChart,
    Divider,

    // Old: to be removed
    // MeasuredKpiChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
    kpiData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      kpiType: 's12e',
    }
  },

  computed: {
    ...mapState('portfolio', ['emissionsSustainabilityIndicatorIdentifiers']),

    chartOptions() {
      return {
        plugins: {
          legend: {
            labels: {
              font: {
                size: 12,
                weight: '500',
                lineHeight: 1,
              },
              boxWidth: 20,
              padding: 5,
            },
          },
        },
      }
    },
  },

  methods: {
    setKpiType(kpiType) {
      this.kpiType = kpiType
    },
  },
}
</script>

<style lang="scss" scoped>
.c-emissions-kpi-modal {
  display: flex;
  flex-direction: column;

  & .kpi-type-switch {
    width: 100%;
  }

  & .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);

    & .cards-row {
      display: flex;
      flex-direction: row;
      flex: 1;
      margin-bottom: var(--spacing-l);
    }

    & .card {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .kpi-values {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xs);
      flex: 1;
    }

    & .energy-demand-chart,
    & .energy-carrier-chart,
    & .measurement-data-chart {
      flex: 1;
    }

    & h3 {
      text-align: center;
    }
  }
}
</style>
