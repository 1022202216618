<i18n>
{
  "de": {
    "generic": {
      "DEFAULT": "Quelle: Standardannahme, effektiver Wert unbekannt",
      "GIS": "Quelle: GWR",
      "MANUAL": "Quelle: Manuelle Eingabe"
    },
    "roofType": {
      "DEFAULT": "Quelle: Standardannahme, effektiver Wert unbekannt",
      "GIS": "Quelle: GIS-Gebäudedaten",
      "MANUAL": "Quelle: Manuelle Eingabe"
    },
    "component": {
      "AUTO": "Schätzung",
      "MANUAL": "Manuell"
    }
  }
}
</i18n>

<template>
  <div>
    {{ value }}
    <div class="source-info">{{ $t(translationKey) }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },
    translationKey: {
      type: String,
    },
  },
}
</script>

<style scoped>
.source-info {
  color: var(--secondary-text-color);
  font-size: var(--font-xs);
  line-height: 16px;
}
</style>
