<i18n>
{
  "de": {
    "parcelTitle": "Parzelle",
    "parcelArea": "Grundstückfläche",
    "parcelNr": "Nr"
  }
}
</i18n>
<template>
  <MapboxPopup :coordinates="coordinates" :showed="showed" anchor="top" @close="onPopupClose">
    <div class="parcel-popup">
      <template v-if="feature !== undefined">
        <div class="title">{{ $t('parcelTitle') }}</div>
        <div v-if="feature.properties.parcel_nr !== 'null'">
          {{ $t('parcelNr') }}: {{ feature.properties.parcel_nr }}
        </div>
        <div>{{ $t('parcelArea') }}: {{ formatNumber(feature.properties.area, 0) }} m²</div>
      </template>
    </div>
  </MapboxPopup>
</template>

<script>
import MapboxPopup from '@/components/shared/MapboxPopup.vue'

export default {
  components: {
    MapboxPopup,
  },

  props: {
    feature: {
      type: Object,
    },
    coordinates: {
      type: Array,
      required: true,
    },
    showed: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    onPopupClose(event) {
      this.$emit('close', event)
    },
  },
}
</script>

<style>
.parcel-popup .title {
  font-weight: 600;
  margin-bottom: var(--spacing-m);
}
</style>
