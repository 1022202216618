<i18n>
{
  "de": {
    "pvMethod": "Auslegung",
    "pvMethodAuto": "Automatische Auslegung mit GIS-Daten",
    "pvMethodManual": "Manuelle Auslegung",
    "costsAuto": "Automatische Kostenschätzung",
    "costsLabel": "Kosten",
    "subsidiesLabel": "Subventionen"
  }
}
</i18n>

<template>
  <div class="c-edit-pv-measure">
    <FormRow :label="$t('pvMethod')">
      <div class="pv-method">
        <template v-if="edit">
          <input id="pv-method-auto" v-model="model.method" type="radio" value="AUTO" @change="onInput" />
          <label for="pv-method-auto">{{ $t('pvMethodAuto') }}</label>
          <br />
          <input id="pv-method-manual" v-model="model.method" type="radio" value="MANUAL" @change="onInput" />
          <label for="pv-method-manual">{{ $t('pvMethodManual') }}</label>
        </template>
        <template v-else>
          <span v-if="model.method === 'AUTO'">{{ $t('pvMethodAuto') }}</span>
          <span v-else>{{ $t('pvMethodManual') }}</span>
        </template>
        <EditPvDimensioning
          v-if="model.method === 'AUTO'"
          v-model="model.dimensioning"
          class="pv-dimensioning"
          :edit="isEditing"
          @input="onDimensioningChange"
          @validation="onValidation($event, 'pv-dimensioning')"
        />
        <EditPvSections
          v-if="model.method === 'MANUAL'"
          v-model="model.sections"
          class="pv-sections"
          :edit="isEditing"
          :pv-results="pvResults"
          @input="onSectionsChange"
          @validation="onValidation($event, 'pv-sections')"
        />
      </div>
    </FormRow>

    <FormRow :label="$t('costsLabel')">
      <NumericInput
        v-model="model.costs"
        int
        optional
        :min="0"
        :edit="isEditing"
        :units="'CHF'"
        class="limit-width"
        @input="onInput"
        @validation="onValidation($event, 'pv-costs')"
      />
    </FormRow>
    <FormRow :label="$t('subsidiesLabel')">
      <NumericInput
        v-model="model.subsidies"
        int
        optional
        :min="0"
        :edit="isEditing"
        :units="'CHF'"
        class="limit-width"
        @input="onInput"
        @validation="onValidation($event, 'pv-subsidies')"
      />
    </FormRow>
  </div>
</template>

<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin.vue'

import EditPvSections from '@/components/building/edit/EditPvSections.vue'
import EditPvDimensioning from '@/components/building/measures/edit/EditPvDimensioning.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'

export default {
  mixins: [
    //
    ComplexFormMixin,
  ],

  components: {
    FormRow,
    EditPvSections,
    EditPvDimensioning,
    NumericInput,
  },

  props: {
    pvResults: {
      type: Array,
    },
  },

  computed: {
    isValid() {
      for (let i = 0; i < this.invalidFields.length; i++) {
        const field = this.invalidFields[i]
        if (field === 'pv-sections') {
          if (this.model.method === 'MANUAL') {
            return false
          }
        } else if (field === 'pv-dimensioning') {
          if (this.model.method === 'AUTO') {
            return false
          }
        } else {
          return false
        }
      }
      return true
    },
  },

  methods: {
    onSectionsChange(sections) {
      this.model.sections = sections
      this.onInput()
    },

    onDimensioningChange(dimensioning) {
      this.model.dimensioning = dimensioning
      this.onInput()
    },
  },
}
</script>

<style lang="scss" scoped>
.c-edit-pv-measure {
  & .pv-method {
    width: 100%;
  }

  & .pv-sections,
  & .pv-dimensioning {
    margin-top: var(--spacing-m);
    width: 100%;
  }

  & .limit-width {
    max-width: 150px;
  }
}
</style>
