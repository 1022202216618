<i18n>
{
  "de": {
    "renovationHeader": "Gebäudehülle",
    "wallsAge": "Alter Fassade",
    "windowsAge": "Alter Fenster",
    "basementAge": "Alter Kellerdecke",
    "roofAge": "Alter Dach",
    "buildingComponents": {
      "walls": "Fassade",
      "windows": "Fenster",
      "basement": "Kellerdecke",
      "roof": "Dach"
    },
    "years": "Jahre",
    "lifecyclePopupTitle": "Zustand Gebäudehülle",
    "originalStateLabel": "Nicht saniert",
    "renovationLabel": "Saniert",
    "partialRenovationLabel": "Teilsaniert",
    "constructionYear": "Baujahr",
    "componentYearsUnknown": "Der Zustand der Gebäudehülle ist teilweise unbekannt",
    "manualRhDemand": "Wärmebedarf manuell",
    "missingBuildingYear": "Baujahr unbekannt"
  }
}
</i18n>

<template>
  <DetailListItem
    class="c-current-state-envelope-summary-row"
    :title="$t('renovationHeader')"
    expandable
    @expand="onModalOpen"
  >
    <div
      v-if="
        building.current_state.result &&
        (!building.current_state.result.envelope || !building.current_state.result.envelope.building_year.value)
      "
    >
      <MissingDataLabel>{{ $t('missingBuildingYear') }}</MissingDataLabel>
    </div>

    <!-- <div v-else-if="building.current_state.envelope.manual_rh_demand && building.current_state.result">
      {{ $t('manualRhDemand') }}
    </div> -->

    <div v-else-if="building.current_state.result" class="details">
      <DataWarning v-if="componentYearsUnknown">{{ $t('componentYearsUnknown') }}</DataWarning>
      <template v-if="buildingComponents">
        <template v-for="buildingComponent in ['walls', 'windows', 'roof', 'basement']">
          <span :key="buildingComponent" class="pill">
            {{ `${$t(`buildingComponents.${buildingComponent}`)}:&nbsp;${buildingComponents[buildingComponent]}` }}
          </span>
        </template>
      </template>
    </div>

    <template
      v-if="
        building.current_state.result &&
        building.current_state.result.envelope &&
        building.current_state.result.envelope.building_year.value &&
        !building.current_state.envelope.without_heating &&
        !building.current_state.envelope.manual_rh_demand
      "
      #rightContent
    >
      <VPopover trigger="hover" placement="right" class="lifecycle-popover">
        <RingIndicator
          :year="building.walls_year"
          :minLifetime="building.walls_replacement_year"
          :maxLifetime="building.walls_replacement_year + 5"
          class="ring-indicator"
        />
        <RingIndicator
          :year="building.windows_year"
          :minLifetime="building.windows_replacement_year"
          :maxLifetime="building.windows_replacement_year + 5"
          class="ring-indicator"
        />
        <RingIndicator
          :year="building.roof_year"
          :minLifetime="building.roof_replacement_year"
          :maxLifetime="building.roof_replacement_year + 5"
          class="ring-indicator"
        />
        <RingIndicator
          v-if="building.current_state.envelope.basement_exists"
          :year="building.basement_year"
          :minLifetime="building.basement_replacement_year"
          :maxLifetime="building.basement_replacement_year + 5"
          class="ring-indicator"
        />
        <template slot="popover">
          <header>
            {{ $t('lifecyclePopupTitle') }}
          </header>
          <main class="c-current-state-envelope-summary-row-lifecycle">
            <RingIndicator
              :year="building.walls_year"
              :minLifetime="building.walls_replacement_year"
              :maxLifetime="building.walls_replacement_year + 5"
            />
            {{ $t('wallsAge') }}: {{ wallsAge }} {{ $t('years') }}
          </main>
          <main class="c-current-state-envelope-summary-row-lifecycle">
            <RingIndicator
              :year="building.windows_year"
              :minLifetime="building.windows_replacement_year"
              :maxLifetime="building.windows_replacement_year + 5"
            />
            {{ $t('windowsAge') }}: {{ windowsAge }} {{ $t('years') }}
          </main>
          <main class="c-current-state-envelope-summary-row-lifecycle">
            <RingIndicator
              :year="building.roof_year"
              :minLifetime="building.roof_replacement_year"
              :maxLifetime="building.roof_replacement_year + 5"
            />
            {{ $t('roofAge') }}: {{ roofAge }} {{ $t('years') }}
          </main>
          <main
            v-if="building.current_state.envelope.basement_exists"
            class="c-current-state-envelope-summary-row-lifecycle"
          >
            <RingIndicator
              :year="building.basement_year"
              :minLifetime="building.basement_replacement_year"
              :maxLifetime="building.basement_replacement_year + 5"
            />
            {{ $t('basementAge') }}: {{ basementAge }} {{ $t('years') }}
          </main>
        </template>
      </VPopover>
    </template>

    <template #modal>
      <Modal v-if="isModalOpen" wide @close="onModalClose">
        <template #header>
          <h2>{{ $t('renovationHeader') }}</h2>
        </template>
        <ViewEnvelope :building="building" :portfolio="portfolio" />
      </Modal>
    </template>
  </DetailListItem>
</template>

<script>
import { VPopover } from 'v-tooltip'

import ModalMixin from '@/components/shared/ModalMixin.vue'

import Modal from '@/components/shared/Modal.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import RingIndicator from '@/components/shared/RingIndicator.vue'
import DataWarning from '@/components/shared/DataWarning.vue'
import MissingDataLabel from '@/components/shared/MissingDataLabel.vue'
import ViewEnvelope from '@/components/building/view/ViewEnvelope.vue'

export default {
  mixins: [
    //
    ModalMixin,
  ],

  components: {
    VPopover,
    Modal,
    ViewEnvelope,
    DetailListItem,
    RingIndicator,
    DataWarning,
    MissingDataLabel,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
  },

  computed: {
    componentYearsUnknown() {
      for (const c of ['walls', 'roof', 'basement', 'windows']) {
        if (this.building.current_state.envelope[c].year_estimated) {
          return true
        }
      }
      return false
    },

    buildingComponents() {
      let componentYears = {}
      for (const c of ['walls', 'roof', 'basement', 'windows']) {
        const buildingYear = this.building.current_state.result.envelope.building_year.value
        const componentYear = this.building.current_state.envelope[c].year || buildingYear
        componentYears[c] = componentYear
      }
      return componentYears
    },

    currentYear() {
      return new Date().getFullYear()
    },

    wallsAge() {
      return this.currentYear - this.building.walls_year
    },

    windowsAge() {
      return this.currentYear - this.building.windows_year
    },

    basementAge() {
      return this.currentYear - this.building.basement_year
    },

    roofAge() {
      return this.currentYear - this.building.roof_year
    },
  },
}
</script>

<style lang="scss">
.c-current-state-envelope-summary-row {
  position: relative;

  & .lifecycle-popover {
    display: inline;
  }

  & .ring-indicator {
    height: 28px;
    margin-right: 7px;
    vertical-align: middle;
  }

  & .details {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--spacing-xxs);
    width: 100%;
  }

  & .pill {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    padding: 0 var(--spacing-s);
    border: var(--box-border);
    border-radius: 13px;
    height: 24px;
    font-size: 14px;

    & > span {
      display: inline-block;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.c-current-state-envelope-summary-row-lifecycle {
  display: grid;
  grid-template-columns: 32px 1fr;

  & .ring-indicator {
    height: 24px;
  }
}
</style>
