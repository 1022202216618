<i18n>
  {
    "de": {
      "roof": "Dach",
      "fassade": "Fassade",
      "editRoofLayer": "Dach bearbeiten",
      "viewRoofLayer": "Dach ansehen",
      "close": "Schliessen",
      "googleMaps": "Google Maps",
      "swissGis": "map.geo.admin.ch",
      "cantonZhGisAv": "Amtliche Vermessung Kanton ZH",
      "oerebZhGis": "ÖREB Kanton ZH",
      "loading": "Karte wird geladen..."
    }
  }
  </i18n>

<template>
  <div class="c-building-map">
    <div class="map-container">
      <MglMap
        :ref="`map-${building.id}-${mapMode}`"
        :key="`map-${building.id}-${mapMode}`"
        :accessToken="accessToken"
        :mapStyle.sync="mapStyle"
        :scrollZoom="false"
        :center="coordinates"
        :zoom="initialZoom"
        :attributionControl="false"
        @load="onMapLoaded"
      >
        <!-- Navigation control -->
        <MglNavigationControl position="top-left" />

        <!-- GeoJSON layers -->
        <EnergyGridPolygonLayer
          v-if="mapLoaded && building.energy_grids"
          :building="building"
          sourceId="energyGridPolygonSource"
          layerId="energyGridPolygonLayer"
          @selectfeature="gridSelect"
        />
        <EnergyGridPointLayer
          v-if="mapLoaded && building.energy_grids"
          :building="building"
          sourceId="energyGridPointSource"
          layerId="energyGridPointLayer"
          @selectfeature="gridSelect"
        />
        <ParcelLayer
          v-if="mapLoaded"
          :building="building"
          sourceId="parcelsSource"
          layerId="parcelsLayer"
          @selectfeature="parcelSelect"
        />
        <RoofTileLayer
          v-if="mapLoaded"
          :building="!isEditLayers ? building : null"
          sourceId="roofTilesSource"
          layerId="roofTilesLayer"
          @selectfeature="roofTileSelect"
        />
        <RoofEditTileLayer
          v-if="mapLoaded && isEditRoofTilesLayer"
          :building="building"
          sourceId="roofEditTilesSource"
          layerId="roofEditTilesLayer"
          @selectfeature="roofTileSelect"
        />
        <!-- <RoofEditTileIconsLayer
        v-if="mapLoaded && isEditRoofTilesLayer"
        :building="building"
        sourceId="roofEditTilesIconSource"
        layerId="roofEditTilesIconLayer"
        @selectfeature="roofTileSelect"
      /> -->
        <FassadeTileLayer
          v-if="mapLoaded"
          :building="!isEditLayers ? building : null"
          sourceId="fassadeTilesSource"
          layerId="fassadeTilesLayer"
          @selectfeature="fassadeTileSelect"
        />
        <!-- <FassadeEditTileLayer
        v-if="mapLoaded && isEditFassadeTilesLayer"
        :building="building"
        sourceId="fassadeEditTilesSource"
        layerId="fassadeEditTilesLayer"
        @selectfeature="fassadeTileSelect"
      /> -->
        <EgidLayer
          v-if="mapLoaded"
          :building="building"
          sourceId="egidSource"
          layerId="egidLayer"
          @selectfeature="egidSelect"
        />

        <!-- Popups -->
        <RoofTilePopup
          :building="building"
          :feature="activeFeature"
          :coordinates="popupCoordinates"
          :showed="activeLayer === 'roofTiles'"
          @close="onPopupClose"
        />
        <FassadeTilePopup
          :building="building"
          :feature="activeFeature"
          :coordinates="popupCoordinates"
          :showed="activeLayer === 'fassadeTiles'"
          @close="onPopupClose"
        />
        <ParcelPopup
          :feature="activeFeature"
          :coordinates="popupCoordinates"
          :showed="activeLayer === 'parcels'"
          @close="onPopupClose"
        />
        <EgidPopup
          :feature="activeFeature"
          :coordinates="popupCoordinates"
          :showed="activeLayer === 'egid'"
          @close="onPopupClose"
        />
        <EnergyGridPopup
          v-if="building.energy_grids"
          :feature="activeFeature"
          :coordinates="popupCoordinates"
          :showed="activeLayer === 'energyGrids'"
          @close="onPopupClose"
        />
      </MglMap>

      <div class="edit-layers">
        <div v-if="!mapLoaded" class="loading">
          <ClipLoader color="#000" size="16px" />
          <span>{{ $t('loading') }}</span>
        </div>

        <template v-if="!isEditLayers && mapLoaded">
          <button type="button" class="edit-layers-button" @click="onEditRoofTilesLayer">
            <template v-if="getBuildingPermission('EDIT_FEASIBILITY')">
              <img src="/icons/edit.svg" />
              <span>
                {{ $t('editRoofLayer') }}
              </span>
            </template>
            <template v-else>
              <span>
                {{ $t('viewRoofLayer') }}
              </span>
            </template>
          </button>
        </template>

        <template v-if="isEditLayers && mapLoaded">
          <!-- <button type="button" class="type-button" @click="onEditRoofTilesLayer">
            <strong>
              {{ $t('roof') }}
            </strong>
            <img v-if="getBuildingPermission('EDIT_FEASIBILITY') && isEditRoofTilesLayer" src="/icons/edit.svg" />
          </button> -->
          <!-- <button type="button" class="type-button" @click="onEditFassadeTilesLayer">
          <strong>
            {{ $t('fassade') }}
          </strong>
          <img v-if="isEdifFassadeTilesLayer" src="/icons/edit.svg" />
        </button> -->
          <button type="button" class="close-button" @click="onCloseEditLayers">
            <img src="/icons/x.svg" />
            <span>{{ $t('close') }}</span>
          </button>
        </template>
      </div>

      <div v-if="!isEditLayers" class="gis-links">
        <a v-if="googleMapsUrl" :href="googleMapsUrl" :title="$t('googleMaps')" rel="noopener" target="_blank">{{
          $t('googleMaps')
        }}</a>
        <a v-if="swissGisUrl" :href="swissGisUrl" :title="$t('swissGis')" rel="noopener" target="_blank">{{
          $t('swissGis')
        }}</a>
        <a
          v-if="cantonZhGisAvUrl"
          :href="cantonZhGisAvUrl"
          :title="$t('cantonZhGisAv')"
          rel="noopener"
          target="_blank"
          >{{ $t('cantonZhGisAv') }}</a
        >
        <a v-if="oerebZhGisUrl" :href="oerebZhGisUrl" :title="$t('oerebZhGis')" rel="noopener" target="_blank">{{
          $t('oerebZhGis')
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { MglMap, MglNavigationControl } from 'vue-mapbox'
import { mapGetters } from 'vuex'

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import FassadeTileLayer from '@/components/building/map/FassadeTileLayer.vue'
import FassadeTilePopup from '@/components/building/map/FassadeTilePopup.vue'
import RoofTileLayer from '@/components/building/map/RoofTileLayer.vue'
import RoofEditTileLayer from '@/components/building/map/RoofEditTileLayer.vue'
import RoofTilePopup from '@/components/building/map/RoofTilePopup.vue'
import ParcelLayer from '@/components/building/map/ParcelLayer.vue'
import ParcelPopup from '@/components/building/map/ParcelPopup.vue'
import EgidLayer from '@/components/building/map/EgidLayer.vue'
import EgidPopup from '@/components/building/map/EgidPopup.vue'
import EnergyGridPolygonLayer from '@/components/building/map/EnergyGridPolygonLayer.vue'
import EnergyGridPointLayer from '@/components/building/map/EnergyGridPointLayer.vue'
import EnergyGridPopup from '@/components/building/map/EnergyGridPopup.vue'

export default {
  components: {
    MglMap,
    MglNavigationControl,
    ClipLoader,
    FassadeTileLayer,
    FassadeTilePopup,
    RoofTileLayer,
    RoofEditTileLayer,
    RoofTilePopup,
    ParcelLayer,
    ParcelPopup,
    EgidLayer,
    EgidPopup,
    EnergyGridPolygonLayer,
    EnergyGridPointLayer,
    EnergyGridPopup,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    layout: {
      type: String,
      default: 'vertical',
    },
    mapMode: {
      type: String,
      default: 'map',
    },
  },

  data() {
    return {
      mapLoaded: false,
      accessToken: 'pk.eyJ1IjoibWl2dW5lIiwiYSI6ImNsNWNreTd0cTBpZHkza28xeWo1ODJzamEifQ.Srz2_XsFlucH_7qZzPqkLQ',
      // Old custom mapbox style
      // mapStyle: 'mapbox://styles/mivune/cl5clbnp5001a14pvz0v0wugw',
      // swisstopo normal basemap style
      // normalStyle: 'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.basemap_world.vt/style.json?key=QSCGTjvrbWP0n9n3qf98', // key for enrique.ruizdurazo@tend.ch
      // swisstopo light basemap style
      lightStyle:
        'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.lightbasemap_world.vt/style.json?key=QSCGTjvrbWP0n9n3qf98', // key for enrique.ruizdurazo@tend.ch
      // swisstopo satellite basemap style
      satelliteStyle:
        'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.imagerybasemap_world.vt/style.json?key=QSCGTjvrbWP0n9n3qf98', // key for enrique.ruizdurazo@tend.ch
      activeLayer: undefined,
      activeFeature: undefined,
      popupCoordinates: [8, 48], // this can't be blank!  it won't be shown but pick something
      isEditRoofTilesLayer: false,
      isEditFassadeTilesLayer: false,
    }
  },

  computed: {
    ...mapGetters({
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),

    // Map style
    mapStyle() {
      return this.mapMode === 'satellite' ? this.satelliteStyle : this.lightStyle
    },

    coordinates() {
      return [(this.building.bbox[0] + this.building.bbox[2]) / 2, (this.building.bbox[1] + this.building.bbox[3]) / 2]
    },

    initialZoom() {
      // https://wiki.openstreetmap.org/wiki/Zoom_levels
      return Math.min(18, Math.floor(Math.log2(360 / (this.building.bbox[2] - this.building.bbox[0]))))
    },

    googleMapsUrl() {
      const zoom = 125
      const tilt = 45
      const angle = 0
      const cameraLatitude = this.building.latitude - 0.001 // move camera south
      const cameraLongitude = this.building.longitude
      return `https://www.google.com/maps/place/${this.building.latitude} N ${this.building.longitude} E/@${cameraLatitude},${cameraLongitude},${zoom}a,35y,${angle}h,${tilt}t/data=!3m1!1e3!4m5!3m4!1s0x0:0x0!8m2!3d${this.building.latitude}!4d${this.building.longitude}`
    },

    swissGisUrl() {
      if (this.building.location_info.state !== 'OK') {
        return false
      }
      const zoom = 12
      const coordsEast = this.building.location_info.data.coordinates_lv95.x
      const coordsNorth = this.building.location_info.data.coordinates_lv95.y
      return `https://map.geo.admin.ch/?lang=de&topic=ech&bgLayer=ch.swisstopo.pixelkarte-farbe&layers=ch.swisstopo.zeitreihen,ch.swisstopo-vd.amtliche-vermessung,ch.kantone.cadastralwebmap-farbe,ch.swisstopo.swissimage-product,ch.bfe.solarenergie-eignung-fassaden,ch.bfe.solarenergie-eignung-daecher,ch.bfs.gebaeude_wohnungs_register&layers_visibility=false,false,true,false,false,false,true&layers_timestamp=18641231,,,,,&E=${coordsEast}&N=${coordsNorth}&zoom=${zoom}`
    },

    cantonZhGisAvUrl() {
      if (
        this.building.location_info.state !== 'OK' ||
        this.building.gwr.entries === null ||
        this.building.gwr.entries.length === 0 ||
        this.building.gwr.entries[0].properties.gdekt !== 'ZH'
      ) {
        return false
      }
      const coordsEast = this.building.location_info.data.coordinates_lv95.x
      const coordsNorth = this.building.location_info.data.coordinates_lv95.y
      const scale = 488
      return `https://maps.zh.ch?topic=AVfarbigZH&scale=${scale}&x=${coordsEast}&y=${coordsNorth}&srid=2056&offlayers=av-stand%2CLCOBJPROJ%2Cbezirkslabels`
    },

    oerebZhGisUrl() {
      if (
        this.building.location_info.state !== 'OK' ||
        this.building.gwr.entries === null ||
        this.building.gwr.entries.length === 0 ||
        this.building.gwr.entries[0].properties.gdekt !== 'ZH'
      ) {
        return false
      }
      const coordsEast = this.building.location_info.data.coordinates_lv95.x
      const coordsNorth = this.building.location_info.data.coordinates_lv95.y
      const scale = 488
      return `https://maps.zh.ch?topic=OerebKatasterZH&scale=${scale}&x=${coordsEast}&y=${coordsNorth}&srid=2056&offlayers=projektierungszonen-flughafenanlagen%2Csicherheitszonen%2Ckbs-oev%2Ckbs-zfp%2Ckbs-militaer-bund%2Cul-breiche%2Claermempfindlichkeitsstufen%2Cls-proj%2Cul-b-proj%2Ckbs%2Cgrundwasserschutzareale%2Cgwsa-proj%2Cgrundwasserschutzzonen%2Cgwsz-proj%2Cgewaesserabstandslinien%2Cgal-proj%2CBaulinien_Nationalstrassen_OeREB%2Cbaulinien%2Cbl-proj%2Cwaldgrenzen%2Cwg-proj%2Cwaldabstandslinien%2Cwal-proj%2Cmarkierung-liegenschaft%2Cmarkierung-sr`
    },

    isEditLayers() {
      return this.isEditRoofTilesLayer || this.isEditFassadeTilesLayer
    },
  },

  created() {
    this.map = null
  },

  methods: {
    onMapLoaded() {
      this.mapLoaded = true
    },

    onPopupClose() {
      this.activeLayer = undefined
      this.activeFeature = undefined
    },

    egidSelect(data) {
      this.activeLayer = 'egid'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },

    parcelSelect(data) {
      this.activeLayer = 'parcels'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },

    roofTileSelect(data) {
      this.activeLayer = 'roofTiles'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },

    fassadeTileSelect(data) {
      this.activeLayer = 'fassadeTiles'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },

    gridSelect(data) {
      this.activeLayer = 'energyGrids'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },

    onEditRoofTilesLayer() {
      this.isEditRoofTilesLayer = true
      this.isEditFassadeTilesLayer = false
    },

    onEditFassadeTilesLayer() {
      this.isEditRoofTilesLayer = false
      this.isEditFassadeTilesLayer = true
    },

    onCloseEditLayers() {
      this.isEditRoofTilesLayer = false
      this.isEditFassadeTilesLayer = false
    },
  },
}
</script>

<style lang="scss">
.c-building-map {
  position: relative;
  height: 100%;

  & .map-container {
    position: relative;
    height: calc(100% - 65px);
  }

  & .info-panel {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 300px;
  }

  & .gis-links {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    gap: 2px;
    width: 100%;
    // Future, make space for navigation control
    // max-width: calc(100% - 42px);
    padding: var(--spacing-xs);

    & a {
      background-color: white;
      padding: var(--spacing-xs) var(--spacing-s);
      font-size: var(--font-xs);
      font-weight: 500;
      border-radius: 40px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:hover {
        cursor: pointer;
        background-color: #f4f4f4;
      }

      &:first-child {
        flex: none;
      }

      &:nth-child(2) {
        flex: none;
      }
    }
  }
}

.edit-layers {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 40px;
  min-height: 36px;

  & .loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-s);
    padding: 8px 16px;

    & > * {
      line-height: 20px;
      flex: none;
    }
  }

  & .edit-layers-button {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    background-color: white;
    border: none;
    border-radius: 40px;
    font-weight: 500;
    gap: var(--spacing-s);

    &:hover {
      cursor: pointer;
      background-color: #f4f4f4;
    }

    & img {
      width: 16px;
      height: auto;
    }

    & span {
      line-height: 20px;
    }
  }

  & .type-button {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    background-color: #f5f5f5;
    text-decoration: underline;
    border: none;
    border-radius: 40px;
    gap: var(--spacing-s);

    & strong {
      line-height: 20px;
    }

    & img {
      width: 16px;
      height: auto;
    }
  }

  & .close-button {
    flex: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 16px;
    background-color: transparent;
    border: none;
    gap: var(--spacing-xs);
    font-weight: 500;

    & img {
      width: 20px;
      height: auto;
    }
  }

  & button:hover {
    cursor: pointer;
  }
}
</style>
