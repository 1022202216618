<!--
AsyncContentMixin
Requires implementation of the following methods: loadHandler
Provides: formState
-->
<script>
import compassApi from '@/services/compass-api.js'

export default {
  data() {
    return {
      private__error: null, // Error message
      private__pending: false, // API request is currently pending
      private__ready: false,
      private__abortController: null,
    }
  },

  computed: {
    contentState() {
      return {
        pending: this.private__pending,
        error: this.private__error,
        ready: this.private__ready,
      }
    },
  },

  mounted() {
    this.refreshContent()
  },

  beforeDestroy() {
    this.abortCurrentRequest()
  },

  methods: {
    async refreshContent() {
      this.abortCurrentRequest()
      this.private__abortController = compassApi.createAbortController()
      this.private__pending = true
      this.private__ready = false
      this.private__error = null

      try {
        await this.loadContentHandler(this.private__abortController.signal)
        this.private__ready = true
      } catch (error) {
        if (error.name !== 'AbortError') {
          this.private__error = error
        }
      } finally {
        this.private__pending = false
        this.$emit('loaded')
      }
    },

    abortCurrentRequest() {
      if (this.private__abortController) {
        this.private__abortController.abort()
        this.private__abortController = null
      }
    },
  },
}
</script>
