<script>
import BaseLayer from '@/components/building/map/BaseLayer.vue'

export default {
  components: {
    BaseLayer,
  },

  extends: BaseLayer,

  computed: {
    sourceConfig() {
      let features = []
      if (this.building.cadastre.state === 'OK') {
        for (let i = 0; i < this.building.cadastre.parcels.length; i++) {
          let parcel = this.building.cadastre.parcels[i]
          features.push({
            id: i,
            type: 'Feature',
            geometry: parcel['geometry'],
            properties: parcel,
          })
        }
      }
      return {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: features,
        },
      }
    },

    layerConfig() {
      return {
        type: 'line',
        source: this.sourceId,
        paint: {
          'line-color': '#ff0000',
          'line-width': 3,
          'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.5],
        },
      }
    },
  },
}
</script>
