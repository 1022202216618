<template>
  <hr :class="`cui-divider ${className}`" />
</template>

<script>
export default {
  name: 'Divider',

  props: {
    className: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.cui-divider {
  height: 1px;
  border-width: 0;
  color: var(--hairline-color);
  background-color: var(--hairline-color);
  margin: 0;
}
</style>
