<template>
  <ul :class="`cui-list ${computedClass} ${className}`">
    <slot></slot>
  </ul>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'List',

  props: {
    className: {
      type: String,
      default: '',
    },
    divider: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClass() {
      const classes = {
        divider: this.divider,
      }
      return _.keys(classes)
        .filter((key) => classes[key])
        .join(' ')
    },
  },
}
</script>

<style lang="scss" scoped>
.cui-list.divider li {
  border-bottom: 1px solid var(--separator-color);

  &:last-child {
    border-bottom: none;
  }
}
</style>
