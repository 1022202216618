<template>
  <BubbleChartWrapper
    :key="`building-bubble--${building.id}-${key}`"
    :highlight-building-id="building.id"
    :chart-data="chartData"
    class="c-bubble-summary-chart"
    @click="onBubbleClick"
  />
</template>

<script>
import BubbleChartWrapper from '@/components/portfolio/kpi/charts/BubbleChartWrapper.vue'

export default {
  components: {
    BubbleChartWrapper,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      key: 0,
    }
  },

  watch: {
    chartData: {
      handler() {
        this.key++
      },
      deep: true,
    },
  },

  methods: {
    onBubbleClick(building) {
      this.$router.push({
        name: 'buildingDetails',
        params: { portfolio_id: building.portfolio_id, building_id: building.id },
      })
    },
  },
}
</script>
