<i18n>
{
  "de": {
    "zoneModalTitle": "Fläche & Nutzung"
  }
}
</i18n>

<template>
  <Modal wide class="zones-modal" @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('zoneModalTitle') }}</h2>
    </template>

    <ViewZones :building="building" />
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import ViewZones from '@/components/building/view/ViewZones.vue'

export default {
  components: {
    ViewZones,
    Modal,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },
}
</script>
