<template>
  <Bubble :chart-data="chartData" :chart-options="chartOptions" :height="320" />
</template>

<script>
import _ from 'lodash'
import 'chart.js/auto'
import { Bubble } from 'vue-chartjs/legacy'

export default {
  components: { Bubble },

  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
    },
  },

  computed: {
    chartOptions() {
      let options = _.cloneDeep(this.options)
      if (!options?.plugins?.annotation) _.set(options, 'plugins.annotation.annotations', {})
      return options
    },
  },
}
</script>
