<i18n>
{
  "de": {
    "photovoltaic": "Photovoltaik",
    "pvProduction": "PV-Produktion",
    "ownUse": "Eigenverbrauch",
    "measurementData": "Messdaten",
    "reductionPaths": "Absenkpfade"
  }
}
</i18n>

<template>
  <Modal class="c-pv-kpi-modal" :width="800" @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('photovoltaic') }}</h2>
    </template>

    <div class="content">
      <div class="cards-row">
        <div class="kpi-values card">
          <h3>{{ $t('pvProduction') }}</h3>
          <KpiBox
            :value="formatNumberWithThreshold((building?.kpi?.el_production || 0) / 1000, 2, 1, 1)"
            valueUnit="MWh"
            :relativeValue="`${$t('ownUse')}: ${formatNumberWithThreshold(
              kpiData.find((kpi) => kpi.indicator === 'el_ownuse').share,
              2,
              1,
              1
            )}`"
            relativeValueUnit="%"
          />
        </div>
        <div class="pv-share-chart card">
          <h3>{{ $t('pvProduction') }}</h3>
          <DoughnutChart :chart-data="chartData" :options="chartOptions" :expandable="false" />
        </div>
      </div>

      <!-- Old: to be removed -->
      <!-- Meters -->
      <!-- <div class="measurement-data-chart card">
        <h3>{{ $t('measurementData') }}</h3>
        <MeasuredKpiChart kpi-type="ee" :portfolio="portfolio" :query="{ building_id: this.building.id }" />
      </div> -->

      <Divider />

      <h3>{{ $t('reductionPaths') }}</h3>

      <!-- Projections 1 -->
      <div class="card">
        <ProjectionChart
          :building="building"
          :portfolio="portfolio"
          :projections="['own-use']"
          default-projection="own-use"
          hide-selector
        />
      </div>

      <!-- Projections 2 -->
      <div class="card">
        <ProjectionChart
          :building="building"
          :portfolio="portfolio"
          :projections="['own-use']"
          default-projection="own-use"
          hide-selector
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import Modal from '@/components/shared/Modal.vue'
import KpiBox from '@/components/shared/kpi/KpiBox.vue'
import DoughnutChart from '@/components/shared/charts/DoughnutChart.vue'
import ProjectionChart from '@/components/building/kpi/ProjectionChart.vue'
import Divider from '@/components/cui/data-display/Divider.vue'

// Old: to be removed
// import MeasuredKpiChart from '@/components/report/charts/MeasuredKpiChart.vue'

export default {
  mixins: [
    //
    ChartOptionsMixin,
  ],

  components: {
    Modal,
    KpiBox,
    DoughnutChart,
    ProjectionChart,
    Divider,

    // Old: to be removed
    // MeasuredKpiChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    kpiData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        // cutout: '60%',
        scales: {
          xAxis: {
            display: false,
          },
          yAxis: {
            display: false,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            display: true,
            labels: {
              font: {
                size: 12,
                weight: '500',
                lineHeight: 1,
              },
              boxWidth: 20,
              padding: 5,
            },
          },
          tooltip: {
            callbacks: {
              label: (item) => this.getTooltipLabel(item, { precision: 1, unit: 'MWh', label: item.label }),
            },
          },
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-pv-kpi-modal {
  display: flex;
  flex-direction: column;

  & .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);

    & .cards-row {
      display: flex;
      flex-direction: row;
      flex: 1;
    }

    & .card {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .kpi-values {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xs);
      flex: 1;
      text-align: center;
    }

    & .selectors {
      width: 100%;
      display: flex;
    }

    & .pv-share-chart,
    & .measurement-data-chart {
      flex: 1;
    }
  }
}
</style>
