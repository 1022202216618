<i18n>
{
  "de": {
    "elHeader": "Photovoltaik",
    "noPv": "Keine PV-Anlage vorhanden"
  }
}
</i18n>

<template>
  <DetailListItem
    class="c-current-state-photovoltaic-summary-row"
    :title="$t('elHeader')"
    :expandable="photovoltaicSummary !== null"
    @expand="onModalOpen"
  >
    <div class="details">
      <span v-if="photovoltaicSummary" class="pill">{{ photovoltaicSummary }}</span>
      <span v-else class="no-pv">{{ $t('noPv') }}</span>
    </div>

    <template #modal>
      <Modal v-if="isModalOpen" wide @close="onModalClose">
        <template #header>
          <h2>{{ $t('elHeader') }}</h2>
        </template>
        <ViewPv :building="building" />
      </Modal>
    </template>
  </DetailListItem>
</template>

<script>
import ModalMixin from '@/components/shared/ModalMixin.vue'

import Modal from '@/components/shared/Modal.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import ViewPv from '@/components/building/view/ViewPv.vue'

export default {
  mixins: [
    //
    ModalMixin,
  ],

  components: {
    Modal,
    DetailListItem,
    ViewPv,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    photovoltaicSummary() {
      const photovoltaicSummaryElement = this.building.current_state.pv.sections.reduce((c, pv) => c + pv.capacity, 0)

      return photovoltaicSummaryElement ? `${photovoltaicSummaryElement} kWp` : null
    },
  },
}
</script>

<style lang="scss" scoped>
.c-current-state-photovoltaic-summary-row {
  & .details {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--spacing-xxs);
    width: 100%;
  }

  & .pill {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    padding: 0 var(--spacing-s);
    border: var(--box-border);
    border-radius: 13px;
    height: 24px;
    font-size: 14px;

    & > span {
      display: inline-block;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  & .no-pv {
    opacity: 0.5;
  }
}
</style>
