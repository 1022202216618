<i18n>
  {
    "de": {
      "title": "Photovoltaik",
      "pvProduction": "PV-Produktion",
      "total": "Absolut",
      "share": "Anteil",
      "type": {
        "el_ownuse": "PV-Eigenverbrauch",
        "el_feedin": "PV-Rückspeisung"
      }
    }
  }
  </i18n>

<template>
  <KpiOverviewCard
    class="c-pv-kpi-overview"
    :title="$t('title')"
    titleIcon="cards/pv"
    :show-details="building?.kpi?.el_production > 0"
    section="pv"
    @details-click="onModalOpen()"
  >
    <template #values>
      <KpiBox
        :label="$t('pvProduction')"
        :value="formatNumberWithThreshold(building?.kpi?.el_production / 1000, 2, 1, 1)"
        valueUnit="MWh"
      />
    </template>
    <template v-if="building?.kpi?.el_production > 0" #chart>
      <DoughnutChart
        :chart-data="processedChartData"
        :options="chartOptions"
        :expandable="false"
        class="pv-share-chart"
      />
    </template>

    <template v-if="building?.kpi?.el_production > 0" #table>
      <DetailList has-header>
        <!-- Headers -->
        <template #header>
          <div class="name" />
          <HeaderEntry :title="$t('total')" unit="MWh" align-right />
          <HeaderEntry :title="$t('share')" unit="%" align-right />
        </template>

        <!-- Rows, data -->
        <template>
          <li v-for="item in tableData" :key="item.indicator" class="bold">
            <div class="name">
              <span>
                {{ $t(`type.${item.indicator}`) }}
              </span>
            </div>
            <div class="align-right">
              <span>
                {{ formatNumberWithThreshold(item.total, 2, 1, 1) }}
              </span>
            </div>
            <div class="align-right">
              <span>
                {{ formatNumberWithThreshold(item.share, 2, 1, 1) }}
              </span>
            </div>
          </li>
        </template>
      </DetailList>
    </template>

    <template v-if="building?.kpi?.el_production > 0" #modal>
      <PvKpiModal
        v-if="modalOpen"
        :building="building"
        :portfolio="portfolio"
        :chart-data="processedChartData"
        :kpi-data="tableData"
        @close="onModalClose()"
      />
    </template>
  </KpiOverviewCard>
</template>

<script>
import _ from 'lodash'

import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import KpiOverviewCard from '@/components/shared/kpi/KpiOverviewCard.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'
import KpiBox from '@/components/shared/kpi/KpiBox.vue'
import DoughnutChart from '@/components/shared/charts/DoughnutChart.vue'
import PvKpiModal from '@/components/building/kpi/PvKpiModal.vue'
import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [
    //
    ChartOptionsMixin,
  ],

  components: {
    KpiBox,
    DoughnutChart,
    KpiOverviewCard,
    DetailList,
    HeaderEntry,
    PvKpiModal,
  },

  props: {
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        // cutout: '60%',
        layout: {
          padding: {
            left: 15,
            right: 15,
            top: 15,
            bottom: 15,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            display: false,
            labels: {
              font: {
                size: 12,
                weight: '500',
                lineHeight: 1,
              },
              boxWidth: 20,
              padding: 5,
            },
          },
          tooltip: {
            padding: 6,
            cornerRadius: 3,
            bodyFont: {
              size: 14,
            },
            callbacks: {
              label: (item) => this.getSlicedTooltipLabel(item, { precision: 1, unit: 'MWh', label: item.label }),
            },
          },
        },
      }
    },

    processedChartData() {
      return {
        labels: this.tableData.map((item) => this.$t(`type.${item.indicator}`)),
        datasets: [
          {
            data: this.tableData.map((item) => item.total),
            hoverOffset: 4,
            backgroundColor: this.tableData.map((item) => colorPalettes.pvColors[item.indicator]),
          },
        ],
      }
    },

    tableData() {
      return _.map(['el_ownuse', 'el_feedin'], (indicator) => {
        const value = this.building.kpi?.[indicator]
        const hasValue = value !== undefined && value !== null
        const totalValue = hasValue ? value / 1000 : null
        const shareValue = totalValue !== null ? (value / this.building.kpi.el_production) * 100 : null

        return {
          indicator,
          total: totalValue || '-',
          share: shareValue || '-',
        }
      })
    },
  },

  methods: {
    onModalOpen() {
      this.modalOpen = true
    },

    onModalClose() {
      this.modalOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.c-pv-kpi-overview {
  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .name {
    flex: 2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .bold {
    font-weight: 600;
  }
}
</style>
