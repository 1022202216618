<template>
  <textarea :value="value" @input="updateValue($event.target.value)" @change="updateValue($event.target.value)" />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },

  mounted() {
    // Initialize height to the scroll height
    this.$nextTick(() => {
      this.$el.style.height = this.$el.scrollHeight + 'px'
      this.$el.style.overflowY = 'hidden'
    })

    // Add event listener for input
    this.$el.addEventListener('input', this.adjustHeight)
  },

  beforeDestroy() {
    // Remove event listener
    this.$el.removeEventListener('input', this.adjustHeight)
  },

  methods: {
    updateValue(value) {
      this.$emit('input', value)
    },

    adjustHeight() {
      this.$el.style.height = 'auto'
      this.$el.style.height = this.$el.scrollHeight + 'px'
    },
  },
}
</script>
