<i18n>
{
  "de": {
    "yLabels": {
      "ee": "Endenergie (kWh/m²)",
      "s12e": "Scope 1-2 Emissionen (kg CO₂e/m²)",
      "s123e": "Scope 1-3 Emissionen (kg CO₂e/m²)",
      "per": "Primärenergie (erneuerbar) (kWh/m²)"
    },
    "units": {
      "ee": "kWh/m²",
      "s12e": "kg CO₂e/m²",
      "s123e": "kg CO₂e/m²",
      "per": "kWh/m²"
    },
    "modelLabel": "Modell"
  }
}
</i18n>

<template>
  <AsyncContentContainer :content-state="contentState" class="c-kpi-by-carrier-chart">
    <template #default>
      <FaultsContainer :faults="projectionsByCarrier.faulty" :portfolio="portfolio" />
      <BarChart :chart-data="chartData" :options="options" exportable />
    </template>
  </AsyncContentContainer>
</template>

<script>
import { mapGetters } from 'vuex'

import compassApi from '@/services/compass-api.js'
import colorPalettes from '@/services/color-palettes.js'

import AsyncContentMixin from '@/components/shared/AsyncContentMixin.vue'
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer.vue'
import FaultsContainer from '@/components/report/charts/FaultsContainer.vue'
import BarChart from '@/components/shared/charts/BarChart.vue'

export default {
  mixins: [
    //
    AsyncContentMixin,
    ChartOptionsMixin,
  ],

  components: {
    BarChart,
    AsyncContentContainer,
    FaultsContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    scenario: {
      type: Object,
      required: true,
    },
    kpiType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      projectionsByCarrier: null,
      legendItemsHidden: [],
    }
  },

  computed: {
    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    sustainabilityIndicator() {
      return this.__getEnhancedSustainabilityIndicator(this.kpiType)
    },

    yLabel() {
      if (this.getIndicatorName === this.kpiType) {
        return this.$t(`yLabels.${this.kpiType}`)
      }
      return `${this.getIndicatorName} (${this.specificUnit})`
    },

    getIndicatorName() {
      if (!this.sustainabilityIndicator) {
        return this.kpiType
      }
      return this.sustainabilityIndicator.name
    },

    specificUnit() {
      if (this.sustainabilityIndicator === undefined) {
        return this.kpiType
      }
      if (this.sustainabilityIndicator === null) {
        return this.$t(`units.${this.kpiType}`)
      }
      return this.sustainabilityIndicator.getSpecificTargetUnit()
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxis: {
            beginAtZero: true,
            title: {
              display: true,
              text: this.yLabel,
            },
          },
        },
        interaction: {
          mode: 'x',
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (items) =>
                this.getTooltipTitleWithTotal(items, {
                  title: this.$t('modelLabel'),
                  precision: 1,
                  unit: this.specificUnit,
                }),
              label: (item) => this.getTooltipLabelWithPercentage(item, { unit: this.specificUnit }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            reverse: false,
            labels: {
              padding: 15,
              generateLabels: this.generateLegendLabels,
            },
            onClick: (event, legendItem) => {
              this.onLegendItemClick(event.chart, event, legendItem)
            },
          },
        },
      }
    },

    chartData() {
      let datasets = []
      Object.keys(this.projectionsByCarrier.projections).forEach((carrierName, i) => {
        const energyCarrier = this.portfolio.energy_carriers.find((ec) => ec.name === carrierName)
        if (energyCarrier) {
          datasets.push({
            carrierCode: carrierName,
            label: energyCarrier.default ? this.$t(`_energyCarriers.${energyCarrier.name}`) : energyCarrier.name,
            data: this.projectionsByCarrier.projections[carrierName].value.map(
              (v, i) => v / this.projectionsByCarrier.projections[carrierName].area[i] || 0
            ),
            stack: 'Calculated',
          })
        }
      })
      // Sort the datasets by the value of the current year (largest to smallest)
      const currentYear = new Date().getFullYear()
      const currentYearIndex = this.projectionsByCarrier.years.indexOf(currentYear)
      const currentYearTotal = datasets.reduce((acc, dataset) => acc + dataset.data[currentYearIndex] || 0, 0)
      const dataByYearsTotals = this.projectionsByCarrier.years.map((year, i) => {
        let yearData = 0
        Object.values(this.projectionsByCarrier.projections).forEach((projection) => {
          yearData += projection.value[i]
        })
        return yearData
      })
      const firstYearWithDataIndex = dataByYearsTotals.findIndex((total) => total > 0)
      if (currentYearTotal === 0) {
        datasets.sort((a, b) => b.data[firstYearWithDataIndex] - a.data[firstYearWithDataIndex])
      } else {
        datasets.sort((a, b) => b.data[currentYearIndex] - a.data[currentYearIndex])
      }
      // Add background color to the datasets
      datasets.forEach((dataset, i) => {
        let backgroundColor = colorPalettes.defaultEnergyCarrierColors[dataset.carrierCode]
        if (backgroundColor === undefined) {
          backgroundColor = colorPalettes.energyCarrierColors[i % colorPalettes.energyCarrierColors.length]
        }
        dataset.backgroundColor = backgroundColor
      })
      return {
        labels: this.projectionsByCarrier.years,
        datasets: datasets,
      }
    },
  },

  watch: {
    portfolio() {
      this.refreshContent()
    },
    scenario() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    async loadContentHandler(signal) {
      const query = JSON.stringify(this.query)
      this.projectionsByCarrier = await compassApi.call(
        `/projections/${this.portfolio.id}/${this.kpiType}/by_carrier/${this.scenario.id}?query=${query}`,
        'GET',
        null,
        signal
      )
    },

    generateLegendLabels() {
      const carrierCodes = this.chartData.datasets.map((d) => d.carrierCode)
      const carrierCodesWithoutDuplicates = [...new Set(carrierCodes)]
      return carrierCodesWithoutDuplicates
        .filter((c) => c !== 'ELECTRICITY')
        .map((carrierName) => {
          const energyCarrier = this.portfolio.energy_carriers.find((ec) => ec.name === carrierName)
          let fillStyle = colorPalettes.defaultEnergyCarrierColors[carrierName]
          if (fillStyle === undefined) {
            fillStyle = this.chartData.datasets.filter((c) => c.carrierCode === carrierName)[0].backgroundColor
          }
          return {
            carrierCode: carrierName,
            text: energyCarrier.default ? this.$t(`_energyCarriers.${energyCarrier.name}`) : energyCarrier.name,
            fillStyle: fillStyle,
            hidden: this.legendItemsHidden.includes(carrierName),
          }
        })
    },

    onLegendItemClick(chart, e, legendItem) {
      if (this.legendItemsHidden.includes(legendItem.carrierCode)) {
        this.legendItemsHidden = this.legendItemsHidden.filter((l) => l !== legendItem.carrierCode)
      } else {
        this.legendItemsHidden.push(legendItem.carrierCode)
      }
      for (let dataIdx = 0; dataIdx < chart.data.datasets.length; dataIdx++) {
        if (chart.data.datasets[dataIdx].carrierCode === legendItem.carrierCode) {
          let meta = chart.getDatasetMeta(dataIdx)
          meta.hidden = this.legendItemsHidden.includes(legendItem.carrierCode)
        }
      }
      chart.update()
    },
  },
}
</script>

<style lang="scss">
.c-kpi-by-carrier-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
