<script>
import BaseLayer from '@/components/building/map/BaseLayer.vue'

export default {
  components: {
    BaseLayer,
  },

  extends: BaseLayer,

  computed: {
    sourceConfig() {
      let features = []
      if (this.building.energy_grids.state === 'OK') {
        for (let i = 0; i < this.building.energy_grids.energy_grids.length; i++) {
          let grid = this.building.energy_grids.energy_grids[i]
          if (grid.geometry.type === 'Polygon') {
            features.push({
              id: i,
              type: 'Feature',
              geometry: grid['geometry'],
              properties: grid,
            })
          }
        }
      }
      return {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: features,
        },
      }
    },

    layerConfig() {
      return {
        type: 'fill',
        source: this.sourceId,
        paint: {
          'fill-opacity': 0.2,
          'fill-color': [
            'match',
            ['get', 'status'],
            'EVALUATION',
            '#eeff77',
            'PLANNED',
            '#ddff88',
            'EXISTING',
            '#aaff99',
            'GAS_RETREAT',
            '#ffff00',
            /* other */ '#ccc',
          ],
        },
      }
    },
  },
}
</script>
