<i18n>
{
  "de": {
    "chartsButton": "Kennzahlen",
    "countButton": "Anzahl",
    "byAreaButton": "Fläche"
  }
}
</i18n>

<template>
  <div class="c-building-map-and-kpis" :class="{ 'c-building-map-and-kpis--horizontal': layout === 'horizontal' }">
    <!-- Map -->
    <BuildingMapContainer :building="building" :layout="layout" />

    <!-- KPIs (Kennzahlen) -->
    <BuildingKpis :building="building" :chart-data="chartData" :layout="layout" />
  </div>
</template>

<script>
import BuildingMapContainer from '@/components/building/map/BuildingMapContainer.vue'
import BuildingKpis from '@/components/building/kpi/BuildingKpis.vue'

export default {
  components: {
    BuildingMapContainer,
    BuildingKpis,
  },

  props: {
    building: {
      type: Object,
      // required: true,
    },
    layout: {
      type: String, // 'horizontal' or 'vertical'
      default: 'vertical',
    },
    chartData: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.c-building-map-and-kpis {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & > div {
    flex: 1;
    display: block;
    width: 100%;
  }

  @media screen and (max-width: 1280px) {
    border-bottom: 1px solid #eee;
    overflow-y: auto;
  }

  &.c-building-map-and-kpis--horizontal {
    display: none;
    min-height: initial;
    max-height: initial;
    width: 100svw;
    height: 420px;
    margin: 0 -16px 16px;
    overflow: hidden;

    @media screen and (max-width: 1280px) {
      display: flex;
      flex-direction: row;

      & > div {
        display: block;
        width: 100%;
        height: 100%;
      }

      & > div:first-child {
        border-right: 1px solid #eee;
      }
    }
  }
}
</style>
