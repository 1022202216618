<template>
  <div :class="{ 'cui-card-header': true, 'cui-card-header--collapsed': isCollapsed }">
    <div class="cui-card-header__title">
      <img v-if="icon" :src="iconSrc" :alt="title" class="cui-card-header__icon" />
      <slot name="prefix" class="cui-card-header__prefix"></slot>
      <slot name="title">
        <span v-if="title">
          {{ title }}
        </span>
      </slot>
      <span
        v-if="isCollapsible"
        class="cui-card-header__collapse-icon"
        :class="{ 'cui-card-header__collapse-icon--collapsed': isCollapsed }"
        >▾</span
      >
      <slot name="suffix" class="cui-card-header__suffix"></slot>
    </div>
    <slot name="action" class="cui-card-header__action"></slot>
  </div>
</template>

<script>
export default {
  name: 'CardHeader',

  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },

  computed: {
    iconSrc() {
      // If full path with extension used
      if (/\.[a-zA-Z0-9]+$/.test(this.icon)) return this.icon
      // If short name used
      else return `/icons/${this.icon}.svg`
    },

    isCollapsible() {
      return this.$parent.$props.collapsible
    },

    isCollapsed() {
      return this.$parent.$data.isCollapsed
    },
  },
}
</script>

<style lang="scss" scoped>
.cui-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-xs);
  width: 100%;
  height: 64px;
  padding: 20px 12px;
  border-bottom: var(--box-border);
  border-radius: var(--box-radius) var(--box-radius) 0 0;
  background-color: #fff;

  &:hover {
    cursor: pointer;

    & .cui-card-header__collapse-icon {
      opacity: 1;
    }
  }

  &.cui-card-header--collapsed {
    border-radius: var(--box-radius);
    border-bottom: 1px solid transparent;
  }

  & .cui-card-header__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-xs);
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  & .cui-card-header__icon {
    pointer-events: none;
  }

  & .cui-card-header__collapse-icon {
    margin: 0 0 0 4px;
    opacity: 0;
    transform: translate(0, -1px);
    transition: opacity 0.2s ease, transform 0.2s ease;

    &.cui-card-header__collapse-icon--collapsed {
      transform: rotate(-90deg) translate(-1px, -2px);
      opacity: 1;
    }
  }
}
</style>
