<template>
  <v-select v-if="edit" v-model="model" clearable :options="gridOptions" :reduce="(t) => t.id" @input="onInput" />
  <span v-else
    ><span v-if="selectedLabel">{{ selectedLabel }}</span></span
  >
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    gridOptions: {
      type: Array,
    },
    edit: {
      type: Boolean,
    },
  },

  data() {
    return {
      model: null,
    }
  },

  computed: {
    selectedLabel() {
      const selectedGrid = this.gridOptions.find((o) => o.id === this.model)
      return selectedGrid && selectedGrid.label
    },
  },

  watch: {
    value() {
      this.model = this.value
    },
  },

  created() {
    this.model = this.value
  },

  methods: {
    onInput() {
      this.$emit('input', this.model)
    },
  },
}
</script>
