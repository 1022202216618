<i18n>
  {
    "de": {
      "tarifCategoryTitle": "BFE-Verbraucherprofil",
      "gridTarifTitle": "Netznutzung",
      "energyTarifTitle": "Energie",
      "taxesTarifTitle": "Steuern",
      "kevTarifTitle": "KEV",
      "totalTitle": "Total",
      "userDefined": "Benutzer definiert",
      "cancelEdit": "Abbrechen"
    }
  }
  </i18n>

<template>
  <div class="c-edit-electricity-grid-tariff">
    <FormRow v-if="isEditing" :label="$t('tarifCategoryTitle')">
      <div class="select-field">
        <Select
          id="tariff-category-options"
          v-model="model.category"
          :options="tariffCategoryOptions"
          :clearable="false"
          :reduce="(t) => t.id"
          class="select-width"
          @input="updateModel"
        />
      </div>
    </FormRow>
    <DetailList has-header class="grid-tarifs-table">
      <template #header>
        <span v-if="!isEditing">{{ $t('tarifCategoryTitle') }}</span>
        <span class="align-right">{{ $t('gridTarifTitle') }}</span>
        <span class="align-right">{{ $t('energyTarifTitle') }}</span>
        <span class="align-right">{{ $t('taxesTarifTitle') }}</span>
        <span class="align-right">{{ $t('kevTarifTitle') }}</span>
        <span class="align-right">{{ $t('totalTitle') }}</span>
        <span :class="isEditing ? 'action-column-2' : 'action-column-1'"></span>
      </template>
      <li v-if="!isEditing">
        <span>{{ model.category === 'CUSTOM' ? this.$t('userDefined') : model.category }}</span>
        <span class="align-right">{{ formatNumber(model.gridTariff, 1) }} Rp./kWh</span>
        <span class="align-right">{{ formatNumber(model.energyTariff, 1) }} Rp./kWh</span>
        <span class="align-right">{{ formatNumber(model.taxes, 1) }} Rp./kWh</span>
        <span class="align-right">{{ formatNumber(model.kev, 1) }} Rp./kWh</span>
        <span class="align-right">{{ formatNumber(totalValue, 1) }} Rp./kWh</span>
        <ListButtonWrapper v-if="getBuildingPermission('EDIT_TARIFFS')" class="action-column-1">
          <ListEditButton @click="onEdit()" />
        </ListButtonWrapper>
      </li>
      <li v-else>
        <div>
          <NumericInput
            v-model="model.gridTariff"
            noFormatting
            :edit="isEditing"
            :units="'Rp./kWh'"
            @validation="onValidation($event, 'gridTariff')"
          />
        </div>
        <div class="align-right">
          <NumericInput
            v-model="model.energyTariff"
            noFormatting
            :edit="isEditing"
            :units="'Rp./kWh'"
            @validation="onValidation($event, 'energyTariff')"
          />
        </div>
        <div class="align-right">
          <NumericInput
            v-model="model.taxes"
            noFormatting
            :edit="isEditing"
            :units="'Rp./kWh'"
            @validation="onValidation($event, 'taxes')"
          />
        </div>
        <div class="align-right">
          <NumericInput
            v-model="model.kev"
            noFormatting
            :edit="isEditing"
            :units="'Rp./kWh'"
            @validation="onValidation($event, 'kev')"
          />
        </div>
        <span class="align-right">{{ totalValue }} Rp./kWh</span>
        <ListButtonWrapper class="action-column-2">
          <ListSaveButton :disabled="!isValid || (!isDirty && !isCategoryChanged)" @click="onSave" />
          <ListCancelButton @click="onCloseEdit" />
        </ListButtonWrapper>
      </li>
    </DetailList>
    <ListActionFeedback :message="formState.error?.message || formState.error" @close="onFormEvent('clear-error')" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import FormRow from '@/components/shared/forms/FormRow.vue'
import DetailList from '@/components/shared/lists/DetailList'
import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import ListSaveButton from '@/components/shared/lists/ListSaveButton.vue'
import ListCancelButton from '@/components/shared/lists/ListCancelButton.vue'
import Select from '@/components/shared/forms/Select.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import ListActionFeedback from '@/components/shared/lists/ListActionFeedback.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    DetailList,
    ListButtonWrapper,
    ListEditButton,
    ListCancelButton,
    ListSaveButton,
    FormRow,
    Select,
    NumericInput,
    ListActionFeedback,
  },

  props: {
    buildingId: {
      type: Number,
      required: true,
    },
    currentCategory: {
      type: String,
      required: true,
    },
    gridTariffsData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    currentTariffData() {
      return this.gridTariffsData.find((v) => v.category === this.currentCategory)
    },

    selectedTariffData() {
      return this.gridTariffsData.find((v) => v.category === this.model.category)
    },

    tariffCategoryOptions() {
      return this.gridTariffsData.map((v) => {
        return {
          id: v.category,
          label: v.category === 'CUSTOM' ? this.$t('userDefined') : v.category,
        }
      })
    },

    totalValue() {
      const sum = this.model.gridTariff + this.model.energyTariff + this.model.taxes + this.model.kev
      return isNaN(sum) ? '-' : this.formatNumber(sum, 1)
    },

    isCategoryChanged() {
      return this.model.category !== this.currentCategory
    },

    isDirty() {
      return (
        this.model.gridTariff !== this.selectedTariffData.grid_tarif ||
        this.model.energyTariff !== this.selectedTariffData.energy_tarif ||
        this.model.taxes !== this.selectedTariffData.taxes ||
        this.model.kev !== this.selectedTariffData.kev
      )
    },
  },

  watch: {
    gridTariffsData() {
      this.resetModel()
    },

    isEditing() {
      this.resetModel()
    },
  },

  methods: {
    ...mapActions({
      updateBuildingGridTariff: 'building/updateBuildingElectricityGridTariff',
    }),

    resetModel() {
      this.model = {
        category: this.currentTariffData.category,
        gridTariff: this.currentTariffData.grid_tarif,
        energyTariff: this.currentTariffData.energy_tarif,
        taxes: this.currentTariffData.taxes,
        kev: this.currentTariffData.kev,
      }
    },

    updateModel() {
      const {
        category,
        grid_tarif: gridTariff,
        energy_tarif: energyTariff,
        taxes,
        kev,
      } = this.gridTariffsData.find((v) => v.category === this.model.category)

      this.model = {
        category,
        gridTariff,
        energyTariff,
        taxes,
        kev,
      }
    },

    onEdit() {
      this.model = { ...this.data }
      this.onFormEvent('edit')
    },

    onSave() {
      this.onFormEvent('save')
    },

    async saveHandler(model) {
      // CUSTOM if values are changed, otherwise use the selected category
      const category = this.isDirty ? 'CUSTOM' : this.model.category

      await this.updateBuildingGridTariff({
        id: this.buildingId,
        category,
        customValues: this.isDirty
          ? {
              category,
              grid_tarif: model.gridTariff,
              energy_tarif: model.energyTariff,
              taxes: model.taxes,
              kev: model.kev,
              total: model.gridTariff + model.energyTariff + model.taxes + model.kev,
            }
          : null,
      })
    },

    onCloseEdit() {
      this.onFormEvent('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
.c-edit-electricity-grid-tariff {
  & .select-width {
    min-width: 170px;
    width: 170px;
  }

  & .select-field {
    max-width: 300px;
    display: inline-block;
  }

  & .action-column-1 {
    max-width: 50px;
  }

  & .action-column-2 {
    max-width: 100px;
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .error-message {
    color: var(--error-color);
  }
}
</style>
