<i18n>
{
  "de": {
    "detailsUrlLink": "Details",
    "heatSourceTitle": "Wärmequelle",
    "operatorTitle": "Betreiber",
    "ghgFactorTitle": "Scope 1-2 EF",
    "peFactorTitle": "Primärenergie (PEF)",
    "statusTitle": "Status",
    "distanceTitle": "Distanz",
    "detailsUrlTitle": "Quelle"
  }
}
</i18n>

<template>
  <MapboxPopup :coordinates="coordinates" :showed="showed" anchor="top" @close="onPopupClose">
    <div class="energy-grid-popup">
      <template v-if="feature !== undefined">
        <div class="title">{{ feature.properties.name }}</div>
        <div class="two-cols">
          <span>{{ $t('operatorTitle') }}</span>
          <span v-if="feature.properties.operator !== 'null'">{{ feature.properties.operator }}</span>
          <span v-else>-</span>

          <span>{{ $t('heatSourceTitle') }}</span>
          <span v-if="feature.properties.heatsource !== 'null'">{{ feature.properties.heatsource }}</span>
          <span v-else>-</span>

          <span>{{ $t('statusTitle') }}</span>
          <TextWithIcon
            v-if="feature.properties.status"
            :icon="getIcon(feature.properties.status)"
            :text="$t(`_gridAvailability.${feature.properties.status}`)"
          />
          <span>{{ $t('distanceTitle') }}</span>
          <span>{{ Math.ceil(feature.properties.distance / 10) * 10 }} m</span>

          <span>{{ $t('ghgFactorTitle') }}</span>
          <span v-if="feature.properties.ghg_factor !== 'null'"
            >{{ formatNumber(feature.properties.ghg_factor, 0) }} g/kWh</span
          >
          <span v-else>-</span>

          <span>{{ $t('peFactorTitle') }}</span>
          <span v-if="feature.properties.pe_factor !== 'null'">{{
            formatNumber(feature.properties.pe_factor, 2)
          }}</span>
          <span v-else>-</span>

          <span>{{ $t('detailsUrlTitle') }}</span>
          <span>
            <span v-if="feature.properties.data_source !== 'null'">{{ feature.properties.data_source }}</span>
            &nbsp;<a
              v-if="feature.properties.detailsurl !== 'null'"
              target="_blank"
              :href="feature.properties.detailsurl"
              >{{ $t('detailsUrlLink') }}</a
            >
          </span>
        </div>
      </template>
    </div>
  </MapboxPopup>
</template>

<script>
import MapboxPopup from '@/components/shared/MapboxPopup.vue'
import TextWithIcon from '@/components/shared/TextWithIcon.vue'

export default {
  components: {
    MapboxPopup,
    TextWithIcon,
  },

  props: {
    feature: {
      type: Object,
    },
    coordinates: {
      type: Array,
      required: true,
    },
    showed: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    onPopupClose(event) {
      this.$emit('close', event)
    },

    getIcon(status) {
      let icons = {
        HEAT_SOURCE: 'info-circle',
        HEAT_POTENTIAL: 'info-circle',
        GAS_RETREAT: 'x-circle',
        EXISTING: 'check-circle',
        PLANNED: 'alert-triangle',
        EVALUATION: 'alert-triangle',
        NONE: 'x-circle',
        ERROR: 'info-circle',
        UNKNOWN: 'help-circle',
      }
      return icons[status]
    },
  },
}
</script>

<style>
.energy-grid-popup {
  min-width: 400px;
}
.energy-grid-popup .two-cols {
  display: grid;
  grid-template-columns: 130px 1fr;
}

.energy-grid-popup .title {
  font-weight: 600;
  margin-bottom: var(--spacing-m);
}
</style>
