<i18n>
{
  "de": {
    "pvPotentialHeader": "Solarstrahlung",
    "noGisData": "Keine GIS-Daten verfügbar",
    "dataWarningTitle": "Die Daten der Dach- und Fassadenflächen sind veraltet. Falls die Liegenschaft neu berechnet wird, besteht die Möglichkeit, dass Fassadenflächen fälschlicherweise nicht berücksichtigt werden. Um dies zu beheben fragen Sie sie hier neu ab."
  }
}
</i18n>

<template>
  <DetailListItem class="pv-potential-row" :title="$t('pvPotentialHeader')" expandable @expand="onModalOpen">
    <div v-if="building.roof.state == 'OK'" class="potential">{{ formatNumber(totalIrradiation, 0) }} MWh</div>
    <div v-else class="missing-data">
      {{ $t(`_roofState.${building.roof.state}`) }}
    </div>
    <template #modal>
      <PvPotentialModal v-if="modalOpen" :building="building" @close="onModalClose" />
    </template>
  </DetailListItem>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import PvPotentialModal from '@/components/building/feasibility/PvPotentialModal.vue'

export default {
  components: {
    DetailListItem,
    PvPotentialModal,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  computed: {
    totalIrradiation() {
      const roofIrradiation = this.building.roof.roof_tiles.filter(t => t.active).reduce((pv, cv) => pv + cv.irradiation * cv.area, 0)
      const fassadeIrradiation = this.building.roof.fassade_tiles.filter(t => t.active).reduce((pv, cv) => pv + cv.irradiation * cv.area, 0)
      return (roofIrradiation + fassadeIrradiation) / 1000
    },
  },

  methods: {
    onModalOpen() {
      this.modalOpen = true
    },

    onModalClose() {
      this.modalOpen = false
    },
  },
}
</script>

<style scoped>
.missing-data {
  color: var(--warning-color);
  font-size: var(--font-xs);
  display: inline;
}

.potential {
  display: inline;
}
</style>
