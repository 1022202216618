<i18n>
{
  "de": {
    "utilityTitle": "Elektrizitätsversorgung und Preise",
    "noDataMessage": "Keine Daten verfügbar",
    "feedinTarifsTitle": "Rückliefertarife",
    "gridTarifsTitle": "Stromtarife",
    "tariffCategoryTitle": "BFE-Verbraucherprofil",
    "gridTariffTitle": "Netznutzung",
    "energyTariffTitle": "Energie",
    "taxesTariffTitle": "Steuern",
    "kevTariffTitle": "KEV",
    "totalTitle": "Total",
    "downloadSheetButton": "Tarifblatt herunterladen",
    "showGridTariffTable": "Tarifblatt anzeigen",
    "hideGridTariffTable": "Tarifblatt verbergen",
    "powerTitle": "Installierte PV-Leistung",
    "energyTitle": "Vergütung Energie",
    "ecoTitle": "Vergütung Herkunftsnachweise (HKN)",
    "totalCompensationTitle": "Vergütung total",
    "powerFromLabel": "ab",
    "pvtariffSourceLabel": "Quelle Rücklieferdaten:",
    "pvtariffSourceLinkText": "VESE / pvtarif.ch",
    "pvtariffYear": "Stand",
    "elcomSourceLabel": "Quelle Stromtarife:",
    "elcomSourceLinkText": "Elektrizitätskommission (ElCom)",
    "operatorLabel": "Verteilnetzbetreiber",
    "gridOperatorCompensation": "Vergütungen Netzbetreiber"
  }
}
</i18n>

<template>
  <Modal wide class="electricity-utility-modal" @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('utilityTitle') }}</h2>
    </template>
    <div class="operator-title">
      <span>{{ $t('operatorLabel') }}</span>
      <h3>{{ building.el_utility.name }}</h3>
    </div>
    <ButtonWrapper
      v-if="building.el_utility.tariff_sheet_urls !== null && building.el_utility.tariff_sheet_urls.length > 0"
    >
      <a
        v-for="(url, idx) in building.el_utility.tariff_sheet_urls"
        :key="idx"
        target="_blank"
        class="button"
        :href="url"
        >{{ $t('downloadSheetButton') }}
        <span v-if="building.el_utility.tariff_sheet_urls.length > 1">{{ idx + 1 }}</span>
      </a>
    </ButtonWrapper>
    <h3>{{ $t('gridTarifsTitle') }}</h3>
    <EditElectricityGridTariff
      :building-id="building.id"
      :current-category="currentGridTariffCategory"
      :grid-tariffs-data="gridTariffsData"
    />
    <div v-if="gridTariffsData && gridTariffsData.length > 0">
      <ButtonWrapper>
        <button type="button" class="button" @click="onSwitchGridTariffShow()">
          {{ $t(showGridTariffTable ? 'hideGridTariffTable' : 'showGridTariffTable') }}
        </button>
      </ButtonWrapper>
      <div v-if="showGridTariffTable">
        <DetailList has-header class="grid-tariffs-table">
          <template #header>
            <span>{{ $t('tariffCategoryTitle') }}</span>
            <span class="align-right">{{ $t('gridTariffTitle') }}</span>
            <span class="align-right">{{ $t('energyTariffTitle') }}</span>
            <span class="align-right">{{ $t('taxesTariffTitle') }}</span>
            <span class="align-right">{{ $t('kevTariffTitle') }}</span>
            <span class="align-right">{{ $t('totalTitle') }}</span>
          </template>
          <li v-for="(tariff, idx) in gridTariffsData.filter((v) => v.category !== 'CUSTOM')" :key="idx">
            <span>{{ tariff.category }}</span>
            <span class="align-right">{{ formatNumber(tariff.grid_tarif, 1) }} Rp./kWh</span>
            <span class="align-right">{{ formatNumber(tariff.energy_tarif, 1) }} Rp./kWh</span>
            <span class="align-right">{{ formatNumber(tariff.taxes, 1) }} Rp./kWh</span>
            <span class="align-right">{{ formatNumber(tariff.kev, 1) }} Rp./kWh</span>
            <span class="align-right">{{ formatNumber(tariff.total, 1) }} Rp./kWh</span>
          </li>
        </DetailList>
        <br />
      </div>
      {{ $t('elcomSourceLabel') }}
      <a href="https://www.strompreis.elcom.admin.ch/" target="_blank" class="link">{{ $t('elcomSourceLinkText') }}</a>
      ({{ $t('pvtariffYear') }} {{ building.el_utility.grid_tariffs_year }})
    </div>
    <div v-else>{{ $t('noDataMessage') }}</div>
    <br />
    <h3>{{ $t('feedinTarifsTitle') }}</h3>
    <div v-if="defaultFeedinTariffData">
      <EditElectricityFeedinTariff :building-id="building.id" :default-feedin-tariff-data="defaultFeedinTariffData" />
      <br />
    </div>
    <div class="sub-title">{{ $t('gridOperatorCompensation') }}</div>
    <div v-if="feedinTariffsData !== null && feedinTariffsData.length > 0">
      <DetailList has-header class="feedin-tariffs-table">
        <template #header>
          <span>{{ $t('powerTitle') }}</span>
          <span class="align-right">{{ $t('energyTitle') }}</span>
          <span class="align-right">{{ $t('ecoTitle') }}</span>
          <span :class="`align-right ${getBuildingPermission('EDIT_TARIFFS') && 'align-space-left'}`">{{
            $t('totalCompensationTitle')
          }}</span>
        </template>
        <li v-for="(tariff, idx) in feedinTariffsData" :key="idx">
          <span>{{ $t('powerFromLabel') }} {{ tariff.power }} kWp</span>
          <span class="align-right">{{ formatNumber(tariff.energy, 1) }} Rp./kWh</span>
          <span class="align-right">{{ formatNumber(tariff.eco, 1) }} Rp./kWh</span>
          <span :class="`align-right ${getBuildingPermission('EDIT_TARIFFS') && 'align-space-left'}`"
            >{{ formatNumber(tariff.energy + tariff.eco, 1) }} Rp./kWh</span
          >
        </li>
      </DetailList>
      <br />
      {{ $t('pvtariffSourceLabel') }}
      <a href="http://www.pvtarif.ch" target="_blank" class="link">{{ $t('pvtariffSourceLinkText') }}</a> ({{
        $t('pvtariffYear')
      }}
      {{ building.el_utility.feedin_tariffs_year }})
    </div>
    <div v-else>{{ $t('noDataMessage') }}</div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex'

import Modal from '@/components/shared/Modal'
import DetailList from '@/components/shared/lists/DetailList'
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import EditElectricityGridTariff from '@/components/building/edit/EditElectricityGridTariff.vue'
import EditElectricityFeedinTariff from '@/components/building/edit/EditElectricityFeedinTariff.vue'

export default {
  components: {
    ButtonWrapper,
    Modal,
    DetailList,
    EditElectricityGridTariff,
    EditElectricityFeedinTariff,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showGridTariffTable: false,
    }
  },

  computed: {
    ...mapGetters({
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),

    currentGridTariffCategory() {
      const storedCategory = this.building.el_utility.grid_tariff_category
      const hasCustomData = this.building.el_utility.grid_tariffs.some((v) => v.category === 'CUSTOM')

      // H4 id default category if no category is set or if custom category is set but no custom data is available
      if (!storedCategory || (storedCategory === 'CUSTOM' && !hasCustomData)) return 'H4'
      else return storedCategory
    },

    gridTariffsData() {
      return this.building.el_utility?.grid_tariffs
    },

    feedinTariffsData() {
      return this.building.el_utility.feedin_tariffs.filter((v) => v.category !== 'CUSTOM')
    },

    defaultFeedinTariffData() {
      const elUtility = this.building.el_utility
      const customTariff = elUtility.feedin_tariffs.find((v) => v.category === 'CUSTOM')
      // If custom tariff is set, return it, otherwise return the first tariff
      return customTariff || elUtility.feedin_tariffs[0]
    },
  },

  methods: {
    onSwitchGridTariffShow() {
      this.showGridTariffTable = !this.showGridTariffTable
    },
  },
}
</script>

<style lang="scss" scoped>
.electricity-utility-modal {
  & .operator-title {
    display: flex;
    align-items: center;
    gap: 100px;
  }

  & .sub-title {
    margin-bottom: var(--spacing-s);
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .align-space-left {
    margin-left: 50px;
  }

  a.link {
    text-decoration: none;
    border-bottom: 1px solid;
  }
}
</style>
