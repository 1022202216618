<i18n>
{
  "de": {
    "geothermalTitle": "Zulässigkeit Wärmenutzung",
    "ewsAdmissibility": "Erdwärmesonden",
    "gwAdmissibility": "Grundwasser",
    "ambiguousMessage": "(Nicht eindeutig, zur Sicherheit manuell überprüfen)"
  }
}
</i18n>

<template>
  <DetailListItem :title="$t('geothermalTitle')" expandable @expand="onModalOpen">
    <Box v-if="building.geothermal.state !== 'OK'" className="missing-data">
      {{ $t(`_geothermalState.${building.geothermal.state}`) }}
    </Box>
    <Box v-else className="admissibilities">
      <TextWithIcon
        :icon="ewsAdmissibilityIcon"
        :tooltipText="$t(`_admissibility.${building.geothermal.admissibilities.ews}`)"
        :text="$t('ewsAdmissibility')"
      />
      <TextWithIcon
        :icon="gwAdmissibilityIcon"
        :tooltipText="$t(`_admissibility.${building.geothermal.admissibilities.gw}`)"
        :text="$t('gwAdmissibility')"
      />
    </Box>
    <Box v-if="isAmbiguous">{{ $t('ambiguousMessage') }}</Box>
    <template #modal>
      <GeothermalModal v-if="modalOpen" :building="building" @close="onModalClose" />
    </template>
  </DetailListItem>
</template>

<script>
import Box from '@/components/cui/layout/Box.vue'

import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import TextWithIcon from '@/components/shared/TextWithIcon.vue'
import GeothermalModal from '@/components/building/feasibility/GeothermalModal.vue'

export default {
  components: {
    Box,
    DetailListItem,
    TextWithIcon,
    GeothermalModal,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  computed: {
    ewsAdmissibilityIcon() {
      let icons = {
        ADMISSIBLE: 'check-circle',
        LIMITATIONS: 'alert-triangle',
        NON_ADMISSIBLE: 'x-circle',
        BAD_GEOLOGY: 'x-circle',
        ERROR: 'info-circle',
        UNKNOWN: 'help-circle',
      }
      if (this.building.geothermal.admissibilities.ews_ambiguous) {
        icons['ADMISSIBLE'] = 'alert-triangle'
      }
      return icons[this.building.geothermal.admissibilities.ews]
    },

    gwAdmissibilityIcon() {
      let icons = {
        ADMISSIBLE: 'check-circle',
        LIMITATIONS: 'alert-triangle',
        NON_ADMISSIBLE: 'x-circle',
        BAD_GEOLOGY: 'x-circle',
        ERROR: 'info-circle',
        UNKNOWN: 'help-circle',
      }
      if (this.building.geothermal.admissibilities.gw_ambiguous) {
        icons['ADMISSIBLE'] = 'alert-triangle'
      }
      return icons[this.building.geothermal.admissibilities.gw]
    },

    isAmbiguous() {
      const admissibilities = this.building?.geothermal.admissibilities
      return admissibilities?.ews_ambiguous || admissibilities?.gw_ambiguous
    },
  },

  methods: {
    onModalOpen() {
      this.modalOpen = true
    },

    onModalClose() {
      this.modalOpen = false
    },
  },
}
</script>
<style lang="scss" scoped>
.missing-data {
  color: var(--warning-color);
  font-size: var(--font-xs);
}
.admissibilities {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-m);
}
</style>
