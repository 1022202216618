<template>
  <div :class="`cui-box ${className}`">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Box',

  props: {
    className: {
      type: String,
      default: '',
    },
  },
}
</script>
