<template>
  <v-select v-if="edit" v-model="model" clearable :options="tagOptions" :reduce="(t) => t.id" @input="onInput" />
  <span v-else-if="selectedTag">{{ selectedTag.name }}</span>
  <span v-else>-</span>
</template>

<script>
export default {
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    value: {
      type: Number,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    tagGroup: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      model: null,
    }
  },

  computed: {
    selectedTag() {
      return this.tagGroup.tags.find((t) => t.id === this.model)
    },

    tagOptions() {
      return this.tagGroup.tags.map((g) => {
        return {
          id: g.id,
          label: g.name,
        }
      })
    },
  },

  watch: {
    value() {
      this.model = this.value
    },
  },

  created() {
    this.model = this.value
  },

  methods: {
    onInput() {
      this.$emit('input', this.model)
    },
  },
}
</script>
