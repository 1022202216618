<!--
Used for a custom form component that provides v-model for a complex data structure (e.g. heaters table)
    Usage example:
    - Bind form state (isEditing), model and validation for each input field
      <NumericInput :edit="isEditing" @input="onInput" v-model="model.myDataField" @validation="onValidation('myDataField')" />

    - Override default validation (optional)
      computed: {
        isValid () {
          return this.noInvalidFields && this.model.length > 0
        }
      }
-->
<script>
export default {
  props: {
    value: {},
    edit: {
      type: Boolean,
    },
  },

  data() {
    return {
      invalidFields: [], // List of fields that failed validation
      model: null, // Temporary data model
    }
  },

  computed: {
    noInvalidFields() {
      return this.invalidFields.length === 0
    },

    // this method can be overwritten to implement custom validation logic
    isValid() {
      return this.noInvalidFields
    },

    isEditing() {
      return this.edit
    },
  },

  watch: {
    value() {
      this.resetModel()
    },
  },

  created() {
    this.resetModel()
  },

  methods: {
    resetModel() {
      if (this.value) {
        this.model = JSON.parse(JSON.stringify(this.value))
      }
    },

    // Handler for edit fields that have validation event
    onValidation(isValid, fieldName) {
      if (isValid) {
        this.invalidFields = this.invalidFields.filter((r) => r !== fieldName)
      } else {
        if (this.invalidFields.indexOf(fieldName) === -1) {
          this.invalidFields.push(fieldName)
        }
      }
      this.$emit('validation', this.isValid)
    },

    onInput() {
      this.$emit('input', this.model)
      this.$emit('validation', this.isValid)
    },
  },
}
</script>
