<template>
  <div v-if="message" :class="`c-list-action-feedback ${type}`">
    {{ message }}
    <button @click="onClose"><img src="/icons/x.svg" /></button>
  </div>
</template>

<script>
const TYPES = {
  ERROR: 'error',
}

export default {
  props: {
    type: {
      type: String,
      default: TYPES.ERROR,
    },
    message: {
      type: [String, Error],
    },
  },

  methods: {
    onClose() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.c-list-action-feedback {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-s);
  padding-right: var(--spacing-xxs);
  margin-top: var(--spacing-s);

  &.error {
    color: var(--error-color);
  }

  button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;

    img {
      width: 18px;
      opacity: 0.5;
    }
  }
}
</style>
