<i18n>
{
  "de": {
    "heatingTypeLabel": "Typ",
    "costsLabel": "Kosten *",
    "autoCostsHint": "Kosten leer lassen für automatische Kostenschätzung"
  }
}
</i18n>

<template>
  <div class="c-edit-heating-measure">
    <EditHeatersTable
      v-model="model.heaters"
      :heatersResults="heatersResults"
      :portfolio="portfolio"
      :edit="isEditing"
      @input="onHeatersChange"
      @validation="onValidation($event, 'heaters-table')"
    />
    <FormRow :label="$t('costsLabel')" class="costs-row">
      <NumericInput
        v-model="model.costs"
        :edit="isEditing"
        :min="0"
        optional
        :units="'CHF'"
        class="limit-width"
        @input="onInput"
        @validation="onValidation($event, 'heating-costs')"
      />
    </FormRow>
    <div class="hint">* {{ $t('autoCostsHint') }}</div>
  </div>
</template>

<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin.vue'

import EditHeatersTable from '@/components/building/edit/EditHeatersTable.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'

export default {
  mixins: [
    //
    ComplexFormMixin,
  ],

  components: {
    FormRow,
    EditHeatersTable,
    NumericInput,
  },

  props: {
    heatersResults: {
      type: Array,
    },
    portfolio: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.onInput()
  },

  methods: {
    onHeatersChange(heaters) {
      this.model.heaters = heaters
      this.onInput()
    },
  },
}
</script>

<style lang="scss">
.c-edit-heating-measure {
  & .costs-row {
    margin-top: var(--spacing-m);
  }

  & .limit-width {
    width: 200px;
  }
}
</style>
