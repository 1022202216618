<i18n>
{
  "de": {
    "usableFractionTitle": "Anteil nutzbar [%]",
    "remarksTitle": "Bemerkungen",
    "pvClusteringList": "Clustering-Verhalten"
  }
}
</i18n>

<template>
  <EditContainer
    class="c-edit-tile"
    :bind-form-state="formState"
    :allow-edit="getBuildingPermission('EDIT_FEASIBILITY')"
    @container="onFormEvent"
  >
    <FormRow :label="$t('usableFractionTitle')" class="usable-fraction-field">
      <NumericInput
        v-model="model.usable_fraction"
        optional
        :min="0"
        :max="100"
        :edit="isEditing"
        @validation="onValidation($event, 'usable_fraction')"
      />
    </FormRow>

    <FormRow :label="$t('remarksTitle')">
      <TextInput v-model="model.remarks" :edit="isEditing" :allow-empty="true" />
    </FormRow>

    <FormRow v-if="isRoof" :label="$t('pvClusteringList')">
      <div class="input">
        <PvClusteringListPicker
          v-model="model.pv_clustering_list"
          :edit="isEditing"
          :options="pvClusteringListOptions"
        />
      </div>
    </FormRow>
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import EditContainer from '@/components/shared/forms/EditContainer.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import PvClusteringListPicker from './PvClusteringListPicker.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    EditContainer,
    FormRow,
    NumericInput,
    TextInput,
    PvClusteringListPicker,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    feature: {
      type: Object,
      required: true,
    },
    isRoof: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      pvClusteringListOptions: [
        //
        'WHITE',
        'BLACK',
        // 'NONE'
      ],
    }
  },

  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding',
    }),

    resetModel() {
      const tile = this.isRoof
        ? this.building.feasibility.roof_tiles === null
          ? undefined
          : this.building.feasibility.roof_tiles.find((t) => t.id === this.feature.properties.id)
        : this.building.feasibility.fassade_tiles === null
        ? undefined
        : this.building.feasibility.fassade_tiles.find((t) => t.id === this.feature.properties.id)
      if (tile !== undefined) {
        this.model = { ...tile }
        if (this.model.usable_fraction !== undefined) {
          this.model.usable_fraction *= 100
        }
      } else {
        this.model = this.isRoof
          ? {
              usable_fraction: null,
              remarks: null,
              pv_clustering_list: null,
            }
          : {
              usable_fraction: null,
              remarks: null,
            }
      }
    },

    async saveHandler() {
      let newFeasibility = { ...this.building.feasibility }
      if (this.isRoof) {
        newFeasibility.roof_tiles = newFeasibility.roof_tiles.filter((t) => t.id !== this.feature.properties.id)
        let newTile = {
          id: this.feature.properties.id,
          ...this.model,
        }
        if (newTile.usable_fraction !== undefined) {
          newTile.usable_fraction /= 100
        }
        newFeasibility.roof_tiles.push(newTile)
      } else {
        newFeasibility.fassade_tiles = newFeasibility.fassade_tiles.filter((t) => t.id !== this.feature.properties.id)
        let newTile = {
          id: this.feature.properties.id,
          ...this.model,
        }
        if (newTile.usable_fraction !== undefined) {
          newTile.usable_fraction /= 100
        }
        newFeasibility.fassade_tiles.push(newTile)
      }
      await this.updateBuilding({
        id: this.building.id,
        feasibility: newFeasibility,
      })
    },
  },
}
</script>

<style lang="scss">
.c-edit-tile {
  padding: 4px 40px 0 0;

  & .two-column-row {
    display: grid;
    grid-template-columns: 150px 1fr;
    margin-bottom: 0;
    gap: 0;
    column-gap: 0;
  }

  & input,
  & .input {
    margin: 2px 0;
    width: 100%;
  }
}
</style>
