<i18n>
{
  "de": {
    "measuresTitle": "Lebenszyklus & Massnahmen",
    "addNewLabel": "Massnahme hinzufügen",
    "yearTitle": "Jahr",
    "actionsTitle": "Massnahmen",
    "ghgTitle": "Scope 1-2 Emissionen",
    "rhTitle": "Raumwärme",
    "peTitle": "Primärenergie",
    "hasBadMeasureList": "Folgende Szenarien haben eine ungültige Massnahmenliste:",
    "infoBadMeasureList": "Erklärung: Szenario editierte Massnahmen dürfen nur nach allen umgesetzten und geplanten Massnahmen auftreten."
  }
}
</i18n>

<template>
  <div id="measures" ref="measuresSection" class="c-measures-section">
    <Card collapsible section="measures">
      <!-- Header -->
      <CardHeader slot="header" :title="$t('measuresTitle')" :icon="'cards/lifecycle'" />

      <CardContent slot="content">
        <!-- Pick Scenario & Add New Measure -->
        <div
          v-sticky="isSectionVisible"
          :sticky-offset="stickyOffset"
          class="scenario-picker-wrapper"
          :class="{ 'sticky-class': isSectionVisible }"
        >
          <ScenarioPicker v-model="selectedScenario" :portfolio="portfolio" class="scenario-picker" />
          <Button
            v-if="getBuildingPermission('ADD_MEASURES')"
            icon="plus"
            :text="$t('addNewLabel')"
            @click="onAddMeasure"
          />
        </div>

        <!-- Bad Scenarios -->
        <div v-if="badScenarioList.length" class="has-bad-measure-list">
          <p>{{ $t('hasBadMeasureList') }}</p>
          <ul>
            <li v-for="(s, idx) in badScenarioList" :key="idx">{{ s }}</li>
          </ul>
          <p>{{ $t('infoBadMeasureList') }}</p>
        </div>

        <!-- Lifecycle & Measures -->
        <div class="lifecycle">
          <!-- Initial State -->
          <BuildingInitialState :building="building" :portfolio="portfolio" />

          <!-- Past Measures (COMPLETED) -->
          <template v-if="pastMeasures?.length">
            <BuildingMeasureState
              v-for="(measure, index) in pastMeasures"
              :key="`past-measure-${index}`"
              :measure="measure"
              :building="building"
              :portfolio="portfolio"
              :selected-scenario="selectedScenario"
              :last-kpi="index > 0 ? pastMeasures[index - 1].kpi : initialStateKpi"
            />
          </template>

          <!-- Current State -->
          <BuildingCurrentState
            :building="building"
            :portfolio="portfolio"
            :is-last-measure="!futureMeasures?.length"
          />

          <!-- Future Measures (PLANNED, SCENARIO_EDITED, SCENARIO) -->
          <template v-if="futureMeasures?.length">
            <BuildingMeasureState
              v-for="(measure, index) in futureMeasures"
              :key="`future-measure-${index}`"
              :measure="measure"
              :building="building"
              :portfolio="portfolio"
              :selected-scenario="selectedScenario"
              :last-kpi="index > 0 ? futureMeasures[index - 1].kpi : building.kpi"
              :is-last-measure="index === futureMeasures.length - 1"
            />
          </template>

          <!-- TODO: Remove -->
          <!-- Old: Measures -->
          <!-- <div style="margin: 20px">
            <DetailList v-if="selectedScenario && measures.length" has-header>
              <template #header>
                <span></span>
                <span>{{ $t('yearTitle') }}</span>
                <span>{{ $t('actionsTitle') }}</span>
                <span>{{ $t('ghgTitle') }}</span>
                <span>{{ $t('peTitle') }}</span>
                <span>{{ $t('rhTitle') }}</span>
                <span></span>
              </template>

              <MeasureRow
                v-for="(measure, index) in measures"
                :key="index"
                :measure="measure"
                :last-kpi="index > 0 ? measures[index - 1].kpi : building.kpi"
                :building="building"
                :portfolio="portfolio"
                :selected-scenario="selectedScenario"
              />
            </DetailList>
          </div> -->
        </div>
      </CardContent>
    </Card>

    <EditMeasureModal
      v-if="addNew"
      :grid-options="gridOptions"
      :building="building"
      :portfolio="portfolio"
      :selected-scenario="selectedScenario"
      @close="onCloseAddMeasureModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ScenarioPicker from '@/components/shared/ScenarioPicker.vue'
import EditMeasureModal from '@/components/building/measures/edit/EditMeasureModal.vue'
import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import BuildingInitialState from '@/components/building/measures/BuildingInitialState.vue'
import BuildingMeasureState from '@/components/building/measures/BuildingMeasureState.vue'
import BuildingCurrentState from '@/components/building/measures/BuildingCurrentState.vue'
import Button from '@/components/cui/inputs/Button.vue'

// TODO: Remove
// import DetailList from '@/components/shared/lists/DetailList.vue'
// import MeasureRow from '@/components/building/measures/MeasureRow.vue'

export default {
  components: {
    ScenarioPicker,
    EditMeasureModal,
    Card,
    CardHeader,
    CardContent,
    BuildingInitialState,
    BuildingMeasureState,
    BuildingCurrentState,
    Button,

    // TODO: Remove
    // DetailList,
    // MeasureRow,
  },

  props: {
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      addNew: false,
      selectedScenario: null,
      stickyOffset: `{ top: 65, bottom: 0 }`,
      isSectionVisible: false,
      scrollableParent: null,
    }
  },

  computed: {
    ...mapGetters({
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),

    badScenarioList() {
      const badScenarios = []
      this.portfolio.scenarios.forEach((s) => {
        if (!this.hasGoodMeasureOrder(this.building, s.id)) {
          badScenarios.push(s.description)
        }
      })
      return badScenarios
    },

    currentYear() {
      return new Date().getFullYear()
    },

    // Measures that are either completed, or planned but not in the future
    pastMeasures() {
      return (
        this.selectedScenario &&
        this.building.measures.filter(
          (m) =>
            (m.phase === 'COMPLETED' && m.scenario_id === null) ||
            ((m.scenario_id === this.selectedScenario.id || m.scenario_id === null) && m.year < this.currentYear)
        )
      )
    },

    // Measures that are planned in the future
    futureMeasures() {
      return (
        this.selectedScenario &&
        this.building.measures.filter(
          (m) =>
            ['PLANNED', 'SCENARIO_EDITED', 'SCENARIO'].includes(m.phase) &&
            (m.scenario_id === this.selectedScenario.id || m.scenario_id === null) &&
            m.year >= this.currentYear
        )
      )
    },

    // KPIs of the initial state must be reconstructed here for the KpiChangeModal
    // This is because the KPIs at the building root level are for the current state and not the initial state
    // This is needed only for the first COMPLETED measure because it needs the initial state KPIs for the comparison
    initialStateKpi() {
      const energyArea =
        this.building.initial_state.zones.zones.reduce((acc, z) => {
          return acc + z.energy_area.deduced
        }, 0) || 0
      const elFeedin =
        Math.abs(
          this.building.initial_state.result.energy_demand.find((demand) => demand.usage === 'FEED_IN')?.amount
        ) || 0
      const elOwnuse =
        this.building.initial_state.result.energy_demand.find((demand) => demand.carrier === 'ELECTRICITY_OWNUSE')
          ?.amount || 0
      const elProduction = elFeedin + elOwnuse

      return {
        dhw_calibration: this.building.initial_state.result.dhw_calibration,
        dhw_demand: this.building.initial_state.result.dhw_demand,
        dhw_end_energy: this.building.initial_state.result.dhw_end_energy,
        ee: this.building.initial_state.result.computations.totals.ee,
        el_demand:
          this.building.initial_state.result.computations.by_usage.GENERAL.ee +
          this.building.initial_state.result.computations.by_usage.TENANTS.ee,
        el_feedin: elFeedin,
        el_ownuse: elOwnuse,
        el_production: elProduction,
        energy_area: energyArea,
        heating_power: this.building.initial_state.result.heating_power,
        pe: this.building.initial_state.result.computations.totals.pe,
        pe_ref: this.building.initial_state.result.pe_ref,
        penr: this.building.initial_state.result.computations.totals.penr,
        per:
          this.building.initial_state.result.computations.totals.pe -
          this.building.initial_state.result.computations.totals.penr,
        rh_calibration: this.building.initial_state.result.rh_calibration,
        rh_demand: this.building.initial_state.result.rh_demand,
        rh_end_energy: this.building.initial_state.result.rh_end_energy,
        rh_limit: this.building.initial_state.result.rh_limit,
        s123e:
          this.building.initial_state.result.computations.totals.s1e +
          this.building.initial_state.result.computations.totals.s2e +
          this.building.initial_state.result.computations.totals.s3e,
        s12e:
          this.building.initial_state.result.computations.totals.s1e +
          this.building.initial_state.result.computations.totals.s2e,
        s1e: this.building.initial_state.result.computations.totals.s1e,
        s2e: this.building.initial_state.result.computations.totals.s2e,
        s3e: this.building.initial_state.result.computations.totals.s3e,
      }
    },

    gridOptions() {
      return this.portfolio.heat_grids.map((g) => {
        return {
          id: g.identifier,
          label: g.name,
        }
      })
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.scrollableParent = this.getScrollableParent(this.$refs.measuresSection)
      if (this.scrollableParent) {
        this.scrollableParent.addEventListener('scroll', this.checkVisibility)
        window.addEventListener('resize', this.checkVisibility)
        this.checkVisibility()
      }
    })
  },

  beforeDestroy() {
    if (this.scrollableParent) {
      this.scrollableParent.removeEventListener('scroll', this.checkVisibility)
      window.removeEventListener('resize', this.checkVisibility)
    }
  },

  methods: {
    //
    onAddMeasure() {
      this.addNew = true
    },

    //
    onCloseAddMeasureModal() {
      this.addNew = false
    },

    //
    hasGoodMeasureOrder(building, scenarioId) {
      const SCENARIO_SCOPED_MEASURE_TYPE = ['SCENARIO_EDITED']
      const BUILDING_SCOPED_MEASURE_TYPE = ['COMPLETED', 'PLANNED']
      let scenarioScopedHasOccurred = false
      if (building) {
        for (const measure of building.measures) {
          // building.measures.forEach(measure => {
          if (
            BUILDING_SCOPED_MEASURE_TYPE.includes(measure.phase) ||
            (measure.scenario_id === scenarioId && SCENARIO_SCOPED_MEASURE_TYPE.includes(measure.phase))
          ) {
            if (!scenarioScopedHasOccurred) {
              if (SCENARIO_SCOPED_MEASURE_TYPE.includes(measure.phase)) {
                scenarioScopedHasOccurred = true
              }
            } else {
              if (BUILDING_SCOPED_MEASURE_TYPE.includes(measure.phase)) {
                return false
              }
            }
          }
        }
      }
      return true
    },

    checkVisibility() {
      if (!this.$refs.measuresSection || !this.scrollableParent) return
      const rect = this.$refs.measuresSection.getBoundingClientRect()
      const parentRect = this.scrollableParent.getBoundingClientRect()
      // 128px is the height of the header
      this.isSectionVisible =
        rect.top < parentRect.bottom &&
        rect.bottom - 148 >= parentRect.top &&
        rect.left < parentRect.right &&
        rect.right >= parentRect.left
    },

    getScrollableParent(element) {
      if (!element) return null
      if (element.scrollHeight > element.clientHeight) {
        return element
      } else {
        return this.getScrollableParent(element.parentElement)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-measures-section {
  margin-bottom: var(--spacing-l);

  & .scenario-picker-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding: var(--spacing-s);
    border-bottom: var(--box-border);
    background-color: #fff;
    transition: opacity 0.5s ease;
    opacity: 0;

    &.sticky-class {
      opacity: 1;
    }

    & .scenario-picker {
      margin-bottom: 0;
    }
  }

  & .lifecycle {
    display: flex;
    flex-direction: column;
    background-color: #fcfcfc;
    padding: 0 0 20px;
    border-radius: 0 0 var(--box-radius) var(--box-radius);
  }

  .has-bad-measure-list {
    padding: 12px 18px;
    background: #ff6f6f;
    border: var(--box-border);
    border-radius: var(--box-radius);
    margin: var(--spacing-s);

    & ul {
      margin-top: 10px;
    }

    & li {
      margin-left: 40px;
      list-style: disc;
    }
  }
}
</style>

<style lang="scss">
.c-measures-section {
  & > .c-cui-card {
    overflow: visible;
  }
}
</style>
