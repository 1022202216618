<template>
  <svg viewBox="3 3 16.5 8.5" :height="size">
    <path
      d="m 5,7.2130144 4,-2.4000534 4,4.2214142 4,0.5172475"
      :style="`fill:none;stroke:${color};stroke-width:0.8;stroke-linecap:round;stroke-linejoin:miter;`"
    />
    <circle r="1.25" cy="7.3659778" cx="4.75" :style="`fill:${color};stroke:none;`" />
    <circle r="1.25" cy="4.9425216" cx="8.75" :style="`fill:${color};stroke:none;`" />
    <circle r="1.25" cy="8.7695303" cx="12.75" :style="`fill:${color};stroke:none;`" />
    <circle r="1.25" cy="9.5491066" cx="16.75" :style="`fill:${color};stroke:none;`" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
    },
    size: {
      type: String,
      default: '16',
    },
  },
}
</script>
