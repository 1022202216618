<i18n>
  {
    "de": {
      "meterTypeTitle": "Typ",
      "meterEnergyCarrierTitle": "Energieträger",
      "startMonthTitle": "Stichmonat Messperiode",
      "meterIdentifierTitle": "Zählernummer (optional)",
      "meteringPeriod": "Messperiode",
      "selectMeteringPeriod": "Messperiode auswählen",
      "months": {
        "1": "1 (Januar)",
        "2": "2 (Februar)",
        "3": "3 (März)",
        "4": "4 (April)",
        "5": "5 (Mai)",
        "6": "6 (Juni)",
        "7": "7 (Juli)",
        "8": "8 (August)",
        "9": "9 (September)",
        "10": "10 (Oktober)",
        "11": "11 (November)",
        "12": "12 (Dezember)"
      },
      "spans": {
        "1": "Januar bis Dezember",
        "2": "Februar bis Januar",
        "3": "März bis Februar", 
        "4": "April bis März",
        "5": "Mai bis April",
        "6": "Juni bis Mai",
        "7": "Juli bis Juni",
        "8": "August bis Juli",
        "9": "September bis August",
        "10": "Oktober bis September",
        "11": "November bis Oktober",
        "12": "Dezember bis November"
      },
      "reportingYear": "Reporting-Jahr",
      "followingYear": "Folgejahr"
    }
  }
  </i18n>

<template>
  <div class="c-edit-meter">
    <EditContainer
      :bind-form-state="formState"
      :allow-edit="getBuildingPermission('EDIT_METERS')"
      @container="onFormEvent"
    >
      <!-- Type -->
      <FormRow :label="$t('meterTypeTitle')">
        <Select
          v-if="isEditing"
          id="meter-type-options"
          v-model="model.meterType"
          class="limit-width"
          :options="typeOptions"
          :reduce="(t) => t.id"
        />
        <div v-else>
          {{ model.meterType ? $t(`_meterTypes.${model.meterType}`) : model.meterType }}
        </div>
      </FormRow>

<!-- Energy Carrier (only for END_ENERGY_HEATER) -->
      <FormRow v-if="model.meterType === 'END_ENERGY_HEATER'" :label="$t('meterEnergyCarrierTitle')">
        <Select
          v-if="isEditing && model.meterType === 'END_ENERGY_HEATER'"
          id="energy-carrier-options"
          v-model="model.energyCarrierId"
          class="limit-width"
          :options="carrierOptions"
          :reduce="(t) => t.id"
        />
        <div v-else-if="!isEditing && model.meterType === 'END_ENERGY_HEATER'">
          {{ energyCarrierIdentifier(model.energyCarrierId) }}
        </div>
        <div v-else>-</div>
      </FormRow>

      <!-- Data Point Number -->
      <FormRow :label="$t('meterIdentifierTitle')">
        <TextInput v-model="model.identifier" class="limit-width" :edit="isEditing" :allow-empty="true" />
      </FormRow>

      <!-- Metering Period -->
      <FormRow :label="$t('startMonthTitle')">
        <Select
          v-if="isEditing"
          id="starting-month-options"
          v-model="model.startingMonth"
          class="limit-width"
          :options="months"
          :reduce="(t) => t.id"
          :placeholder="$t('selectMeteringPeriod')"
        />
        <div v-else>
          {{ $t('spans.' + model.startingMonth) }}
        </div>
      </FormRow>

      <div class="metering-period">
        <!-- Period -->
        <div class="period">
          {{ $t('meteringPeriod') }}
        </div>
        <!-- Months -->
        <div class="months" :style="`margin-left: ${offset}px`">
          <div class="reporting">
            <div v-for="month in months" :key="month.id">
              {{ month.id }}
            </div>
          </div>
          <div class="following">
            <div v-for="month in months" :key="month.id">
              {{ month.id }}
            </div>
          </div>
        </div>
        <!-- Years -->
        <div class="years">
          <div class="reporting" :style="`width: ${(12 + 1 - model.startingMonth) * 40}px`">
            {{ $t('reportingYear') }}
          </div>
          <div class="following" :style="`width: ${(model.startingMonth - 1) * 40}px`">
            {{ $t('followingYear') }}
          </div>
        </div>
      </div>
    </EditContainer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'
import EditContainer from '@/components/shared/forms/EditContainer.vue'

import Select from '@/components/shared/forms/Select.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
        - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
        - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                    getBuildingPermission, getPortfolioPermission
      */
  ],

  components: {
    TextInput,
    Select,
    EditContainer,
    FormRow,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    meter: {
      type: Object,
    },
  },

  data() {
    return {
      error: null,
      pending: false,
      internalMeter: null,
      typeOptions: [
        { id: 'END_ENERGY_HEATER', label: this.$t('_meterTypes.END_ENERGY_HEATER') },
        { id: 'ELECTRICITY_TENANTS', label: this.$t('_meterTypes.ELECTRICITY_TENANTS') },
        { id: 'ELECTRICITY_GENERAL', label: this.$t('_meterTypes.ELECTRICITY_GENERAL') },
        { id: 'ELECTRICITY_PV', label: this.$t('_meterTypes.ELECTRICITY_PV') },
      ],
      months: [
        { id: 1, label: this.$t('months.1') },
        { id: 2, label: this.$t('months.2') },
        { id: 3, label: this.$t('months.3') },
        { id: 4, label: this.$t('months.4') },
        { id: 5, label: this.$t('months.5') },
        { id: 6, label: this.$t('months.6') },
        { id: 7, label: this.$t('months.7') },
        { id: 8, label: this.$t('months.8') },
        { id: 9, label: this.$t('months.9') },
        { id: 10, label: this.$t('months.10') },
        { id: 11, label: this.$t('months.11') },
        { id: 12, label: this.$t('months.12') },
      ],
    }
  },

  computed: {
    carrierOptions() {
      return this.portfolio.energy_carriers
        .filter((ec) => {
          return !['UNKNOWN'].includes(ec.name)
        })
        .map((ec) => {
          return {
            id: ec.id,
            label: ec.default ? this.$t(`_energyCarriers.${ec.name}`) : ec.name,
          }
        })
    },

    offset() {
      return this.model.startingMonth ? Number(this.model.startingMonth - 1) * 40 * -1 : 0
    },

    isValid() {
      return (
        ((this.model.meterType && this.model.meterType !== 'END_ENERGY_HEATER') ||
          (this.model.meterType === 'END_ENERGY_HEATER' && this.model.energyCarrierId)) &&
        Number(this.model.startingMonth) >= 1 &&
        Number(this.model.startingMonth) <= 12
      )
    },

    heatingOil() {
      return this.portfolio.energy_carriers.find((ec) => ec.name === 'HEATING_OIL')
    },
  },

  watch: {
    meter() {
      this.internalMeter = _.cloneDeep(this.meter)
      this.resetModel()
    },
  },

  created() {
    if (this.meter) {
      this.internalMeter = _.cloneDeep(this.meter)
    }
    if (!this.meter) {
      this.onFormEvent('edit')
    }
    this.resetModel()
  },

  methods: {
    //
    ...mapActions({
      addMeter: 'building/addMeter',
      updateMeter: 'building/updateMeter',
    }),

    //
    resetModel() {
      if (this.internalMeter) {
        this.model = {
          // Meter basic fields
          id: this.internalMeter.id,
          buildingId: this.internalMeter.building_id,
          meterType: this.internalMeter.meter_type,
          energyCarrierId: this.internalMeter.energy_carrier_id,
          identifier: this.internalMeter.identifier,
          startingMonth: this.internalMeter.start_month,
        }
      } else {
        this.model = {
          // Creating a new Meter
          buildingId: this.building.id,
          meterType: null,
          energyCarrierId: this.heatingOil ? this.heatingOil.id : null,
          identifier: '',
          startingMonth: 1,
        }
      }
    },

    //
    energyCarrierIdentifier(energyCarrierId) {
      if (!this.model.meterType) {
        return '-'
      }
      const energyCarrier = this.portfolio.energy_carriers.find((ec) => ec.id === energyCarrierId)
      if (energyCarrier) {
        if (energyCarrier.default) {
          return this.$t(`_energyCarriers.${energyCarrier.name}`)
        } else {
          return energyCarrier.name
        }
      } else {
        return this.$t('_energyCarriers.UNKNOWN')
      }
    },

    //
    async saveHandler() {
      let energyCarrierId = null
      if (this.model.meterType === 'END_ENERGY_HEATER') {
        energyCarrierId = this.model.energyCarrierId
      }
      try {
        if (this.internalMeter) {
          await this.updateMeter({
            id: this.model.id,
            building_id: this.model.buildingId,
            meter_type: this.model.meterType,
            energy_carrier_id: energyCarrierId,
            identifier: this.model.identifier,
            start_month: this.model.startingMonth,
          })
          this.internalMeter = { ...this.internalMeter, ...this.model }
        } else {
          const result = await this.addMeter({
            building_id: this.model.buildingId,
            meter_type: this.model.meterType,
            energy_carrier_id: energyCarrierId,
            identifier: this.model.identifier,
            start_month: this.model.startingMonth,
          })
          this.$emit('add', result)
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-edit-meter {
  margin: 1rem 0;

  & .two-column-row {
    align-items: center;
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .gray {
    color: #999;
  }

  & .limit-width {
    width: 100%;
    max-width: 482px;
  }

  & .metering-period {
    width: 482px;
    overflow: hidden;
    margin-left: 224px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #ddd;

    & .period {
      flex: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
      border: 1px solid #ddd;
    }

    & .years {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 0;

      & > div {
        flex: none;
        width: 480px;
        height: 40px;
        border: 1px solid #ddd;
        line-height: 24px;
        padding: 8px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      & .following {
        background-color: #f4f4f4;
      }
    }

    & .months {
      display: flex;
      flex-direction: row;

      & > * {
        display: flex;
        flex-direction: row;

        & > div {
          flex: none;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border: 1px solid #ddd;
        }

        &.following {
          & > div {
            background-color: #f4f4f4;
          }
        }
      }
    }
  }
}
</style>
