<script>
import BaseLayer from '@/components/building/map/BaseLayer.vue'

export default {
  components: {
    BaseLayer,
  },

  extends: BaseLayer,

  computed: {
    sourceConfig() {
      if (this.building) {
        let features = []
        if (this.building.roof.state === 'OK') {
          for (let i = 0; i < this.building.roof.fassade_tiles.length; i++) {
            let tile = this.building.roof.fassade_tiles[i]
            if (tile.active) {
              features.push({
                id: i,
                type: 'Feature',
                geometry: tile['geometry'],
                properties: tile,
              })
            }
          }
        }
        return {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: features,
          },
        }
      } else {
        return {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        }
      }
    },

    layerConfig() {
      return {
        type: 'line',
        source: this.sourceId,
        paint: {
          'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1.0, 0.8],
          'line-width': 5,
          'line-color': [
            'match',
            ['get', 'solar_class'],
            5,
            '#a80000',
            4,
            '#ff6500',
            3,
            '#ffaa00',
            2,
            '#ffff00',
            1,
            '#00c5ff',
            /* other */
            '#ccc',
          ],
        },
      }
    },
  },
}
</script>
