<i18n>
{
  "de": {
    "currentStateTitle": "Aktueller Zustand",
    "viewOnly": "Nur Ansicht"
  }
}
</i18n>

<template>
  <div class="c-building-current-state">
    <!-- Timeline -->
    <TimelineElement type="CURRENT" :is-last-measure="isLastMeasure" />

    <!-- Current State -->
    <div class="current-state-wrapper">
      <Card collapsible>
        <CardHeader slot="header" :title="$t('currentStateTitle')">
          <template #prefix>
            <div>{{ currentYear }}</div>
          </template>
          <template #suffix>
            <div class="pill">
              <span>{{ $t('viewOnly') }}</span>
            </div>
          </template>
        </CardHeader>

        <!-- Building details -->
        <CardContent slot="content">
          <div class="content-wrapper">
            <!-- Summary Rows -->
            <List divider class="building-details">
              <CurrentStateZonesSummaryRow :building="building" />
              <CurrentStateEnvelopeSummaryRow :building="building" :portfolio="portfolio" />
              <CurrentStateHeatingSummaryRow :building="building" :portfolio="portfolio" />
              <CurrentStateElectricitySummaryRow :portfolio="portfolio" :building="building" />
              <CurrentStatePhotovoltaicSummaryRow :building="building" />
            </List>

            <!-- KPI Report card -->
            <KpiReportCard
              :s12e-value="building.current_state.result.ghg / energyArea"
              :energy-efficiency-value="building.current_state.result.pe / energyArea"
              :heating-efficiency-value="building.current_state.result.rh_demand / energyArea"
              :energy-efficiency-ratio="building.current_state.result.pe_ratio"
              :heating-efficiency-ratio="building.current_state.result.rh_ratio"
            />
          </div>
        </CardContent>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import List from '@/components/cui/data-display/List.vue'
import CurrentStateZonesSummaryRow from '@/components/building/overview/CurrentStateZonesSummaryRow.vue'
import CurrentStateEnvelopeSummaryRow from '@/components/building/overview/CurrentStateEnvelopeSummaryRow.vue'
import CurrentStateHeatingSummaryRow from '@/components/building/overview/CurrentStateHeatingSummaryRow.vue'
import CurrentStateElectricitySummaryRow from '@/components/building/overview/CurrentStateElectricitySummaryRow.vue'
import CurrentStatePhotovoltaicSummaryRow from '@/components/building/overview/CurrentStatePhotovoltaicSummaryRow.vue'
import KpiReportCard from '@/components/building/measures/KpiReportCard.vue'
import TimelineElement from '@/components/building/measures/TimelineElement.vue'

export default {
  components: {
    Card,
    CardHeader,
    CardContent,
    List,
    CurrentStateZonesSummaryRow,
    CurrentStateEnvelopeSummaryRow,
    CurrentStateHeatingSummaryRow,
    CurrentStateElectricitySummaryRow,
    CurrentStatePhotovoltaicSummaryRow,
    KpiReportCard,
    TimelineElement,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
    isLastMeasure: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    energyArea() {
      return this.building.current_state.zones.zones.reduce((acc, zone) => acc + zone.energy_area.deduced, 0)
    },

    currentYear() {
      return new Date().getFullYear()
    },
  },
}
</script>

<style lang="scss" scoped>
.c-building-current-state {
  display: flex;
  flex-direction: row;
  width: 100%;

  & .current-state-wrapper {
    width: 100%;
    margin: 20px 20px 0 0;

    & .content-wrapper {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      @media screen and (max-width: 1400px) {
        flex-direction: column;
      }

      @media screen and (max-width: 1280px) {
        flex-direction: row;
      }

      @media screen and (max-width: 960px) {
        flex-direction: column;
      }

      & .building-details {
        flex: 1;
        border-right: var(--box-border);

        @media screen and (max-width: 1400px) {
          border-right: none;
          border-bottom: var(--box-border);
        }

        @media screen and (max-width: 1280px) {
          border-right: var(--box-border);
          border-bottom: none;
        }

        @media screen and (max-width: 960px) {
          border-right: none;
          border-bottom: var(--box-border);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.c-building-current-state {
  & .c-cui-card {
    overflow: hidden;
  }

  & .pill {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    padding: 2px var(--spacing-s);
    border: var(--box-border);
    border-radius: 40px;
    font-size: 14px;

    & > span {
      font-weight: 400;
      display: inline-block;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
